import * as Types from './types'

export const snowflakeReducer = (state = { snowflake_connections: [], snowflake_tables: [], snowflake_columns: [], loading: false } , action) => {
  switch(action.type){
    case Types.SNOWFLAKE_REQUEST:
      return{
        ...state,
        loading: true
      }
    case Types.SNOWFLAKE_SUCCESS:
      return{
        ...state,
        loading: false,
        snowflake_connections: action.payload
      }
    case Types.TABLES_SUCCESS:
      return{
        ...state,
        loading: false,
        snowflake_tables: action.payload
      }
    case Types.COLUMNS_SUCCESS:
      return{
        ...state,
        loading: false,
        snowflake_columns: action.payload
      }
    case Types.SNOWFLAKE_FAILURE:
      return{
        ...state,
        loading: false
      }
    case Types.TABLES_FAILURE:
      return{
        ...state,
        loading: false
      }
    case Types.COLUMNS_FAILURE:
      return{
        ...state,
        loading: false
      }
    default:
      return state
  }
}