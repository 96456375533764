import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginCard from './LoginCard'
import MicrosoftSSOLoginCard from './MicrosoftSSOLoginCard'
import CircularIndeterminate from '../CircularLoading'
import { loginFormStyles } from './styles'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

class Login extends Component{
  render(){
    const { loggedIn, loading, classes } = this.props
    
    //regular login with <LoginCard/> and Microsoft login with <MicrosoftSSOLoginCard/>
    return(
      loggedIn === 0 ?
        loading ? <div className={classes.root}><CircularIndeterminate isLoading={loading} /></div> : <LoginCard/> :
        <Redirect to='/menu' />
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return{
    loggedIn: state.authentication.loggedIn,
    loading: state.authentication.loading
  }
}

export default connect(mapStateToProps)(withStyles(loginFormStyles)(Login))