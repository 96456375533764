import React from "react";
import { Field} from "redux-form";
import { connect } from "react-redux";
import { TextField } from "redux-form-material-ui";
import { munvoBlue } from "../utils/globalStyles";
import { renderSnowflakeConnectionDropdown, renderSnowflakeDropdowns, renderSnowflakeConditions } from "./NodeActions";
import { getSnowflakeOperators } from "../utils/generalUtils";
import { getColumnsByTableName, getTablesByConnectionID } from "../reducers/snowflake/actions";
import CircularIndeterminate from "../CircularLoading";
import { ConditionSwitchForm } from "./NodeForm";

class SnowflakeNodeAction extends React.Component{
   constructor(props) {
      super(props);

      this.state = {
         selectedConnection: null,
         selectedTable: null,
         selectedColumn: null,
         advancedSwitch: false,
         query: ""
      }
   }

   componentDidMount() {
      if (this.props.initialValues && this.props.initialValues.actions){
         var snowflakeActionIndexes = []
         this.props.initialValues.actionsList.forEach((element, index) => element=="snowflake" ? snowflakeActionIndexes.push(index) : null)
         
         for (var snowflakeIndex in snowflakeActionIndexes) {
            var action = this.props.initialValues.actions[snowflakeIndex] !== undefined ? this.props.initialValues.actions[snowflakeIndex].snowflake : undefined;
            var selectedConnection = action !== undefined ? this.props.snowflakeList.filter(conn => {return conn.connectionName == action.connection})[0] : undefined;
            
            if (selectedConnection !== undefined) {
               this.props.getListOfTablesByConnectionID(selectedConnection.id)
               this.props.getListOfColumnsByIdAndTableName(selectedConnection.id, action.table)
            }

            this.setState({
               selectedConnection: selectedConnection ? selectedConnection.connectionName : '',
               selectedTable: action ? action.table : '',
               selectedColumn: action ? action.enrichmentField : '',
               query: action ? action.simpleQuery : '',
               advancedSwitch: action ? action.advancedSwitch : ''
            })
         }
      }
   }

   handleSelectedConnectionChange = (val) => {
      var connectionName = Object.values(val).slice(0,-1).join("").replaceAll(",", "") 
      var selectedConnection = this.props.snowflakeList.filter(conn => {return conn.connectionName == connectionName})[0]
      this.setState({
         selectedConnection: selectedConnection.connectionName
      })
      this.props.getListOfTablesByConnectionID(selectedConnection.id)
   }

   handleSelectedTableChange = (val) => {
      var tableName = Object.values(val).slice(0,-1).join("").replaceAll(",", "") 
      var selectedConnection = this.props.snowflakeList.filter(conn => {return conn.connectionName == this.state.selectedConnection})[0]
      this.setState({
         selectedTable: tableName
      })
      this.props.getListOfColumnsByIdAndTableName(selectedConnection.id, tableName)
      this.setState({
         selectedColumn: null,
      })
   }

   handleSelectedColumnChange = (val) => {
      var columnName = Object.values(val).slice(0,-1).join("").replaceAll(",", "") 
      this.setState({
         selectedColumn: columnName
      })
   }

   toggleAdvancedSwitch = () => {
      this.setState({
         advancedSwitch: !this.state.advancedSwitch
      })
   }

   render() {
      return(
         <div>
         <CircularIndeterminate isLoading={this.props.loading} />
         <Field
            name={this.props.prefix + ".description"}
            component={TextField}
            floatingLabelText="Description"
            style={{ width: "80%"}}
            multiLine={true}
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         <br/>
         <br/>
         {(this.state.selectedConnection || (this.props.snowflakeList && this.props.snowflakeList.length > 0)) &&
            <Field
               name={this.props.prefix + ".connection"}
               label="Connection"
               component={renderSnowflakeConnectionDropdown}
               connections={this.props.snowflakeList}
               value={this.state.selectedConnection}
               onChange={this.handleSelectedConnectionChange}
            />
         }
         <br/>
         <br/>
         {(this.state.selectedConnection || ((this.props.snowflakeTableList && this.props.snowflakeTableList.length > 0) && this.props.initialValues)) &&
            <Field
               name={this.props.prefix + ".table"}
               label="Tables"
               component={renderSnowflakeDropdowns}
               data={this.props.snowflakeTableList}
               dataTitle={"Tables"}
               widthDesired={"80%"}
               value={this.state.selectedTable}
               onChange={this.handleSelectedTableChange}
            />
         }
         <br/>
         <br/>
         {(this.state.selectedTable || ((this.props.snowflakeColumnList && this.props.snowflakeColumnList.length > 0) && this.props.initialValues)) &&
          <div>
            <h5 style={{position: 'relative', color: "black"}}>Snowflake query</h5>
            <Field
               name={this.props.prefix + ".advancedSwitch"}
               value={this.state.advancedSwitch}
               onChange={this.toggleAdvancedSwitch}
               component={ConditionSwitchForm}
             />
             {this.state.advancedSwitch ? 
              (<Field
               component={TextField}
               style={{ width: "100%"}}
               multiLine={true}
               floatingLabelText="Query"
               name={this.props.prefix + ".advancedQuery"}
               underlineStyle={{ borderColor: munvoBlue }}
               underlineFocusStyle={{ borderColor: munvoBlue }}
               floatingLabelFocusStyle={{ color: munvoBlue }}
             />)
             : 
             (<Field
               component={renderSnowflakeConditions}
               name={this.props.prefix + ".simpleQuery"}
               operators={getSnowflakeOperators(this.props.payloadSchema, this.props.snowflakeColumnList)}
               query={this.state.query}
               onChange={this.verifySubstringCall}
            />) 
             }
            <br />
            <Field
              name={this.props.prefix + ".enrichmentName"}
              label="Enrichment Name"
              floatingLabelText="Enrichment Name"
              component={TextField}
              placeholder={"Enrichment Name"}
              width={"40%"}
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
            <Field
              name={this.props.prefix + ".enrichmentField"}
              label="Enrichment Field"
              component={renderSnowflakeDropdowns}
              data={this.props.snowflakeColumnList}
              dataTitle={"Enrichment Field"}
              widthDesired={"80%"}
              value={this.state.selectedColumn}
              onChange={this.handleSelectedColumnChange}
              style={{paddingLeft: '10px'}}
            />
          </div>
         }
      </div>
      )
   }
}

const mapStateToProps = state => {
   return {
     snowflakeTableList: state.snowflake.snowflake_tables,
     snowflakeColumnList: state.snowflake.snowflake_columns,
     loading: state.snowflake.loading
   }
 }
 
 const mapDispatchToProps = dispatch => {
   return {
     getListOfTablesByConnectionID: connectionId => dispatch(getTablesByConnectionID(connectionId)),
     getListOfColumnsByIdAndTableName: (connectionId, tableName) => dispatch(getColumnsByTableName(connectionId, tableName))
   };
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(SnowflakeNodeAction);