import React from "react";
import { Field, reduxForm } from "redux-form";
import { TextField } from "redux-form-material-ui";
import Grid from "@material-ui/core/Grid";
import { munvoBlue } from "../utils/globalStyles";

let FlowchartForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Field
            component={TextField}
            floatingLabelText="Name"
            name="name"
            hintText="New Flowchart"
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
          />
          <br/>
          <Field
            component={TextField}
            floatingLabelText="Description (optional)"
            name="description"
            hintText="Flowchart Description"
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }

  return errors;
};

FlowchartForm = reduxForm({
  form: "flowchartform", // a unique identifier for this form,
  validate,
  onSubmit: (values, dispatch, props) => {
    props.submitCallback(values);
  }
})(FlowchartForm);

export default FlowchartForm;
