import React, { Component } from 'react'
import HeaderBar from '../Header'
import Grid from '@material-ui/core/Grid'
import { Dialog } from 'material-ui'
import RemoteSubmitButton from '../RemoteSubmitButton'
import NewNodeTemplateForm from './NewNodeTemplateForm'
import { connect } from 'react-redux'
import { getNodeTemplates, postNodeTemplate, deleteNodeTemplate, putNodeTemplate } from '../reducers'
import { parseLinkedReplies } from './utils'
import Button from '@material-ui/core/Button'
import { munvoOrange } from '../utils/globalStyles'
import CircularIndeterminate from '../CircularLoading'
import WrapperTable from '../WrapperTable'
import Paper from '@material-ui/core/Paper'
import EditNodeTemplateForm from './EditNodeTemplateForm'
import '../App.css'
import classnames from 'classnames'
import Sidebar from '../Sidebar'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { nodeTemplateStyles, theme } from './styles'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

class NodeTemplate extends Component{
  constructor(props){
    super(props)
    this.state = {
      openNew: false,
      openEdit: false,
      openDelete: false,
      selectedNodeTemplate: null
    }

    this.handleOpenNew = this.handleOpenNew.bind(this)
    this.handleCloseNew = this.handleCloseNew.bind(this)
    this.handleOpenEdit = this.handleOpenEdit.bind(this)
    this.handleCloseEdit = this.handleCloseEdit.bind(this)
    this.handleOpenDelete = this.handleOpenDelete.bind(this)
    this.handleCloseDelete = this.handleCloseDelete.bind(this)
    this.handleSubmitNew = this.handleSubmitNew.bind(this)
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this)
    this.handleSubmitDelete = this.handleSubmitDelete.bind(this)
  }

  componentDidMount(){
    this.props.dispatch(getNodeTemplates())
  }

  handleOpenNew(){
    this.setState({ openNew: true })
  }

  handleCloseNew(){
    this.setState({ openNew: false })
  }

  handleOpenEdit(e){

    let fullReply = e.rowData.reply//.split(DELIMITER)

    let replies = []

    // for(let i = 0; i < fullReply.length; i++){
    //   if(i > 0){
    //     replies.push({ reply: fullReply[i] })
    //   }
    // }

    let selectedNodeTemplate = { 
      id: e.rowData.id, 
      customerResponse: e.rowData.customerResponse,
      deleted: e.rowData.deleted,
      priority: e.rowData.priority,
      firstReply: fullReply[0],
      replies: replies,
      nodeTemplateName: e.rowData.nodeTemplateName,
      updatedTimestamp: e.rowData.updatedTimestamp
    }

    this.setState({ openEdit: true, selectedNodeTemplate: selectedNodeTemplate })
  }

  handleCloseEdit(e){
    this.setState({ openEdit: false})
  }

  handleOpenDelete(e){
    this.setState({ openDelete: true })
  }

  handleCloseDelete(e){
    this.setState({ openDelete: false })
  }

  handleSubmitNew(form){
    form = parseLinkedReplies(form)

    this.props.dispatch(postNodeTemplate(form))

    this.setState({ openNew: false })
  }

  handleSubmitEdit(form){
    form = parseLinkedReplies(form)

    this.props.dispatch(putNodeTemplate(form))

    this.setState({ openEdit: false })
  }

  handleSubmitDelete(e, selectedNodeTemplate){
    let queryParams = ''

    queryParams = queryParams.concat('id=').concat(selectedNodeTemplate.id)

    this.props.dispatch(deleteNodeTemplate(queryParams))

    this.setState({ openEdit: false, openDelete: false })
  }

  render(){
    const title = 'SMSGateway - Node Templates'

    const { nodeTemplateIsLoading, data, classes } = this.props

    return(
      <div>
        <HeaderBar pageTitle={title} />
        <Sidebar component='div'/>
        <CircularIndeterminate isLoading={nodeTemplateIsLoading} />
        <div className={classnames('app-content', {'expanded': true})}>
          <Grid container>
            <Grid style={{ marginTop: '10px' }} item xs={12}>
              <Button
                color='primary'
                variant='contained'
                style={{ background: munvoOrange }}
                type='submit'
                onClick={this.handleOpenNew}
              >
                Add new Node Template
              </Button>
              <Grid item xs={12}>
                <Paper style={{ height: 600, width: '100%', marginTop: '10px'}}>
                  <WrapperTable
                    rowCount={data.length}
                    rowGetter={({ index }) => data[index]}
                    onRowDoubleClick={e => this.handleOpenEdit(e)}
                    rowHeight={56}
                    headerHeight={56}
                    columns={[
                      {
                        width: 100,
                        flexGrow: 1.0,
                        label: 'Node Template Name',
                        dataKey: 'nodeTemplateName'
                      },
                      {
                        width: 120,
                        label: 'Updated',
                        dataKey: 'updatedTimestamp',
                      }
                    ]}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Dialog
                  title='Create a Node Template'
                  actions={[
                    <Button
                      variant='outlined'
                      onClick={this.handleCloseNew}
                      className={classes.button}
                    >
                      Cancel
                    </Button>,
                    <RemoteSubmitButton
                      component={Button}
                      form='createNodeTemplateForm'
                      label='Submit'
                      color='primary'
                      variant='outlined'
                      className={classes.button}
                    />
                  ]}
                  open={this.state.openNew}
                  onRequestClose={this.handleCloseNew}
                  autoScrollBodyContent={true}
                  contentStyle={{ position: 'absolute', left: '50%', top: '50%', height: '80%', transform: 'translate(-50%, -50%)' }}
                >
                  <NewNodeTemplateForm
                    submitCallback={this.handleSubmitNew}
                    nodeTemplateList={data}
                  />
                </Dialog>
              </Grid>
              <Grid item xs={12}>
                <Dialog
                  title='Edit a Node Template'
                  actions={[
                    <Button
                      variant='outlined'
                      onClick={this.handleOpenDelete}
                      className={classes.button}
                      color='secondary'
                    >
                      Delete
                    </Button>,
                    <Button
                      variant='outlined'
                      onClick={this.handleCloseEdit}
                      className={classes.button}
                    >
                      Cancel
                    </Button>,
                    <RemoteSubmitButton
                      component={Button}
                      form='editNodeTemplateForm'
                      label='Submit'
                      color='primary'
                      variant='outlined'
                      className={classes.button}
                    />
                  ]}
                  open={this.state.openEdit}
                  onRequestClose={this.handleCloseEdit}
                  autoScrollBodyContent={true}
                  contentStyle={{ position: 'absolute', left: '50%', top: '50%', height: '80%', transform: 'translate(-50%, -50%)' }}
                >
                  <EditNodeTemplateForm
                    submitCallback={this.handleSubmitEdit}
                    initialValues={this.state.selectedNodeTemplate}
                    nodeTemplateList={data}
                  />
                </Dialog>
              </Grid>
              <Grid item xs={12}>
                <Dialog
                  title='Delete Node Template(s)'
                  actions={[
                    <Button
                      variant='outlined'
                      onClick={this.handleCloseDelete}
                      className={classes.button}
                    >
                      Cancel
                    </Button>,
                    <MuiThemeProvider theme={theme}>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={e => this.handleSubmitDelete(e, this.state.selectedNodeTemplate)}
                        className={classes.button}
                      >
                        Submit
                      </Button>
                    </MuiThemeProvider>
                  ]}
                  open={this.state.openDelete}
                  onRequestClose={this.handleCloseDelete}
                  autoScrollBodyContent={true}
                  contentStyle={{ position: 'absolute', left: '50%', top: '50%', height: '80%', transform: 'translate(-50%, -50%)' }}
                  >
                    Confirm Delete Node Template(s)
                </Dialog>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

NodeTemplate.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return{
    nodeTemplateIsLoading: state.nodeTemplate.loading,
    data: state.nodeTemplate.nodeTemplates
  }
}

export default connect(mapStateToProps)(withStyles(nodeTemplateStyles)(NodeTemplate))
