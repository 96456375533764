export const POST_URL_REQUEST = "POST_URL_REQUEST";
export const POST_URL_SUCCESS = "POST_URL_SUCCESS";
export const POST_URL_FAILURE = "POST_URL_FAILURE";
export const FETCH_URLS_SUCCESS = "FETCH_URLS_SUCCESS";
export const FETCH_URLS_FAILURE = "FETCH_URLS_FAILURE";
export const FETCH_URLS_REQUEST = "FETCH_URLS_REQUEST";
export const DELETE_URL_REQUEST = "DELETE_URL_REQUEST";
export const DELETE_URL_SUCCESS = "DELETE_URL_SUCCESS";
export const DELETE_URL_FAILURE = "DELETE_URL_FAILURE";
export const FETCH_TWILIO_CREDENTIALS_REQUEST =
  "FETCH_TWILIO_CREDENTIALS_REQUEST";
export const FETCH_TWILIO_CREDENTIALS_SUCCESS =
  "FETCH_TWILIO_CREDENTIALS_SUCCESS";
export const FETCH_TWILIO_CREDENTIALS_FAILURE =
  "FETCH_TWILIO_CREDENTIALS_FAILURE";
export const NEW_PING_SUCCESS = "NEW_PING_SUCCESS";
export const TEST_PING_SUCCESS = "TEST_PING_SUCCESS";
export const TEST_PING_FAILURE = "TEST_PING_FAILURE";
export const NEW_PING_NOT_FOUND = "NEW_PING_NOT_FOUND";
export const TEST_TWILIO_CREDENTIALS_SUCCESS =
  "TEST_TWILIO_CREDENTIALS_SUCCESS";
export const TEST_TWILIO_CREDENTIALS_FAILURE =
  "TEST_TWILIO_CREDENTIALS_FAILURE";
export const LOGOUT = "USER_LOGOUT";
export const ADBLOCK_DETECTED = "ADBLOCK_DETECTED";

export const FETCH_AWS_CREDENTIALS_REQUEST = "FETCH_AWS_CREDENTIALS_REQUEST";
export const FETCH_AWS_CREDENTIALS_SUCCESS = "FETCH_AWS_CREDENTIALS_SUCCESS";
export const FETCH_AWS_CREDENTIALS_FAILURE = "FETCH_AWS_CREDENTIALS_FAILURE";
export const TEST_AWS_CREDENTIALS_SUCCESS = "TEST_AWS_CREDENTIALS_SUCCESS";
export const TEST_AWS_CREDENTIALS_FAILURE = "TEST_AWS_CREDENTIALS_FAILURE";

export const FETCH_SINCH_CREDENTIALS_REQUEST =
  "FETCH_SINCH_CREDENTIALS_REQUEST";
export const FETCH_SINCH_CREDENTIALS_SUCCESS =
  "FETCH_SINCH_CREDENTIALS_SUCCESS";
export const FETCH_SINCH_CREDENTIALS_FAILURE =
  "FETCH_SINCH_CREDENTIALS_FAILURE";
export const TEST_SINCH_CREDENTIALS_SUCCESS = "TEST_SINCH_CREDENTIALS_SUCCESS";
export const TEST_SINCH_CREDENTIALS_FAILURE = "TEST_SINCH_CREDENTIALS_FAILURE";
