import React from "react"
import { Field, reduxForm } from 'redux-form'
import { TextField }from 'redux-form-material-ui'
import { munvoBlue } from '../utils/globalStyles'
import CircularIndeterminate from "../CircularLoading/CircularIndeterminate";
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

const renderSelectField = ({
   input,
   label,
   meta: { touched, error },
   listOfConnections
 }) => (
   <FormControl>
     <InputLabel htmlFor="age-native-simple">Connection</InputLabel>
     <Select
       native
       style={{width: '256px'}}
       {...input}
     >
       {listOfConnections && listOfConnections.map(connection => {
         return(
            <option value={connection.connectionName}>{connection.connectionName}</option>
         )
      })}
     </Select>
   </FormControl>
 )
 

const SnowflakeTestConnection = (props) => {
   const {listOfConnections, response, loading} = props;
   var responseColor = response && response.includes("Connection to snowflake succeeded!") ? "#4BB543" : "#FC100D"

   return(
      <form>
         <Field
            name="connection"
            component={renderSelectField}
            listOfConnections={listOfConnections}
            label="Connection"
         ></Field>
         <br/>
         <Field 
            component={TextField} 
            type='password' 
            floatingLabelText='Password' 
            name='password'
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         {response &&
            <p style={{ color: responseColor }}>{response}</p>
          }
          {loading &&
            <CircularIndeterminate isLoading={loading}/>
          }
      </form>
   )
}

const validate = (values) => {
   const errors = {};

   if (!values.password) { 
      errors.password = "Required!"
   }

   return errors
}

export default reduxForm({
   form: 'snowflakeTestConnectionForm',
   validate,
   onSubmit: (values, dispatch, props) => { 
      if (!values.connection) {
         props.listOfConnections[0].password = values.password
         props.submitCallback(props.listOfConnections[0])
      }
      props.listOfConnections.map(connection => {
         if (connection.connectionName == values.connection) {
            connection.password = values.password
            props.submitCallback(connection)
         }
      })
   }
})(SnowflakeTestConnection);