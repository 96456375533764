import React from "react";
import TextField from '@material-ui/core/TextField';
import * as Constants from "../utils/Constants";
import { munvoBlue } from "../utils/globalStyles";
import { isJSONString } from "../utils/generalUtils";
import QueryBuilder from 'react-querybuilder';
import { formatQuery } from 'react-querybuilder';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import LockIcon from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import { MaterialActionElement, MaterialValueEditor, MaterialValueSelector } from "./styles";

export const defaultSMSGWPayload = "<messages>\n"
+ "\t" + "<message>\n"
+ "\t\t" + "<phoneTo> </phoneTo>\n"
+ "\t\t" + "<phoneFrom> </phoneFrom>\n"
+ "\t\t" + "<broadlogid> </broadlogid>\n"
+ "\t\t" + "<reassignFlag> </reassignFlag>\n"
+ "\t\t" + "<body> </body>\n"
+ "\t" + "</message>\n"
+ "</messages>";

const defaultAPICallPayload = "{\n"
+ "\t" + "\"key\": \"value\"\n"
+ "}";

//this one will change eventually
const defaultEnrichmentPayload = "{\n"
+ "\t" + "\"key\": \"value\"\n"
+ "}";

const style = {
  material: {
    addGroupAction: MaterialActionElement,
    addRuleAction: MaterialActionElement,
    combinatorSelector: MaterialValueSelector,
    fieldSelector: MaterialValueSelector,
    operatorSelector: MaterialValueSelector,
    removeGroupAction: MaterialActionElement,
    removeRuleAction: MaterialActionElement,
    valueEditor: MaterialValueEditor
  }
}

export const renderConditions = ({
  input,
  label,
  name,
  meta,
  operators,
  query,
  formatType
}) => {
  return (
    <QueryBuilder
      name={name}
      controlElements={style.material}
      fields={operators} 
      query={query}
      onQueryChange={(event) => {input.onChange(JSON.parse(formatQuery(event, formatType)))}} 
    />
  )
}

export const renderSnowflakeConditions = ({
  input,
  name,
  operators,
  query
}) => {
  return (
    <QueryBuilder
      name={name}
      controlElements={style.material}
      fields={operators} 
      query={query}
      onQueryChange={(event) => input.onChange(event)}
    />
  )
}

export const renderMessageCenterDropdown = ({
  input
}) => (
  <FormControl style={{width:"80%"}}>
    <InputLabel id="dropdown">Wished Channel</InputLabel>
    <Select value={input.value} onChange={(e) => input.onChange(e.target.value)}>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={"1"}>SMS</MenuItem>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={"0"}>Email</MenuItem>
    </Select>
  </FormControl>
)

export const renderHttpMethodsDropdown = ({
  input
}) => (
  <FormControl style={{width:"80%"}}>
    <InputLabel id="dropdown">HTTP Method</InputLabel>
    <Select value={input.value} onChange={(e) => input.onChange(e.target.value)}>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={"POST"}>POST</MenuItem>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={"GET"}>GET</MenuItem>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={"DELETE"}>DELETE</MenuItem>
    </Select>
  </FormControl>
)

export const renderSnowflakeConnectionDropdown = ({
  connections,
  input
}) => (
  <FormControl style={{width:"80%"}}>
    <InputLabel id="dropdown">Connection</InputLabel>
    <Select value={input.value} onChange={(e) => input.onChange(e.target.value)}>
      {connections && connections.map(conn => {
        return(
          <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={conn.connectionName}>{conn.connectionName}</MenuItem>
        )
      })}
    </Select>
  </FormControl>
)

export const renderSnowflakeDropdowns = ({
  data,
  input,
  dataTitle,
  widthDesired
}) => (
  <FormControl style={{width: widthDesired}}>
    <InputLabel id="dropdown">{dataTitle}</InputLabel>
    <Select value={input.value} onChange={(e) => input.onChange(e.target.value)}>
      {data && data.map(dataPoint => {
        return(
          <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={dataPoint}>{dataPoint}</MenuItem>
        )
      })}
    </Select>
  </FormControl>
)

export const renderNodeActionsDropdown = ({
  input
}) => (
  <FormControl style={{width:"50%"}}>
    <InputLabel id="dropdown">Node Actions</InputLabel>
    <Select value={input.value} onChange={(e) => input.onChange(e.target.value)}>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={"N/A"}>{"N/A"}</MenuItem>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={Constants.NODE_ACTION_MSGCENTER_EMAIL}>{Constants.NODE_ACTION_MSGCENTER_EMAIL_LABEL}</MenuItem>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={Constants.NODE_ACTION_API_CALL}>{Constants.NODE_ACTION_API_CALL_LABEL}</MenuItem>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={Constants.NODE_ACTION_ENRICHMENT}>{Constants.NODE_ACTION_ENRICHMENT_LABEL}</MenuItem>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={Constants.NODE_ACTION_ACC_SOAP_CALL}>{Constants.NODE_ACTION_ACC_SOAP_CALL_LABEL}</MenuItem>
      <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} value={Constants.NODE_ACTION_SNOWFLAKE}>{Constants.NODE_ACTION_SNOWFLAKE_LABEL}</MenuItem>
    </Select>
  </FormControl>
)

export const renderTextField = ({
  input,
  label,
  name,
  meta,
  helperText
}) => (
  <TextField
    error={(input.value === '' && meta.touched) || (input.value === '' && meta.submitting)}
    helperText={helperText ? helperText : input.value === '' ? 'Required' : ''}
    label={label}
    name={name}
    style={{width:"80%"}}
    multiline
    {...input}
  />
)

export const renderCheckbox = ({
  input,
  label,
  name
}) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={input.value}
        name={name}
        color='secondary'
        icon={<LockOpen/>}
        checkedIcon={<LockIcon/>}
        {...input}
      />
    }
    label={label}
    labelPlacement="top"
  />
)

export const renderPayloadField = ({
  input,
  label,
  name,
  meta
}) => {

  //Set initial value (default payload) for each action type
  if(input.value === '' && !meta.touched) {
    switch(label){
      
      case Constants.NODE_ACTION_SMSGW_PAYLOAD_LABEL:
        input.value = defaultSMSGWPayload
        break;

      case Constants.NODE_ACTION_API_CALL_PAYLOAD_LABEL:
      case Constants.NODE_ACTION_API_SAMPLE_RESPONSE_LABEL:
        input.value = defaultAPICallPayload
        break;

      case Constants.NODE_ACTION_MSGCENTER_EMAIL_PAYLOAD_LABEL:
        break;

      case Constants.NODE_ACTION_ENRICHMENT_PAYLOAD_LABEL:
        input.value = defaultEnrichmentPayload
        break;

      case Constants.NODE_ACTION_ACC_SOAP_CALL_LABEL:
        break;

    }
  }
  
  return (
    <TextField
      error={input.value === '' || !isJSONString(input.value)}//&& meta.submitting}
      helperText={input.value === '' ? 'Required' : ''}
      label={label}
      name={name}
      multiline
      rowsMax={10}
      variant="outlined"
      style={{width:"80%"}}
      helperText={!isJSONString(input.value) ? "Please enter a valid JSON format." : ""}
      {...input}
    />
  )
}
