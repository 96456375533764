import React, { Component } from "react";
import { connect } from "react-redux";
import SortableTree from "react-sortable-tree";
import {
  flowchartActions,
  flowchartSelectors,
  dbActions,
  dbSelectors,
  snowflakeActions
} from "../reducers";
import {
  EditorModeEdit,
  ActionDelete,
  ContentAddCircle,
  ActionSearch,
  ContentSave,
  NavigationChevronRight,
  NavigationChevronLeft,
  ContentContentCopy
} from "material-ui/svg-icons";
import {
  Dialog,
  IconButton,
  TextField as TextFld,
  FloatingActionButton,
  FlatButton
} from "material-ui";
import NodeForm from "./NodeForm";
import jsxToString from "jsx-to-string";
import RemoteSubmitButton from "../RemoteSubmitButton";
import {
  DelResponseTooltip,
  AddChildTooltip,
  EditTooltip,
  PrevResultTooltip,
  NextResultTooltip,
  SearchNodesTooltip,
  CollapseFlowchartTooltip,
  ExpandFlowchartTooltip,
  DeleteFlowchartTooltip,
  SaveFlowchartTooltip,
  AddResponseTooltip,
  DuplicateFlowchartTooltip,
  RenameTooltip
} from "../utils/Tooltip";
import { EXTERNAL_ID_DEFAULT_FLAG } from "../utils/Constants";
import Tooltip from '@material-ui/core/Tooltip';

import {
  checkForNodeName,
  createNotification,
  setWebhookIdToNode,
  wipeWebhookFlags,
  customSearch,
  logMove,
  revertTree,
  isPhoneNumber,
  removeIds,
  removeFlowchartOptionsIds,
  mapOnTree,
  getActionsList
} from "../utils/generalUtils";
import { urlActions, nodeTemplateActions, configurationActions } from "../reducers";
import TextField from "@material-ui/core/TextField";
import { abilities } from "../utils/Ability";
import { Can } from "@casl/react";
import Button from "@material-ui/core/Button";
import FlowchartOptions from "./FlowchartOptions";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { createMuiTheme } from "@material-ui/core/styles";
import { flowchartStyles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PasswordReset from "../PasswordReset";
import DuplicateForm from "./DuplicateForm";
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import ReactTooltip from "react-tooltip";

const maxDepth = 100;
const isVirtualized = false;
const treeContainerStyle = {};
const defaultFlowchartOptions = [
  {
    key: "flowchartCondition",
    value: "To be configured"
  },
  {
    key: "predefinedSchema",
    value: "{}"
  },
  {
    key: "flowchartDescription",
    value: ""
  }
];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: munvoBlue
    }
  }
});

function setInitValues(node) {
  if (node) {
    var actionsList = getActionsList(node);
    node.actionsList = actionsList;
  }

  return node;
}

const WebhookEnabledStyle = {
  marginLeft: 6,
  marginTop: 11
};

class FlowchartTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNodeDel: false,
      openFlowchartDel: false,
      openFlowchartDup: false,
      openNew: false,
      openEdit: false,
      openPayload: false,
      flowchartDupErrorDisplay: false,
      deleteLoading: true,
      saved: true,
      urlList: [],
      url: 0,
      ability: abilities[this.props.role],
      openDrawer: false,
      flowchartOptions: defaultFlowchartOptions,
      nodeTemplates: null,
      selectedNodeTemplate: null,
      nodeTemplateIndex: undefined,
      webHookUrls: null,
      changeFlowchartName: true,

      targetNodeIndex: undefined,
      initialized: false,
      // list of pair of nodes linked by a redirect (id of initial node, name of target node ) 
      portalList: [],
      // list of node names available to be the target of a portal 
      // portal node cannot intersect in any way ( loop avoidance ) and needs a node name 
      availableTarget: []

    };
    this.handleDataTreeChange = this.handleDataTreeChange.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
  }

  async componentWillMount() {
    await this.props.fetchFlowcharts();
    //now i have all the trees via this.props.treeData
    //parseTree function is call on load and new flowchart 
    //set up node portal in there

    this.props.fetchUrlsWithRedux();
    this.props.getNodeTemplates();
    this.props.getConfiguration();
    this.props.getSnowflakeConnections(); 
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.metadata) {

      if (!this.props.metadata || 
          (nextProps.metadata.name && this.props.metadata.name && nextProps.metadata.name !== this.props.metadata.name)
        ) {
        this.setState({
          flowchartOptions: defaultFlowchartOptions
        });
      }

      if (nextProps.metadata.flowchartOptions) {
        this.setState({
          flowchartOptions: nextProps.metadata.flowchartOptions
        });
      }

      if(nextProps.metadata.name) {
        this.setState({
          flowchartName: nextProps.metadata.name
        })
      }

      if(nextProps.metadata.id) {
        this.setState({
          flowchartId: nextProps.metadata.id
        })
      }

      if(nextProps.metadata.description) {
        if (!nextProps.metadata.flowchartOptions) {
          defaultFlowchartOptions.filter(x => x.key == "flowchartDescription")[0].value = nextProps.metadata.description;
          this.setState({
            flowchartDescription: nextProps.metadata.description,
            flowchartOptions: defaultFlowchartOptions
          });

        } else {
          this.setState({
            flowchartDescription: nextProps.metadata.description,
          });
        }
      } else {
        defaultFlowchartOptions.filter(x => x.key == "flowchartDescription")[0].value = "";
        this.setState({
          flowchartDescription: ""
        })
      }
    }
    if (nextProps.availableTarget) {
      this.setState({ availableTarget: nextProps.availableTarget });
    }
    if (nextProps.urlList) {
      if (nextProps.urlList.urlList[0] && nextProps.urlList.urlList[0].id > 0) {
        this.setState({
          url: 0
        });
      }
    }

    if (nextProps.nodeTemplates) {
      this.setState({
        nodeTemplates: nextProps.nodeTemplates
      });
    }

    if (nextProps.treeData) {
      if (nextProps.treeData[0]) {
        if (nextProps.treeData[0].webhookUrlId) {
          this.setState({
            url: nextProps.treeData[0].webhookUrlId
          });
        }
      }
    }
    document.body.style.overflow = "auto"; // force overflow auto during password reset
  }

  onChangeFlowchartOptions = (key, value) => {
    let newFlowchartOptions = this.state.flowchartOptions.map(opt => {
      if (opt.key === key) {
        opt.value = value.toString();
        return opt;
      } else {
        return opt;
      }
    });
    this.setState({
      flowchartOptions: newFlowchartOptions
    });
  };

  handleOpenNew = rowInfo => this.setState({ openNew: true, rowInfo });
  handleCloseNew = () =>
    this.setState({
      openNew: false,
      rowInfo: null,
      selectedNodeTemplate: null,
      nodeTemplateIndex: "None"
    });
  handleSubmitNewNode = form => {
    let doesNodeExist;

    for (var actionIndex in form.actions) {
      if (form.actions[actionIndex] == undefined) {
        form.actions.splice(actionIndex)
      }
    }

    if (form.name === undefined) {
      doesNodeExist = false;
      form.name = "";
    } else {
      for (var i = 0; i < this.props.treeData.length; i++) {
        doesNodeExist = checkForNodeName(form.name, this.props.treeData[i]);
        if (doesNodeExist) {
          break;
        }
      }
    }

    var predefinedSchema = this.state.flowchartOptions.find(el => el.key === "predefinedSchema").value
    if (!doesNodeExist) {
      if (this.state.rowInfo) {
        this.props.addNode(form, this.state.rowInfo, this.props.treeData);
      } else {
        this.props.insertLastNode(form, predefinedSchema, this.props.treeData);
      }
    } else {
      createNotification(
        "Invalid action!",
        "Node name already exists in this flowchart"
      );
    }

    this.handleCloseNew();
    this.setState({
      selectedNodeTemplate: null,
      nodeTemplateIndex: "None"
    });
  };

  handleRename = () => {
    let duplicateExists = this.props.flowcharts.find(flowchart => {
      return (
        flowchart.name === this.state.flowchartName
      );
    });
    if (duplicateExists) {
      createNotification(
        "Invalid action!",
        "The flowchart name \"" + this.state.flowchartName + "\" already exists!"
      );
      this.setState({flowchartName: this.props.metadata.name})
    }
    this.setState({changeFlowchartName: !this.state.changeFlowchartName})
  };

  handleOpenEdit = rowInfo => this.setState({ openEdit: true, rowInfo });
  handleOpenPayload = () => this.setState({ openPayload: true })
  handleClosePayload = () => this.setState({ openPayload: false })
  handleCloseEdit = () =>
    this.setState({
      openEdit: false,
      rowInfo: null,
      selectedNodeTemplate: null,
      nodeTemplateIndex: "None"
    });
  handleSubmitEdit = form => {
    let doesNodeExist;

    if (
      !this.state.selectedNodeTemplate &&
      (this.state.rowInfo.node.name === undefined ||
        form.name.toLowerCase() ===
        this.state.rowInfo.node.name.toLowerCase() ||
        form.name === "")
    ) {
      doesNodeExist = false;
    } else if (!this.state.selectedNodeTemplate) {
      //check if a node with the same name exist
      for (var i = 0; i < this.props.treeData.length; i++) {
        doesNodeExist = checkForNodeName(form.name, this.props.treeData[i]);
        if (doesNodeExist) {
          break;
        }
      }
    }

    //check if we are updating a node name
    if (this.state.rowInfo.node.name !== form.name) {
      //check if the node being updated is a target node (iterate through tree checking for 
      // and update all the node which aim to that node in this.props.treeData


      //define function to apply on nodes to update target node name 
      var changeTargetNodeName = (node) => {
        //check if the node beeing changed is the target of this node
        if (node && node.targetNode && node.targetNode.name !== "" && node.targetNode.name === this.state.rowInfo.node.name) {
          //if it is, change the target name to the new name
          node.targetNode.name = form.name;

        }
      }

      //for all nodes in tree data , check self and check child 
      mapOnTree(this.props.treeData, false, changeTargetNodeName);

    }


    //if no other node has the same name update the node
    if (!doesNodeExist) {
      this.props.updateNode(form, this.state.rowInfo, this.props.treeData);

    } else {
      createNotification(
        "Invalid action!",
        "Node name already exists in this flowchart"
      );
    }
    this.handleCloseEdit();
    this.setState({
      selectedNodeTemplate: null,
      nodeTemplateIndex: "None"
    });
  };

  handleOpenNodeDel = rowInfo => this.setState({ openNodeDel: true, rowInfo });
  handleCloseNodeDel = () =>
    this.setState({ openNodeDel: false, rowInfo: null });
  handleSubmitNodeDel = () => {
    this.props.deleteNode(this.state.rowInfo, this.props.treeData);
    this.handleCloseNodeDel();
  };

  handleDataTreeChange = event => {
    if (event.searchString === undefined) {
      event.searchString = this.props.searchString;
    }
    this.props.updateTreeData(event);
  };

  handleSubmitNewFlowchart = (
    treeData,
    metadata,
    root,
    url,
    flowchartOptions
  ) => {
 
    let treeDataWithWebHookId = [];

    for (let i = 0; i < treeData.length; i++) {
      treeDataWithWebHookId.push(treeData[i]);
    }

    if (metadata.id === undefined) {
      const jsonRevertTree = revertTree(
        treeDataWithWebHookId,
        metadata,
        flowchartOptions
      );

      this.props.postFlowchart(jsonRevertTree);
    } else {
      const jsonRevertTree = revertTree(
        treeDataWithWebHookId,
        metadata,
        flowchartOptions,
        root
      );
      this.props.updateFlowchart(jsonRevertTree);
    }
  };

  handleOpenFlowchartDel = rowInfo =>
    this.setState({ openFlowchartDel: true, rowInfo });
  handleCloseFlowchartDel = () =>
    this.setState({ openFlowchartDel: false, rowInfo: null });
  handleSubmitFlowchartDel = () => {
    this.props.deleteFlowchart(this.state.rowInfo);
    this.handleCloseFlowchartDel();
  };

  handleOpenFlowchartDup = () => this.setState({ openFlowchartDup: true });

  handleCloseFlowchartDup = () =>
    this.setState({ openFlowchartDup: false, rowInfo: null, flowchartDupErrorDisplay: false });

  handleSubmitDuplicateFlowchart = (
    e,
    treeData,
    metadata,
    root,
    url,
    flowchartOptions
  ) => {

    let doesFlowchartExist = this.props.flowcharts.find(flowchart => {
      return (
        flowchart.name.toLowerCase() ===
        e.name.toLowerCase()
      );
    });

    if (doesFlowchartExist !== undefined) {
      createNotification(
        "Invalid action!",
        "Flowchart with this name already exists"
      );
      this.handleCloseFlowchartDup();

      return;
    }

    if (e.description) {
      this.onChangeFlowchartOptions("flowchartDescription", e.description)
    }

    let treeDataWithWebHookId = [];

    var noIdTreeData = removeIds(treeData);
    var noIdFlowchartOption = removeFlowchartOptionsIds(flowchartOptions);
    for (let i = 0; i < noIdTreeData.length; i++) {
      treeDataWithWebHookId.push(noIdTreeData[i]);
    }

    if (treeDataWithWebHookId[0].webhookUrl === 0) {
      let wipedTreeData = [];
      for (let i = 0; i < treeDataWithWebHookId.length; i++) {
        wipedTreeData.push(wipeWebhookFlags(noIdTreeData[i]));
      }
      const jsonRevertTree = revertTree(
        noIdTreeData,
        e,
        noIdFlowchartOption
      );


      this.props.postFlowchart(jsonRevertTree);
    } else {

      const jsonRevertTree = revertTree(
        treeDataWithWebHookId,
        e,
        noIdFlowchartOption,
        root
      );


      this.props.postFlowchart(jsonRevertTree);

    }
    this.handleCloseFlowchartDup();

  }

  toggleDrawer = () => {
    this.setState({
      openDrawer: !this.state.openDrawer
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  searchFinishCallback = matches => {
    if (this.props.searchFoundCount !== matches.length) {
      this.props.updateSearchProps(matches, this.props.searchFocusIndex);
    }
  };

  generateNodeProps = rowInfo => {
    const sessionStatus = this.props.sessionPath
      ? this.props.sessionPath.find(node => node.id === rowInfo.node.id)
      : null;
    var buttons = [
      <IconButton
        onClick={() => {
          if (
            this.props.treeData.length === 1 &&
            rowInfo.node.id === this.props.treeData[0].id
          ) {
            createNotification(
              "Invalid action!",
              "Cannot delete the last root node (a flowchart must contain at least one node)."
            );
          } else {
            this.handleOpenNodeDel(rowInfo);
          }
        }}
      >
        <ActionDelete data-tip data-for="delete-response-tt" />
        <DelResponseTooltip />
      </IconButton>,
      <IconButton onClick={() => this.handleOpenEdit(rowInfo)}>
        <EditorModeEdit data-tip data-for="edit-tt" />
        <EditTooltip />
      </IconButton>
    ];



    if (rowInfo.node.targetNode === undefined || rowInfo.node.targetNode.name === "") {
      buttons.push(
        <IconButton onClick={() => this.handleOpenNew(rowInfo)}>
          <ContentAddCircle data-tip data-for="add-child-tt" />
          <AddChildTooltip />
        </IconButton>)
    }

    //if this node has webhook enable push the hook icon to the list to render 
    if (rowInfo.node.webhookEnabled) {
      buttons.push(
        <img
          style={WebhookEnabledStyle}
          width="24px"
          height="24px"
          alt=""
          src={"https://image.flaticon.com/icons/svg/879/879255.svg"}
        />
      );
    }
    if (rowInfo.node.targetNode && rowInfo.node.targetNode.name !== "") {
      buttons.push(
        <div>
          <LowPriorityIcon
            width="24px"
            height="24px"
            data-tip data-for={"portal-tt" + rowInfo.node.targetNode.name}
          />
          <ReactTooltip id={"portal-tt" + rowInfo.node.targetNode.name} place='right' type='info' effect='solid'>
            <span>{"This node will redirect customers to the node named : " + rowInfo.node.targetNode.name}</span>
          </ReactTooltip>
        </div>
      );
    }
    return {
      id: rowInfo.node.id,
      buttons,
      className: !sessionStatus ? null : sessionStatus.status
    };
  };

  render() {
    const {
      treeData,
      searchFocusIndex,
      searchFoundCount,
      searchString,
      collapseAll,
      expandAll,
      updateSearchString,
      prevSearchResult,
      nextSearchResult,
      updateTreeData,
      metadata,
      root,
      availableTarget,
      classes
    } = this.props;

    const SearchButtons = ({ value }) => (
      <span>
        <IconButton
          disabled={!searchFoundCount}
          onClick={() => prevSearchResult(searchFocusIndex, searchFoundCount)}
        >
          <NavigationChevronLeft data-tip data-for="prev-result-tt" />
          <PrevResultTooltip />
        </IconButton>
        <IconButton
          disabled={!searchFoundCount}
          onClick={() => nextSearchResult(searchFocusIndex, searchFoundCount)}
        >
          <NavigationChevronRight data-tip data-for="next-result-tt" />
          <NextResultTooltip />
        </IconButton>
        &nbsp;
        {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
        &nbsp;/&nbsp;
        {searchFoundCount || 0}
      </span>
    );
    const AddResponse = () => (
      <table>
        <tbody>
          <tr>
            <td>
              <span style={{ marginLeft: "20px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ background: munvoOrange }}
                  type="submit"
                  data-tip
                  data-for="add-response-tt"
                  onClick={() => {
                    this.handleOpenNew();
                  }}
                >
                  Add Node
                </Button>
                <AddResponseTooltip />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
    const bottomRight = {
      position: "absolute",
      right: 50,
      bottom: 50
    };
    const FloatingSave = () => (
      <FloatingActionButton
        data-tip
        backgroundColor={munvoBlue}
        data-for="save-tt"
        onClick={() => {
          let doesFlowchartExist = this.props.flowcharts.find(flowchart => {
            return (
              flowchart.name.toLowerCase() ===
              this.state.flowchartName.toLowerCase()
            );
          });
          let isFlowchartRenamed = this.props.flowcharts.find(flowchart => {
            return (
              flowchart.name.toLowerCase() !== this.state.flowchartName.toLowerCase()
              && flowchart.id === this.state.flowchartId
            );
          });
          if (doesFlowchartExist !== undefined && metadata.id === undefined) {
            createNotification(
              "Invalid action!",
              "The given name is already associated to an existing flowchart."
            );
          } else {
            metadata.name = isFlowchartRenamed ? this.state.flowchartName : metadata.name
            this.handleSubmitNewFlowchart(
              treeData,
              metadata,
              root,
              this.state.url,
              this.state.flowchartOptions
            );
            this.props.saveFlowchart();
          }
        }}
      >
        <ContentSave />
        <SaveFlowchartTooltip />
      </FloatingActionButton>
    );

    const FloatingDelete = () => (
      <span>
        {metadata === undefined || metadata.id === undefined ? null : (
          <FloatingActionButton
            data-tip
            backgroundColor={munvoBlue}
            data-for="delete-tt"
            onClick={() => {
              this.handleOpenFlowchartDel(metadata.id);
            }}
          >
            <ActionDelete />
            <DeleteFlowchartTooltip />
          </FloatingActionButton>
        )}
      </span>
    );

    const FloatingDuplicate = () => (
      <span>
        <FloatingActionButton
          data-tip
          backgroundColor={munvoBlue}
          data-for="duplicate-tt"
          onClick={() => {
            this.handleOpenFlowchartDup();
          }}
        >
          <ContentContentCopy />
          <DuplicateFlowchartTooltip />
        </FloatingActionButton>
      </span>
    );

    const ExpandCollapse = () => (
      <div>
        <Button
          color="primary"
          variant="contained"
          style={{ background: munvoOrange }}
          data-tip
          data-for="expand-tt"
          onClick={() => expandAll(treeData)}
        >
          Expand All
        </Button>
        <ExpandFlowchartTooltip />
        &nbsp;&nbsp;&nbsp;
        <Button
          color="primary"
          variant="contained"
          style={{ background: munvoOrange }}
          data-tip
          data-for="collapse-tt"
          onClick={() => collapseAll(treeData)}
        >
          Collapse All
        </Button>
        <CollapseFlowchartTooltip />
      </div>
    );
    const DeleteFlowchartDialog = () => (
      <Dialog
        actions={[
          <Button
            variant="outlined"
            onClick={this.handleCloseNodeDel}
            className={classes.button}
          >
            Cancel
          </Button>,
          <MuiThemeProvider theme={theme}>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.handleSubmitNodeDel}
              className={classes.button}
            >
              Submit
            </Button>
          </MuiThemeProvider>
        ]}
        open={this.state.openNodeDel}
        onRequestClose={this.handleCloseNodeDel}
      >
        Delete this node and its children?
      </Dialog>
    );



    const DeleteNodeDialog = () => (
      <Dialog
        actions={[
          <Button
            variant="outlined"
            onClick={this.handleCloseFlowchartDel}
            className={classes.button}
          >
            Cancel
          </Button>,
          <MuiThemeProvider theme={theme}>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.handleSubmitFlowchartDel}
              className={classes.button}
            >
              Submit
            </Button>
          </MuiThemeProvider>
        ]}
        open={this.state.openFlowchartDel}
        onRequestClose={this.handleCloseFlowchartDel}
      >
        Delete this flowchart?
      </Dialog>
    );

    const DuplicateDialog = () => (
      <Dialog
        open={this.state.openFlowchartDup}
        title="Duplicate flowchart"
        onRequestClose={this.handleCloseFlowchartDup}
        actions={[
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={this.handleCloseFlowchartDup}
            labelStyle={{ color: munvoBlue }}
          />,
          <RemoteSubmitButton
            component={FlatButton}
            form="duplicate"
            label="Submit"
            primary={true}
            style={{ color: munvoBlue }}
          />
        ]}
      >
        <div style={{ color: "red" }}>
          {!this.props.isDuplicate && this.state.flowchartDupErrorDisplay ? this.props.duplicateError : ""}
        </div>

        <DuplicateForm
          submitCallback={e => {
            this.handleSubmitDuplicateFlowchart(
              e,
              treeData,
              metadata,
              root,
              this.state.url,
              this.state.flowchartOptions
            );
          }}
        />
      </Dialog>
    );

    const UpdateNodeDialog = () => (
      <Dialog
        title="Edit node"
        actions={[
          <Button
            variant="outlined"
            onClick={this.handleCloseEdit}
            className={classes.button}
          >
            Cancel
          </Button>,
          <RemoteSubmitButton
            component={Button}
            form="node"
            label="Submit"
            color="primary"
            variant="outlined"
            className={classes.button}
          />
        ]}
        open={this.state.openEdit}
        onRequestClose={this.handleCloseEdit}
        autoScrollBodyContent={true}
        contentStyle={dialogStyles}
      >

        <NodeForm
          treeData={treeData}
          availableTarget={this.props.availableTarget}
          webhookList={this.props.urlList.urlList}
          enableReinitialize={true}
          ability={this.state.ability}
          rowInfo={this.state.rowInfo}
          flowchartOptions={this.state.flowchartOptions}
          openPayload={this.state.openPayload}
          onOpenPayload={this.handleOpenPayload}
          onClosePayload={this.handleClosePayload}
          snowflakeList={this.props.snowflakeList}
          initialValues={
            this.state.rowInfo && this.state.rowInfo.node
                ? setInitValues(this.state.rowInfo.node)
                : null
          }
          submitCallback={this.handleSubmitEdit}
        />

      </Dialog>
    );

    const NodeTemplateDropdown = () => (
      <FormControl>
        <InputLabel htmlFor="nodetemplates">Node Templates</InputLabel>
        <Select
          value={this.state.nodeTemplateIndex}
          native
          onChange={e => {
            if (e.target.value !== "None") {
              const tempNodeTemplate = this.state.nodeTemplates[e.target.value];
              let tempSelectedNodeTemplate = {
                reply: tempNodeTemplate.reply,
                name: tempNodeTemplate.name,
                customerResponse: tempNodeTemplate.customerResponse,
                priority: tempNodeTemplate.priority
              };
              this.setState({
                selectedNodeTemplate: tempSelectedNodeTemplate,
                nodeTemplateIndex: e.target.value
              });
            } else {
              this.setState({
                selectedNodeTemplate: null,
                nodeTemplateIndex: "None"
              });
            }
          }}
          input={<Input id="node-template-list" />} // todo check if it does something 
        >
          <option value={undefined}>None</option>
          {this.state.nodeTemplates.map((nodeTemplate, index) => (
            <option key={index} value={index}>
              {nodeTemplate.nodeTemplateName}
            </option>
          ))}

        </Select>
      </FormControl>
    );

    const NewNodeDialog = () => (
      <Dialog
        title="Add a response"
        actions={[
          <Button
            variant="outlined"
            onClick={this.handleCloseNew}
            className={classes.button}
          >
            Cancel
          </Button>,
          <RemoteSubmitButton
            component={Button}
            form="node"
            label="Submit"
            color="primary"
            variant="outlined"
            className={classes.button}
          />
        ]}
        open={this.state.openNew}
        onRequestClose={this.handleCloseNew}
        autoScrollBodyContent={true}
        contentStyle={dialogStyles}
      >
        <NodeForm
          availableTarget={this.props.availableTarget}
          webhookList={this.props.urlList.urlList}
          enableReinitialize={true}
          ability={this.state.ability}
          createNode = {true}
          rowInfo={this.state.rowInfo}
          flowchartOptions={this.state.flowchartOptions}
          treeData = {this.props.treeData}
          openPayload={this.state.openPayload}
          onOpenPayload={this.handleOpenPayload}
          onClosePayload={this.handleClosePayload}
          snowflakeList={this.props.snowflakeList}
          // initialValues={
          //   this.state.rowInfo
          //       ? setInitValues(this.state.rowInfo.node)
          //       : null
          // }
          submitCallback={this.handleSubmitNewNode}
        />

        {/*<NodeTemplateDropdown />*/}
      </Dialog>
    );

    const canDropFunc = ({ nextParent, nextPath }) => {
      //return weither this node can have children
      if (nextParent) {
        return nextParent.canNodeHaveChildren
      }
      return true
    };

    return (
      <div>
        <section style={{ padding: "1rem 1rem" }}>
          <div>
            <div style={{ display: 'inline-block', position: 'relative', height: "80px", width:"50%" }}>              
              <div style={{ position: "absolute", top:"50%", transform: "translate(0%, -50%)", marginTop: "15px" }}>
                <h1 style={{ textAlign: "left", marginLeft: "10px", fontSize: "300%" }}>
                  {metadata ? (
                    <span onDoubleClick={() => {this.setState({changeFlowchartName: !this.state.changeFlowchartName})}}>
                      {this.state.changeFlowchartName ? (
                        <div>
                          <span data-tip data-for="rename-tt">{this.state.flowchartName}: </span>
                          <RenameTooltip />
                        </div>
                        ) : (
                          <TextField
                            data-tip
                            data-for="rename-tt"
                            inputProps={{style: {fontSize: 30}}} // font size of input text
                            InputLabelProps={{style: {fontSize: 30}}} 
                            hintText="Rename flowchart"
                            underlineFocusStyle={{ borderColor: munvoBlue }}
                            value={this.state.flowchartName}
                            onChange={(event) => {this.setState({flowchartName: event.target.value})}}
                            onBlur={this.handleRename}
                          />
                        )
                      }
                
                    </span>
                  ) : (
                    ""
                  )}
                </h1>
              </div>
            </div>
            <div style={{ display: 'inline-block', position: 'relative', height: "80px", width:"50%" }}>
              <div style={{ position: "absolute", top:"50%", left: "50%", transform: "translate(0%, -50%)" }}>
                <FloatingDelete />
                &nbsp;&nbsp;&nbsp;
                <FloatingSave />
                &nbsp;&nbsp;&nbsp;
                <FloatingDuplicate />
              </div>
            </div>
          </div>
        </section>
        <section style={{ boxSizing: "border-box" }}>
          <br/>
          <table>
            <tbody>
              <tr>
                <td>
                  <span style={{ marginLeft: "20px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.toggleDrawer}
                      style={{ background: munvoOrange }}
                    >
                      Flowchart Options
                    </Button>
                  </span>
                </td>
                <td style={{width: "65%"}}>
                  <div>
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <ActionSearch
                        style={{
                          position: "absolute",
                          left: -20,
                          top: 10,
                          width: 20,
                          height: 20
                        }}
                      />
                      <TextField
                        error={this.props.pathError}
                        helperText={this.props.pathError ? this.props.pathError : null}
                        data-tip
                        data-for="search-tt"
                        style={{ textIndent: 30 }}
                        hinttext="Search"
                        value={searchString || ""}
                        underlinefocusstyle={{ borderColor: munvoBlue }}
                        floatinglabelfocusstyle={{ color: munvoBlue }}
                        onChange={event => {
                          updateSearchString(event.target.value);
                        }}
                      />
                      <SearchNodesTooltip />
                    </div>
                    <SearchButtons />
                  </div>
                </td>
                <td>
                  <ExpandCollapse />
                </td>
              </tr>
            </tbody>
          </table>
          
          <br/>
          <div style={treeContainerStyle}>
            <SortableTree
              searchQuery={searchString}
              searchFocusOffset={searchFocusIndex}
              searchFinishCallback={this.searchFinishCallback}
              searchMethod={props => customSearch(props)}
              treeData={treeData}
              onChange={this.handleDataTreeChange}
              //onMoveNode={logMove} //for debugging
              maxDepth={maxDepth}
              canDrag={({ node }) => !node.noDragging}
              canDrop={canDropFunc}
              isVirtualized={isVirtualized}
              generateNodeProps={this.generateNodeProps}
              rowHeight={({ node }) => {
                const subtitle = jsxToString(node.subtitle);
                const actionsCount = subtitle.match(/Action >/g) ? subtitle.match(/Action >/g).length : 0;
                
                return 66 + actionsCount * 14;
              }}
            />
          </div>
          <br/>
          <AddResponse />
        </section>
        <DeleteFlowchartDialog />
        <DeleteNodeDialog />
        <DuplicateDialog />
        <NewNodeDialog />
        <UpdateNodeDialog />
        <div style={bottomRight}>
          <FlowchartOptions
            toggleDrawer={this.toggleDrawer}
            flowchartOptions={this.state.flowchartOptions}
            onChangeFlowchartOptions={this.onChangeFlowchartOptions}
            open={this.state.openDrawer}
          />
        </div>

        <PasswordReset />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    treeData: flowchartSelectors.getParsedFlowchart(state) || [],
    searchFocusIndex: flowchartSelectors.getSearchIndex(state),
    searchFoundCount: flowchartSelectors.getSearchCount(state),
    searchString: flowchartSelectors.getSearchString(state),
    metadata: flowchartSelectors.getMetadata(state),
    root: flowchartSelectors.getRoot(state),
    isLoading: state.db.loading,
    flowcharts: dbSelectors.getFlowchartList(state),
    urlList: state.urlList,
    role: state.authentication.role,
    nodeTemplates: state.nodeTemplate.nodeTemplates,
    loadedTree: state.flowcharts.tree,
    sessionPath: state.flowcharts.sessionPath,
    pathError: state.flowcharts.pathError,
    isDuplicate: state.flowcharts.isDuplicate,
    duplicateError: state.flowcharts.duplicateError,
    availableTarget: state.flowcharts.availableTarget,
    snowflakeList: state.snowflake.snowflake_connections
  };
};

var dialogStyles = {
  position: "absolute",
  left: "50%",
  top: "50%",
  height: "80%",
  transform: "translate(-50%, -50%)"
};

FlowchartTree.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  ...urlActions,
  ...flowchartActions,
  ...dbActions,
  ...nodeTemplateActions,
  ...configurationActions,
  ...snowflakeActions
})(withStyles(flowchartStyles)(FlowchartTree));

export { setInitValues };
