import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import WrapperTable from "../WrapperTable";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Dialog } from "material-ui";
import RemoteSubmitButton from "../RemoteSubmitButton";
import { AddConnectionTooltip } from "../utils/Tooltip";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import SnowflakeForm from "./SnowflakeForm"
import { getSnowflakeConnections, postSnowflakeConnection, testSnowflakeConnection, putSnowflakeConnection, deleteSnowflakeConnection } from "../reducers";
import { convertFromToSnowflakeJSON, createTableData, uniqueConnectionName } from "./utils";
import { snowflakeStyles, theme } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import CircularIndeterminate from "../CircularLoading/CircularIndeterminate";
import SnowflakeTestConnection from "./SnowflakeTestConnection";

class Snowflake extends Component {
  constructor(props) {
    super(props);

    this.state = { 
       openNew: false, 
       openEdit: false,
       openDelete: false,
       openTestConnectionDialog: false,
       selectedConnection: { connectionName: "" },
       loading: false
    };

    this.handleSubmitNew = this.handleSubmitNew.bind(this);
    this.handleTestConnection = this.handleTestConnection.bind(this);
    this.handleSubmitDelete = this.handleSubmitDelete.bind(this);
    this.handleOpenNew = this.handleOpenNew.bind(this);
    this.handleCloseNew = this.handleCloseNew.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);
    this.handleOpenDelete = this.handleOpenDelete.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);
    this.handleOpenTestConnectionDialog = this.handleOpenTestConnectionDialog.bind(this);
    this.handleCloseTestConnectionDialog = this.handleCloseTestConnectionDialog.bind(this)
  }

  componentWillMount() {
    this.props.dispatch(getSnowflakeConnections());
  }

  handleSubmitNew = async form => {
    let connectionJSON = convertFromToSnowflakeJSON(form)
    if (this.props.data) {
      if (uniqueConnectionName(this.props.data, connectionJSON.connectionName)){
        this.setState({
          createConnectionError: "A connection with the same name already exists."
        })
      }
      else {
        var response = await this.props.dispatch(testSnowflakeConnection(connectionJSON))
        if (response.includes("Connection to snowflake succeeded!")) {
          this.props.dispatch(postSnowflakeConnection(connectionJSON))
          this.setState({ openNew: false });
        } else {
          this.setState({
            createConnectionError: "The connection does not exist (cannot connect to Snowflake!)."
          })
        }
      }
    }
  }

  handleSubmitEdit = async form => {
    let connectionJSON = convertFromToSnowflakeJSON(form)
    connectionJSON.id = form.id
    if (this.props.data) {
      var connection = this.props.data.filter(conn => conn.id == form.id).map(x => x)[0]

      if (uniqueConnectionName(this.props.data, connectionJSON.connectionName) && connectionJSON.connectionName != connection.connectionName){
        this.setState({
          createConnectionError: "A connection with the same name already exists."
        })
      }
      else {
        var response = await this.props.dispatch(testSnowflakeConnection(connectionJSON))
        if (response.includes("Connection to snowflake succeeded!")) {
          this.props.dispatch(putSnowflakeConnection(connectionJSON))
          this.setState({ openEdit: false });
        } else {
          this.setState({
            createConnectionError: "The connection does not exist (cannot connect to Snowflake!)."
          })
        }
      }
    }
  }

  handleSubmitDelete = (selectedConnection) => {
    let queryParams = "";
    queryParams = queryParams.concat("id=").concat(selectedConnection.id);
    this.props.dispatch(deleteSnowflakeConnection(queryParams));
    this.setState({ openEdit: false, openDelete: false });
  }

  handleTestConnection = async connection => {
    this.setState({ loading: true })
    var response = await this.props.dispatch(testSnowflakeConnection(connection))
    this.setState({ loading: false, responseTest: response })
  }

  handleOpenNew = () => {
    this.setState({ openNew: true, createConnectionError: "" });
  }

  handleCloseNew = () => {
    this.setState({ openNew: false, responseTest: "", createConnectionError: "" });
  }

  handleOpenEdit = (e) => {
    this.setState({ openEdit: true, selectedConnection: e.rowData, createConnectionError: "" });
  }

  handleCloseEdit = () => {
    this.setState({ openEdit: false, responseTest: "", createConnectionError: "" });
  }

  handleOpenDelete= () => {
    this.setState({ openDelete: true });
  }

  handleCloseDelete= () => {
    this.setState({ openDelete: false });
  }

  handleOpenTestConnectionDialog = () => {
    this.setState({ openTestConnectionDialog: true })
  }

  handleCloseTestConnectionDialog = () => {
    this.setState({ openTestConnectionDialog: false, responseTest: null })
  }

  render() {

    const {data, classes, connectionsLoading } = this.props;

    const rows = createTableData(data);

    return (
      <div className={classnames("app-content", { snowflake: true })}>
        <CircularIndeterminate isLoading={connectionsLoading} />
        <Grid container>
          <Grid style={{ marginTop: "10px" }} item xs={12}>
            <Button
              data-tip
              data-for="add-user-tt"
              color="primary"
              variant="contained"
              style={{ background: munvoOrange }}
              type="submit"
              onClick={this.handleOpenNew}
            >
              Create Connection
            </Button>
            <AddConnectionTooltip />

            <Button
              data-tip
              data-for="add-user-tt"
              color="primary"
              variant="contained"
              style={{ background: munvoBlue, marginLeft: '10px' }}
              type="submit"
              onClick={this.handleOpenTestConnectionDialog}
            >
              Test Connection
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ height: 600, width: "100%", marginTop: "10px" }}>
              <WrapperTable
                rowCount={rows.length}
                rowGetter={({ index }) => rows[index]}
                onRowDoubleClick={e => this.handleOpenEdit(e)}
                rowHeight={56}
                headerHeight={56}
                columns={[
                  {
                    width: 100,
                    flexGrow: 1/6,
                    label: "Name",
                    dataKey: "name"
                  },
                  {
                    width: 100,
                    flexGrow: 1/6,
                    label: "Account identifier",
                    dataKey: "accountIdentifier"
                  },
                  {
                    width: 100,
                    flexGrow: 1/6,
                    label: "Username",
                    dataKey: "username"
                  },
                  {
                    width: 100,
                    flexGrow: 1/6,
                    label: "Warehouse",
                    dataKey: "warehouse"
                  },
                  {
                    width: 100,
                    flexGrow: 1/6,
                    label: "Database",
                    dataKey: "database"
                  },
                  {
                    width: 100,
                    flexGrow: 1/6,
                    label: "Schema",
                    dataKey: "schema"
                  }
                ]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Dialog
              title="Create a Connection"
              actions={[
                <Button
                  variant="outlined"
                  onClick={this.handleCloseNew}
                  className={classes.button}
                >
                  Cancel
                </Button>,
                <RemoteSubmitButton
                  component={Button}
                  form="snowflakeForm"
                  label="Submit"
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                />
              ]}
              open={this.state.openNew}
              onRequestClose={this.handleCloseNew}
              autoScrollBodyContent={true}
              contentStyle={{
                position: "absolute",
                left: "50%",
                top: "50%",
                height: "80%",
                transform: "translate(-50%, -50%)"
              }}
            >
              <SnowflakeForm
                submitCallback={this.handleSubmitNew}
                formResponse={this.state.createConnectionError}
              />
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <Dialog
              title="Test a Connection"
              actions={[
                <Button
                  variant="outlined"
                  onClick={this.handleCloseTestConnectionDialog}
                  className={classes.button}
                >
                  Cancel
                </Button>,
                <RemoteSubmitButton
                  component={Button}
                  form="snowflakeTestConnectionForm"
                  label="Test"
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                />
              ]}
              open={this.state.openTestConnectionDialog}
              onRequestClose={this.handleCloseTestConnectionDialog}
              autoScrollBodyContent={true}
              contentStyle={{
                position: "absolute",
                left: "50%",
                top: "50%",
                height: "80%",
                transform: "translate(-50%, -50%)"
              }}
            >
              <SnowflakeTestConnection
                listOfConnections={data}
                submitCallback={this.handleTestConnection}
                loading={this.state.loading}
                response={this.state.responseTest}
              />
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <Dialog
              title="Edit a Connection"
              actions={[
                <Button
                  variant="outlined"
                  onClick={this.handleOpenDelete}
                  className={classes.button}
                  color="secondary"
                >
                  Delete
                </Button>,
                <Button
                  variant="outlined"
                  onClick={this.handleCloseEdit}
                  className={classes.button}
                >
                  Cancel
                </Button>,
                <RemoteSubmitButton
                  component={Button}
                  form="snowflakeForm"
                  label="Submit"
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                />
              ]}
              open={this.state.openEdit}
              onRequestClose={this.handleCloseEdit}
              autoScrollBodyContent={true}
              contentStyle={{
                position: "absolute",
                left: "50%",
                top: "50%",
                height: "80%",
                transform: "translate(-50%, -50%)"
              }}
            >
              <SnowflakeForm
                submitCallback={this.handleSubmitEdit}
                initialValues={this.state.selectedConnection}
                formResponse={this.state.createConnectionError}
              />
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <Dialog
              title="Delete Connection(s)"
              actions={[
                <Button
                  variant="outlined"
                  onClick={this.handleCloseDelete}
                  className={classes.button}
                >
                  Cancel
                </Button>,
                <MuiThemeProvider theme={theme}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={e =>
                      this.handleSubmitDelete(this.state.selectedConnection)
                    }
                    className={classes.button}
                  >
                    Submit
                  </Button>
                </MuiThemeProvider>
              ]}
              open={this.state.openDelete}
              onRequestClose={this.handleCloseDelete}
              autoScrollBodyContent={true}
              contentStyle={{
                position: "absolute",
                left: "50%",
                top: "50%",
                height: "80%",
                transform: "translate(-50%, -50%)"
              }}
            >
              Confirm Delete Connection
            </Dialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Snowflake.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    connectionsLoading: state.snowflake.loading,
    data: state.snowflake.snowflake_connections
  };
};

export default connect(mapStateToProps)(withStyles(snowflakeStyles)(Snowflake));
