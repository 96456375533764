import React from "react";
import { Field, reduxForm } from "redux-form";
import Switch from "@material-ui/core/Switch";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactJson from 'react-json-view'
import Grid from "@material-ui/core/Grid";
import { munvoBlue } from "../utils/globalStyles";
import { isJSONString } from "../utils/generalUtils";
import CircularIndeterminate from "../CircularLoading/CircularIndeterminate";

export const JSONEditor = ({initialPayload, input}) => {
  const [toggle, setToggle] = React.useState(true);
  const [JSONPayload, setJSONPayload] = React.useState(JSON.parse(initialPayload));
  const [textPayload, setTextPayload] = React.useState("{}");

  const changePayload = (event) => {
    setTextPayload(event.target.value)
    input.onChange(event.target.value)
  }

  const toggleSwitch = () => {
    setToggle(!toggle)
  }

  return(
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={toggle}
            value={toggle}
            onClick={toggleSwitch}
            color="primary"
          />
        }
        label = {toggle ? "JSON Builder" : "Textbox"}
      />
      <br/>
      {toggle &&
        <ReactJson
          src={JSONPayload}
          onAdd={(event) => {
            setJSONPayload(event.updated_src)
            input.onChange(JSON.stringify(event.updated_src))
          }}
          onEdit={(event) => {
            setJSONPayload(event.updated_src)
            input.onChange(JSON.stringify(event.updated_src))
          }}
          onDelete={(event) => {
            setJSONPayload(event.updated_src)
            input.onChange(JSON.stringify(event.updated_src))
          }}
        />
      }
      {!toggle &&
        <TextField
          value={textPayload}
          label="Multiline"
          multiline
          rows={6}
          onChange={changePayload}
          required
          label="Payload"
          margin="normal"
          helperText={!isJSONString(textPayload) ? "Please enter a valid JSON format." : ""}
          error={!isJSONString(textPayload)}
          style={{width: '65%'}}
        />
      }
    </div>
  )
}

let SimulationForm = props => {
  const { handleSubmit, response, loading } = props;
  var payload = JSON.stringify({event:{}})
  var responseColor = ""

  if (response && response.includes("The event has succesfully been generated")) {
    responseColor = "#4BB543"
  } 
  else {
    responseColor = "#FC100D"
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Field
            component={JSONEditor}
            multiline
            margin="normal"
            initialPayload={payload}
            floatingLabelText="Payload"
            name="payload"
            hintText="Payload"
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
          />
          <br/>
          {response &&
            <p style={{ color: responseColor }}>{response}</p>
          }
          {loading &&
            <CircularIndeterminate isLoading={true}/>
          }
        </Grid>
      </Grid>
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (!values.payload) {
    errors.payload = "Required";
  }

  if (!isJSONString(values.payload)) {
    errors.payload = "The payload must be in JSON format.";
  }

  return errors;
};

SimulationForm = reduxForm({
  form: "simulationForm", // a unique identifier for this form,
  onSubmit: (values, dispatch, props) => {
    const errors = {};
    if (!values.payload) {
      values.payload = JSON.stringify({event:{}})
    }
    else if (!isJSONString(values.payload)) {
      errors.payload = "The payload must be in JSON format.";
    }
    if (Object.keys(errors).length !== 0) {
      return errors
    }
    else {
      props.submitCallback(values);
    }
  }
})(SimulationForm);

export default SimulationForm;
