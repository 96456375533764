import React, { Component } from 'react'
import logo from '../logo_munvo_picto-h_inverse.svg'
import { munvoBlue } from '../utils/globalStyles'
import { Drawer, AppBar } from 'material-ui'

class Sidebar extends Component{

  render(){
    const { component: Component } = this.props
    return(
      <Drawer docked={true} open={true} >
        <div style={{ overflow: 'hidden' }}>
          <AppBar
            showMenuIconButton={false}
            style={{ background: munvoBlue }}
            title={<img src={logo} alt='logo' style={{ width: '95%' }}/>}
          />
          <Component />
        </div>
      </Drawer>
    )
  }
}

export default Sidebar

 