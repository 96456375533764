import * as Types from "./types";

export const menuReducer = (state = {}, action) => {
  switch (action.type) {
    case Types.UPDATE_MENU_TREE:
      return {
        ...state,
        treeData: action.payload
      };     
    case Types.LOAD_MENU_TREE:
      return {
        ...state,
        treeData: action.payload,
        saved: true,
        menuEdit: false
      };
    case Types.MENU_EDIT_TOGGLED:
      //if(state.menuEdit) console.log('menuEdit on') //for debugging
      //if(!state.menuEdit) console.log('menuEdit off')

      return {
        ...state,
        menuEdit: !state.menuEdit
      }
    case Types.SAVE_MENU_TREE_SUCCESS:
      return {
        ...state,
        treeData: action.payload,
        menuEdit: false
      };
    case Types.NEW_MENU_FOLDER:
      var newTreeData = state.treeData.slice();
      newTreeData.unshift(action.payload);

      return {
        ...state,
        treeData: newTreeData,
        menuEdit: true
      };
    case Types.DELETE_MENU_FOLDER_SUCCESS:
      return {
        ...state,
        treeData: action.payload,
        menuEdit: false
      };
    case Types.SEARCH_CALLBACK:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
