import React, { Component } from "react";
import HeaderBar from "../Header";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import RemoteSubmitButton from "../RemoteSubmitButton";
import { withStyles } from "@material-ui/core/styles";
import { profilePageStyles } from "./styles";
import PropTypes from "prop-types";
import ProfileForm from "./ProfileForm";
import { getUserProfile, updateUserProfile, resetPassword } from "../reducers";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CircularIndeterminate from "../CircularLoading";
import Sidebar from "../Sidebar";
import Button from "@material-ui/core/Button";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getUserProfile(this.props.username));
  }

  //credential checks
  async handleSubmit(form) {
    const { userProfile } = this.props;
    userProfile.password = form.password;
    userProfile.previousPassword = form.previousPassword;

    this.props.dispatch(resetPassword(userProfile));
  }

  render() {
    const title = "Event Gateway - User Profile";

    const { classes, loading, userProfile, success } = this.props;

    return (
      <div>
        <HeaderBar pageTitle={title} />
        <Sidebar component="div" />
        <CircularIndeterminate isLoading={loading} />
        <div className={classes.profile}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h5">Reset Password</Typography>
              <ProfileForm
                submitCallback={this.handleSubmit}
                initialValues={userProfile}
              />
            </CardContent>
            <CardActions className={classes.actions}>
              <RemoteSubmitButton
                component={Button}
                form="profileForm"
                label="Submit"
                color="primary"
                variant="outlined"
                className={classes.button}
              />
            </CardActions>
            {success === 1 ? (
              <div className={classes.footerTextSuccess}>
                Password Reset Successful
              </div>
            ) : success === -1 ? (
              <div className={classes.footerTextFailure}>
                Password Reset Failed
              </div>
            ) : (
              <div></div>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userProfile: state.profile.profile,
    loading: state.profile.loading,
    success: state.profile.success,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(withStyles(profilePageStyles)(Profile));
