import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { TextField } from 'redux-form-material-ui'
import Grid from '@material-ui/core/Grid'
import { munvoBlue } from '../utils/globalStyles'

function validateDigits(campaignID) {
  var nonDigitChar;

  for(var i = 0; i < campaignID.length; i++) {
    if(isNaN(campaignID.charAt(i)) || campaignID.charAt(i) === ' ') {
      nonDigitChar = campaignID.charAt(i);
      break;
    }
  }

  return nonDigitChar;
}

let DuplicateForm = props => {
  const { handleSubmitDuplicateFlowchart } = props;
  return (
    <form onSubmit={handleSubmitDuplicateFlowchart} >
      <Grid container>
        <Grid item xs={12}>
          <Field 
            component={TextField} 
            floatingLabelText='Name' 
            name='name'
            hintText='New Flowchart'
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field 
            component={TextField} 
            floatingLabelText='Description (optional)' 
            name='description' 
            hintText="Flowchart Description"
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
          />
        </Grid>
      </Grid>
    </form>
  )
}

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  

  if(!values.campaignID) {
    errors.campaignID = 'Required'
  } else {
    var nonDigitChar = validateDigits(values.campaignID);

    if(nonDigitChar) {
      errors.campaignID = 'Invalid character \'' + nonDigitChar + '\' found'
    }
  }
  
  return errors
}

DuplicateForm = reduxForm({
  form: 'duplicate',  // a unique identifier for this form,
  validate,
  onSubmit: (values,dispatch,props) => {
    props.submitCallback(values)
  }
})(DuplicateForm);

export default DuplicateForm;
