import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import DoneIcon from 'material-ui/svg-icons/action/done'
import HelpIcon from 'material-ui/svg-icons/action/help'
import Radio from '@material-ui/core/Radio'
import { pingNewUrl, addUrl, deleteUrl, fetchUrlsWithRedux, testUrl } from '../reducers'
import { confirmAlert } from 'react-confirm-alert'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { tabStyles } from './styles'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { AddUrlToWebhookListTooltip, TestWebhookUrlTooltip, RemoveWebhookURLTooltip } from '../utils/Tooltip'
import ErrorIcon from 'material-ui/svg-icons/alert/error'

const mapDispatchToProps = dispatch => {
  return {
    addUrl: url => dispatch(addUrl(url)),
    deleteUrl: id => dispatch(deleteUrl(id)),
    fetchUrlsWithRedux: () => dispatch(fetchUrlsWithRedux(true)),
    testUrl: url => dispatch(testUrl(url)),
    pingNewUrl: url => dispatch(pingNewUrl(url))
  };
};

const AdblockDetected = (
  <div>
    <ErrorIcon />&nbsp;
    Unable to validate Webhook status. Please disable any ad blocker.
  </div>
)

class Webhook extends Component{
  constructor(props){
    super(props)

    this.state = {
      multiline: 'Controlled',
      urlList: [],
      url: '',
      newUrl: { url: '', contentType: 'json' },
      contentType: 'json',
      removedUrl: null
    }

    this.urlStatus = this.urlStatus.bind(this)
    this.handleRemoveUrl = this.handleRemoveUrl.bind(this)
    this.handleNewUrlChange = this.handleNewUrlChange.bind(this)
    this.handleContentTypeChange = this.handleContentTypeChange.bind(this)
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleContentTypeChange(event) {
    var url = {url: this.state.newUrl.url, contentType: event.target.value}
    this.setState({ contentType: event.target.value, newUrl: url })
  }

  handleNewUrlChange(event) {
    var url = { url: event.target.value, contentType: this.state.contentType }
    this.setState({ newUrl: url })
    this.props.pingNewUrl(url.url)
  }

  handleRemoveUrl(){
    var url = this.props.urlList.urlList.find((el) => {
      return el.url === this.state.url && !el.deleted;
    })

    this.setState({
      url: null,
      removedUrl: url.id
    }, () => {
      this.props.deleteUrl(url.id)
    })
  }

  urlStatus(){
    this.props.testUrl(this.state.url)
  }

  componentDidMount() {
    this.props.fetchUrlsWithRedux()
    this.setState({ url: this.url })
  }

  componentDidCatch() {
    this.forceUpdate()
  }

  componentWillReceiveProps (nextProps) {
    if(nextProps.urlList){
      if(!nextProps.urlList.urlList[0] || nextProps.urlList.urlList[0].id === this.state.removedUrl){
        this.setState({
          url: null
        })
      }else{
        this.setState({
          url: this.state.url ? this.state.url : nextProps.urlList.urlList[0].url,
          removedUrl: null
        })
      }
    }
  }

  render(){

    const { classes, newUrlStatus, testUrlStatus } = this.props

    return(
      <form noValidate autoComplete='off'>
        <Grid container>
          {this.props.adblockDetected ? <Grid item xs={12}>{AdblockDetected}<br/></Grid> : null}
          <Grid item xs={12}>
            <p>Add a new Webhook</p>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField id='url' label='Enter a new API URL' margin='normal' value={this.state.newUrl.url} onChange={this.handleNewUrlChange} />
            <span style={{ verticalAlign: -50, paddingLeft: 5}}>{newUrlStatus === 'Responded!' ? <DoneIcon /> : <HelpIcon />}</span>
          </Grid>
          <Grid item xs={12}>
            <p>Content Type</p>
          </Grid>
          <Grid item xs={12}>
            <span>JSON</span>
            <Radio 
              checked={this.state.contentType === 'json'} 
              onChange={this.handleContentTypeChange}
              value='json'
              name='radio-button-demo'
              aria-label='json'
              color='primary'
            />
            <span>XML</span>
            <Radio 
              checked={this.state.contentType === 'xml'}
              onChange={this.handleContentTypeChange}
              value='xml'
              name='radio-button-demo'
              aria-label='xml'
            />
          </Grid>
          <Grid item xs={12}>
            <Button 
              data-tip 
              data-for='add-url-to-webhook-list-tt' 
              variant='contained' 
              color='primary'
              disabled={!this.state.newUrl.url} 
              onClick={() => {
                this.props.addUrl(this.state.newUrl)
                this.setState({url: this.state.newUrl.url})
              }}>
              Add URL
            </Button>
            <AddUrlToWebhookListTooltip />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.subtitle}>
              <p>Current Webhooks</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='url-list'
              select
              value={this.state.url}
              onChange={this.handleChange('url')}
              SelectProps={{ native: true }}
              helperText='List of current API URLs'
              margin='normal'
            >
              {this.props.urlList.urlList.map(option => (
                <option key={option.url} value={option.url}>
                {option.url}
                </option>
              ))}
            </TextField>
            <span style={{verticalAlign: -35, paddingLeft: 5}}>{testUrlStatus === 'Responded!' ? <DoneIcon /> : <HelpIcon />}</span>
          </Grid>
          <Grid item xs={12}>
            <Button 
              data-tip 
              data-for='test-webhook-url-tt' 
              id='test' 
              variant='contained' 
              color='primary'
              disabled={this.props.urlList.loading || this.props.urlList.urlList.length < 1} 
              className={classes.currentTypeButtons} 
              onClick={this.urlStatus}>
              Test URL
            </Button>
            <TestWebhookUrlTooltip />
            <Button
              data-tip
              data-for='remove-webhook-url-tt' 
              variant='contained' 
              color='secondary'
              className={classes.currentTypeButtons}
              disabled={this.props.urlList.loading || this.props.urlList.urlList.length < 1}
              onClick={() => {
                const title = 'Warning!'
                const message = 'Removing this webhook will remove it from any associated flowcharts, you will have to manually update the flowcharts that have this association'
                if(this.state.url){
                  confirmAlert({
                  title: title,
                  message: message,
                  buttons: [
                    {
                      label: 'OK',
                      onClick: () => this.handleRemoveUrl()
                    },
                    {
                      label: 'Cancel'
                    }
                  ]
                })
                }else if(this.props.urlList.urlList.length < 1){
                  confirmAlert({
                    title: 'Error',
                    message: 'There are no Webhook Urls',
                    buttons: [
                      {
                        label: 'OK'
                      }
                    ]
                  }) 
                  }else{
                    confirmAlert({
                      title: 'Error',
                      message: 'Page is loading...',
                      buttons: [
                        {
                          label: 'OK'
                        }
                      ]
                    }) 
                  }
                }
              }
            >
              Remove URL
            </Button>
            <RemoveWebhookURLTooltip />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.subtitle}>
              <p>Webhook Status</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ul>
              {
                this.props.urlList.urlList.map(url => (
                  <li key={url.url} value={url.url}>
                  <b>{url.url}</b> {' (Content Type: ' + url.contentType.toUpperCase() + ') '}
                  {url.status === 'Responded' ? <DoneIcon /> : <HelpIcon />}
                  </li>
                ))
              }  
            </ul>
          </Grid>
        </Grid>
      </form>
    )
  }
}

Webhook.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return{
    urlList: state.urlList,
    urlListIsLoading:state.urlList.loading,
    newUrlStatus: state.urlList.newUrlStatus,
    testUrlStatus: state.urlList.testUrlStatus,
    adblockDetected: state.urlList.adblockDetected
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(tabStyles)(Webhook))