import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { IconButton } from "material-ui";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from "@material-ui/core/Switch";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LockIcon from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ReactJson from 'react-json-view'
import { ActionDelete, ContentAddCircle } from "material-ui/svg-icons";
import { Can } from "@casl/react";
import * as Constants from "../utils/Constants";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import { renderTextField, renderPayloadField, renderConditions, renderMessageCenterDropdown, renderSnowflakeConnectionDropdown, renderSnowflakeDropdowns, renderSnowflakeConditions, renderHttpMethodsDropdown, renderCheckbox } from "./NodeActions";
import { buildApiName, convertQueryToCondition, buildQueryBuilderOperators, getSnowflakeOperators } from "../utils/generalUtils";
import { getFlatDataFromTree } from "react-sortable-tree";
import SnowflakeNodeAction from "./SnowflakeNodeAction";

const defaultQuery = {rules: [], combinator: "and", not: false}

const styles = {
  checkbox: {
    color: munvoOrange,
    "&$checked": {
      color: munvoOrange
    }
  },
  checked: {}
};

export const EncryptionSwitch  = ({ input }) => (
  <FormControlLabel
    control={
      <Switch
        icon={<LockOpen isChecked={false} />}
        checkedIcon={<LockIcon isChecked={true} />}
        checked={input.value ? true : false}
        value={input.value ? true : false}
        onChange={input.onChange}
        color="secondary"
      />
    }
    label={"Encrypt payload"}
  />
);

export const ConditionSwitchForm = ({ input }) => (
  <FormControlLabel
    control={
      <Switch
        checked={input.value ? true : false}
        value={input.value ? true : false}
        onChange={input.onChange}
        color="primary"
      />
    }
    label={input.value ? "Advanced" : "Simplified"}
  />
);

const PayloadDialog = (props) => (
  <Dialog
     open={props.open}
     onClose={props.handleClose}
     style={{zIndex: 10000}}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Payload Schema"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <ReactJson src={JSON.parse(JSON.stringify(props.payload))} name={false} displayDataTypes={false} />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleClose} color="primary" autoFocus>
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

const ACCSOAPCallBodyElements = props => {

  return (
    <div>
      <div style={{display: 'inline-block', position: 'relative', paddingLeft: '3%', height: '20px'}}>
        <h6>Body Element(s)</h6>  
      </div>

      <div style={{display: "inline-block", position: "relative", height: "20px", top: "-5px"}}>
        <IconButton
          onClick={() => { props.fields.push(); }}
        >
          <ContentAddCircle />{" "}
        </IconButton>
      </div>

      {props.fields.map((bodyElement, bodyElementIndex) => {

        const indexLabel = bodyElementIndex + 1;
        
        return (
          <div>
            <div style={{paddingLeft: '3%', display: 'inline-block', position: 'relative', width: '44%'}}>
              <Field
                component={renderTextField}
                label={"Key #" + indexLabel}
                name={`${bodyElement}.key`}
              />
            </div>

            <div style={{paddingLeft: '3%', display: 'inline-block', position: 'relative', width:'44%'}}>
              <Field
                component={renderTextField}
                label={"Value #" + indexLabel}
                name={`${bodyElement}.value`}
              />
            </div>

            <div style={{display: 'inline-block', position: 'relative', top:'20px', left: '-4%'}}>
              <IconButton
                onClick={e => {
                  props.fields.remove(bodyElementIndex)
                }}
              >
                {" "}
                <ActionDelete />{" "}
              </IconButton>
            </div>
          </div>
        )}
      )}

    </div>
  )
}

const MessageCenterElements = props => {

  return (
    <div>
      <div style={{display: 'inline-block', position: 'relative', paddingLeft: '3%', height: '20px'}}>
        <h6 style={{fontWeight: "bold"}}>{props.title}</h6>  
      </div>

      <div style={{display: "inline-block", position: "relative", height: "20px", top: "-5px"}}>
        <IconButton
          onClick={() => { props.fields.push(); }}
        >
          <ContentAddCircle />{" "}
        </IconButton>
      </div>

      {props.fields.map((ctxElement, ctxElementIndex) => {

        const indexLabel = ctxElementIndex + 1;
        
        return (
          <div>
            <div style={{paddingLeft: '3%', display: 'inline-block', position: 'relative', width: props.encrypt ? '33%' : '40%'}}>
              <Field
                component={renderTextField}
                label={"Key #" + indexLabel}
                name={`${ctxElement}.key`}
              />
            </div>

            <div style={{paddingLeft: '3%', display: 'inline-block', position: 'relative', width: props.encrypt ? '33%' : '40%'}}>
              <Field
                component={renderTextField}
                label={"Value #" + indexLabel}
                name={`${ctxElement}.value`}
              />
            </div>

            {props.encrypt && 
              <div style={{display: 'inline-block', position: 'relative', width:'19%', top: '20px'}}>
                <Field
                  component={renderCheckbox}
                  label={"Encrypt"}
                  name={`${ctxElement}.encrypt`}
                />
              </div>
            }

            <div style={{display: 'inline-block', position: 'relative', top:'20px', left: '-4%'}}>
              <IconButton
                onClick={e => {
                  props.fields.remove(ctxElementIndex)
                }}
              >
                {" "}
                <ActionDelete />{" "}
              </IconButton>
            </div>
          </div>
        )}
      )}

    </div>
  )
}

const actionsArray = props => {

  var { initialValues, snowflakeList, actionsList, payloadSchema } = props;

  const [selectedAction, setSelectedAction] = React.useState("N/A");
  const [httpMethod, setHttpMethod] = React.useState("");

  const handleActionChange = (event) => {
    setSelectedAction(event.target.value);
  }

  const handleHttpMethodChange = (event) => {
    const method = Object.values(event).slice(0,-1).join("").replaceAll(",", "") 
    setHttpMethod(method);
  }

  if (actionsList.length > 0 && initialValues && initialValues.actions && initialValues.actions[actionsList.indexOf('apicall')]) {
    if(httpMethod.length == 0) {
      setHttpMethod(initialValues.actions[actionsList.indexOf('apicall')]['apicall']['httpMethod'])
    }
  }
  
  return (

    <div>
      <div style={{display: "inline-block", position: "relative", height: "50px", top: "13.5px"}}>
        <Field
            name="nodeActionSelected"
            component="select"
            onChange={handleActionChange}
            style={{position: "relative", top: "25%"}}
        >
          <option key={"0"} value={"N/A"} label = {"N/A"} />
          <option key={"1"} value={Constants.NODE_ACTION_MSGCENTER_EMAIL} label = {Constants.NODE_ACTION_MSGCENTER_EMAIL_LABEL} />
          <option key={"2"} value={Constants.NODE_ACTION_API_CALL} label = {Constants.NODE_ACTION_API_CALL_LABEL} />
          <option key={"3"} value={Constants.NODE_ACTION_ENRICHMENT} label = {Constants.NODE_ACTION_ENRICHMENT_LABEL} />
          <option key={"4"} value={Constants.NODE_ACTION_ACC_SOAP_CALL} label = {Constants.NODE_ACTION_ACC_SOAP_CALL_LABEL} />
          <option key={"5"} value={Constants.NODE_ACTION_CONTACT_ENGINE} label = {Constants.NODE_ACTION_CONTACT_ENGINE_LABEL} />
          <option key={"6"} value={Constants.NODE_ACTION_SNOWFLAKE} label = {Constants.NODE_ACTION_SNOWFLAKE_LABEL} />
          {/*<option key={"7"} value={Constants.NODE_ACTION_SMSGW} label = {Constants.NODE_ACTION_SMSGW_LABEL} />*/}


        </Field>
      </div>

      <div style={{display: "inline-block", position: "relative", height: "50px", top: "20px"}}>
        <IconButton
          onClick={() => {
            if(selectedAction !== "N/A") {
              actionsList.push(selectedAction);
              props.fields.push();
            }
          }}
        >
          <ContentAddCircle />{" "}
        </IconButton>
      </div>

      <br/>

      {props.fields.map((nodeAction, nodeActionIndex) => (
        <div key={nodeActionIndex}>
          <IconButton
            style={{display: 'block', position: 'relative', left: '88%', top: '60px'}}
            onClick={e => {
              actionsList.splice(nodeActionIndex, 1);
              props.fields.remove(nodeActionIndex);
            }}
          >
            <ActionDelete />
          </IconButton>
          
          {actionsList[nodeActionIndex] === Constants.NODE_ACTION_SMSGW &&
            <div style={{borderColor: 'whitesmoke', borderWidth: '3px', borderStyle: 'solid', padding: '15px', borderRadius: '8px'}}>
              <h6>SMS Gateway</h6>

              <Field
                component={renderTextField}
                label="Description"
                name={`${nodeAction}.` + Constants.NODE_ACTION_SMSGW + '.description'}
              />

              <br/>
              <br/>

              <Field
                component={renderPayloadField}
                id="outlined-textarea"
                name={`${nodeAction}.` + Constants.NODE_ACTION_SMSGW + '.payload'}
                label={Constants.NODE_ACTION_SMSGW_PAYLOAD_LABEL}
              />
            </div>
          } 
          
          {actionsList[nodeActionIndex] === Constants.NODE_ACTION_MSGCENTER_EMAIL &&
            <div style={{borderColor: 'whitesmoke', borderWidth: '3px', borderStyle: 'solid', padding: '15px', borderRadius: '8px'}}>
              <h6>Message Center Call</h6>

              <Field
                component={renderTextField}
                label="Description"
                name={`${nodeAction}.` + Constants.NODE_ACTION_MSGCENTER_EMAIL + '.description'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Endpoint"
                name={`${nodeAction}.` + Constants.NODE_ACTION_MSGCENTER_EMAIL + '.destination'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Event Type"
                name={`${nodeAction}.` + Constants.NODE_ACTION_MSGCENTER_EMAIL + '.eventType'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="External Id"
                name={`${nodeAction}.` + Constants.NODE_ACTION_MSGCENTER_EMAIL + '.externalId'}
              />

              <br/>
              <br/>

              <Field
                component={renderMessageCenterDropdown}
                title={"Channel"}
                name={`${nodeAction}.` + Constants.NODE_ACTION_MSGCENTER_EMAIL + '.wishedChannel'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label={"Communication"}
                name={`${nodeAction}.` + Constants.NODE_ACTION_MSGCENTER_EMAIL + '.communication'}
                helperText={"You can enter multiple values by separating them with a semicolon"}
              />

              <br/>
              <br/>

              <FieldArray
                name={`${nodeAction}.` + Constants.NODE_ACTION_MSGCENTER_EMAIL + '.rtEventElements'}
                component={MessageCenterElements}
                title={"rtEvent Attribute(s)"}
              />

              <br/>

              <FieldArray
                name={`${nodeAction}.` + Constants.NODE_ACTION_MSGCENTER_EMAIL + '.ctxElements'}
                component={MessageCenterElements}
                title={"CTX Element(s)"}
              />    
            </div>
          }
          
          {actionsList[nodeActionIndex] === Constants.NODE_ACTION_API_CALL &&
            <div style={{borderColor: 'whitesmoke', borderWidth: '3px', borderStyle: 'solid', padding: '15px', borderRadius: '8px'}}>
              <h6>API Call</h6>

              <Field
                component={renderTextField}
                label="Action Name"
                name={`${nodeAction}.` + Constants.NODE_ACTION_API_CALL + '.name'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Description"
                name={`${nodeAction}.` + Constants.NODE_ACTION_API_CALL + '.description'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Endpoint"
                name={`${nodeAction}.` + Constants.NODE_ACTION_API_CALL + '.destination'}
              />

              <br/>
              <br/>

              <Field
                component={renderHttpMethodsDropdown}
                title={"HTTP Method"}
                name={`${nodeAction}.` + Constants.NODE_ACTION_API_CALL + '.httpMethod'}
                onChange={handleHttpMethodChange}
              />

              <br/>
              <br/>

              <FieldArray
                name={`${nodeAction}.` + Constants.NODE_ACTION_API_CALL + '.headers'}
                component={MessageCenterElements}
                encrypt={true}
                title={"Header(s)"}
              />

              <br/>
              <br/> 

              {httpMethod != "GET" && 
                <div>
                  <Field
                    name={`${nodeAction}.` + Constants.NODE_ACTION_API_CALL + '.encryptBody'}
                    component={EncryptionSwitch}
                  />
                  <br/> 
                  <Field
                    component={renderPayloadField}
                    id="outlined-textarea"
                    name={`${nodeAction}.` + Constants.NODE_ACTION_API_CALL + '.requestBody'}
                    label={Constants.NODE_ACTION_API_CALL_PAYLOAD_LABEL}
                  />
                  <br/>
                  <br/>
                </div>
              }
              
              <Field
                component={renderPayloadField}
                id="predefined-textarea"
                name={`${nodeAction}.` + Constants.NODE_ACTION_API_CALL + '.response'}
                label={Constants.NODE_ACTION_API_SAMPLE_RESPONSE_LABEL}
              />
            </div>
          } 

          {actionsList[nodeActionIndex] === Constants.NODE_ACTION_ENRICHMENT &&
            <div style={{borderColor: 'whitesmoke', borderWidth: '3px', borderStyle: 'solid', padding: '15px', borderRadius: '8px'}}>
              <h6>Profile Enrichment</h6>

              <Field
                component={renderTextField}
                label="Description"
                name={`${nodeAction}.` + Constants.NODE_ACTION_ENRICHMENT + '.description'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Endpoint"
                name={`${nodeAction}.` + Constants.NODE_ACTION_ENRICHMENT + '.destination'}
              />

              <br/>
              <br/>

              <Field
                component={renderPayloadField}
                id="outlined-textarea"
                name={`${nodeAction}.` + Constants.NODE_ACTION_ENRICHMENT + '.payload'}
                label={Constants.NODE_ACTION_ENRICHMENT_PAYLOAD_LABEL}
              />
            </div>
          }

          {actionsList[nodeActionIndex] === Constants.NODE_ACTION_ACC_SOAP_CALL &&
            <div style={{borderColor: 'whitesmoke', borderWidth: '3px', borderStyle: 'solid', padding: '15px', borderRadius: '8px'}}>
              <h6>ACC SOAP Call</h6>

              <Field
                component={renderTextField}
                label="Description"
                name={`${nodeAction}.` + Constants.NODE_ACTION_ACC_SOAP_CALL + '.description'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Endpoint"
                name={`${nodeAction}.` + Constants.NODE_ACTION_ACC_SOAP_CALL + '.destination'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Namespace"
                name={`${nodeAction}.` + Constants.NODE_ACTION_ACC_SOAP_CALL + '.namespace'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Body Root Element"
                name={`${nodeAction}.` + Constants.NODE_ACTION_ACC_SOAP_CALL + '.bodyRootElement'}
              />

              <br/>
              <br/>

              <FieldArray
                name={`${nodeAction}.` + Constants.NODE_ACTION_ACC_SOAP_CALL + '.bodyElements'}
                component={ACCSOAPCallBodyElements}
              />

            </div>
          }

          {actionsList[nodeActionIndex] === Constants.NODE_ACTION_CONTACT_ENGINE &&
            <div style={{borderColor: 'whitesmoke', borderWidth: '3px', borderStyle: 'solid', padding: '15px', borderRadius: '8px'}}>
              <h6>Contact Engine</h6>

              <Field
                component={renderTextField}
                label="Description"
                name={`${nodeAction}.` + Constants.NODE_ACTION_CONTACT_ENGINE + '.description'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Template ID"
                name={`${nodeAction}.` + Constants.NODE_ACTION_CONTACT_ENGINE + '.templateID'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Type"
                name={`${nodeAction}.` + Constants.NODE_ACTION_CONTACT_ENGINE + '.type'}
              />

              <br/>
              <br/>

              <Field
                component={renderTextField}
                label="Endpoint"
                name={`${nodeAction}.` + Constants.NODE_ACTION_CONTACT_ENGINE + '.destination'}
              />      
            </div>
          } 

          {actionsList[nodeActionIndex] === Constants.NODE_ACTION_SNOWFLAKE &&
            <div style={{borderColor: 'whitesmoke', borderWidth: '3px', borderStyle: 'solid', padding: '15px', borderRadius: '8px'}}>
              <h6>Snowflake</h6>

              {snowflakeList && snowflakeList.length > 0 ?
                (
                <FieldArray
                  component={SnowflakeNodeAction}
                  prefix={`${nodeAction}.` + Constants.NODE_ACTION_SNOWFLAKE}
                  initialValues={initialValues}
                  payloadSchema={payloadSchema}
                  snowflakeList={snowflakeList}
                />
                )
              :
                (<p>In order to create a Snowflake node action, you need to add a Snowflake connection.<br/>Go to Settings &gt; Snowflake &gt; Create Connection</p>)
              }

            </div>
          }

        </div>
      ))}
    </div>
  );
}

class NodeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      // selectedTarget: this.props.rowInfo && this.props.rowInfo.node && this.props.rowInfo.node.targetNode ?
      //   this.props.rowInfo.node.targetNode.name : "",
      selectedWebhook: { value: "0", key: "0", label: "N/A" },
      conditionSwitch: false,
      conditionAccordion: true,
      actionsList: []
      // portalMode: this.props.rowInfo && this.props.rowInfo.node && this.props.rowInfo.node.portalMode ?
      //   this.props.rowInfo.node.portalMode : ""
    };

    // this.handleTargetChange = this.handleTargetChange.bind(this);
    // this.handlePortalModeChange = this.handlePortalModeChange.bind(this);
    this.toggleConditionSwitch = this.toggleConditionSwitch.bind(this);
    this.initializeToggleCondition = this.initializeToggleCondition.bind(this);
    this.handleWebhookChange = this.handleWebhookChange.bind(this);
    // this.canBePortal = this.canBePortal.bind(this);


  }
  // canBePortal(props) {

  //   if (!this || !this.props || !this.props.rowInfo || !this.props.rowInfo.node) {
  //     return true;
  //   }

  //   //check that current node is not a targetnode 
  //   const currentNodeName = this.props.rowInfo.node.name;

  //   //if no node name then it cant be a targetnode
  //   if (currentNodeName === "") {
  //     return true;
  //   }

  //   //define function to filter
  //   var filter = (node) => {
  //     //check if any node is targeting this node 
  //     if (node && node.targetNode && node.targetNode.name === currentNodeName) {
  //       //if it is, change the target name to the new name
  //       return node;
  //     }
  //   }

  //   var filteredTree = mapOnTree(props.treeData, false, filter)
  //   //for all nodes in tree data , check self and check child 
  //   if (!filteredTree || filteredTree.length === 0 || (filteredTree.length === 1 && filteredTree[0] === undefined)) {
  //     return true;
  //   }
  //   return false;
  // }

  // handlePortalModeChange(event) {
  //   this.setState({ portalMode: event.target.value });
  // }

  // handleTargetChange(event) {
  //   this.setState({ selectedTarget: JSON.parse(event.target.value).name });
  // }

  toggleConditionAccordion(val) {
    this.setState({
      conditionAccordion: val
    })
  }

  toggleConditionSwitch() {
    this.setState({
      conditionSwitch: !this.state.conditionSwitch
    })
  }

  initializeToggleCondition(values) {
    if (!this.state.setConditionInitially) {
      if (values) {
        if (values.conditionStatement && !values.conditionQuery) {
          values.conditionSwitch = true
        }
        this.setState({
          conditionSwitch: values.conditionSwitch,
          setConditionInitially: true
        })
      } else {
        this.setState({
          conditionSwitch: false,
          setConditionInitially: true
        })
      }
    }
  }

  handleWebhookChange(event) {
    var tempSelectedWebhook;
    //default no webhook
    tempSelectedWebhook = { value: "0", key: "0", label: "N/A" }
    //find the webhook url with the right id

    for (var i = 0; i < this.props.webhookList.length; i++) {
      if (this.props.webhookList[i].id && this.props.webhookList[i].id === event.target.value) {
        //console.log(JSON.stringify(this.props.webhookList[i])) //for debugging
        tempSelectedWebhook = { value: event.target.value, key: event.target.value, label: this.props.webhookList[i].url }
        break;
      }
    }

    this.setState({ selectedWebhook: tempSelectedWebhook })
  }

  componentDidMount() {

    //default no webhook
    var tempSelectedWebhook = { value: "0", key: "0", label: "N/A" }
    var tempActionsList = []
    
    //find the webhook url with the right id
    if (this.props.rowInfo && this.props.rowInfo.node && this.props.rowInfo.node.webhookUrlId) {
      for (var i = 0; i < this.props.webhookList.length; i++) {
        //console.log(JSON.stringify(this.props.webhookList[i])) //for debugging
        if (this.props.webhookList[i].id && this.props.webhookList[i].id === this.props.rowInfo.node.webhookUrlId) {
          tempSelectedWebhook = { value: this.props.rowInfo.node.webhookUrlId, key: this.props.rowInfo.node.webhookUrlId, label: this.props.webhookList[i].url }
          break;
        }
      }

    }
    
    if (this.props.initialValues && this.props.initialValues.actionsList) {
      tempActionsList = this.props.initialValues.actionsList
    }

    this.setState({
      selectedWebhook: tempSelectedWebhook,
      actionsList: tempActionsList
    });
  }

  createPayloadSchema(node) {
    var actions = node.actions
    var payloadSchema = []
    if (actions) {
      actions.forEach(actionIndex => {
        if (actionIndex.hasOwnProperty('apicall')) {
          var response = JSON.parse(actionIndex.apicall.response)
          var apiName = buildApiName(actionIndex.apicall.name)
          payloadSchema.push([apiName, response])
        }
      })
    }
    return payloadSchema
  }

  getPayloadSchema(treeData, flowchartOptions, rowInfo, createNode) {
    const predefinedSchema = flowchartOptions.find(el => el.key === "predefinedSchema").value
    if (rowInfo == undefined || (rowInfo.path.length == 1 && rowInfo.path[0] == 0 && rowInfo.node.actions == undefined)) {
      return JSON.parse(predefinedSchema)
    }
    else {
      var payloadSchemas = []
      var payload = JSON.parse(predefinedSchema)
      var pathToNode = rowInfo.path   
      let data = getFlatDataFromTree({
        treeData: treeData,
        path : rowInfo.path,
        getNodeKey: ({ treeIndex }) =>  treeIndex,
        ignoreCollapsed : true
      })

      // Build payload schema for each node individually
      pathToNode.forEach((nodeIndex, index) => {
        if (createNode || (!createNode && index !== pathToNode.length - 1)) {
          payloadSchemas.push(this.createPayloadSchema(data[nodeIndex].node))
        }
      })
  
      // Combine all payload schemas & return payload
      payloadSchemas.flat().forEach(schema => {
        var apiName = schema[0]
        var response = schema[1]
        payload[apiName] = response
      })
      return payload
    }
  }
  
  render() {
    const { handleSubmit, treeData, flowchartOptions, rowInfo, snowflakeList } = this.props;

    var conditionQueryAvailable = this.props.initialValues && this.props.initialValues.conditionQuery;
    var conditionQuery = conditionQueryAvailable ? JSON.parse(this.props.initialValues.conditionQuery) : defaultQuery;
    var payloadSchema = this.getPayloadSchema(treeData, flowchartOptions, rowInfo, this.props.createNode ? true : false)
    var operators = buildQueryBuilderOperators(payloadSchema)

    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={TextField}
          floatingLabelText="Name"
          name="name"
          underlineStyle={{ borderColor: munvoBlue }}
          underlineFocusStyle={{ borderColor: munvoBlue }}
          floatingLabelFocusStyle={{ color: munvoBlue }}
        />

        <Button variant="outlined" color="primary" onClick={this.props.onOpenPayload} style={{float: 'right'}}>
            Payload Schema
        </Button>
        <PayloadDialog 
          open={this.props.openPayload} 
          handleClose={this.props.onClosePayload} 
          payload={payloadSchema} 
        />
      
        <br />
        <br />
        <div style={{display: 'flex'}}>
          <h5 style={{position: 'relative', color: "black", top:'10px'}}> Condition statement </h5>
          {this.state.conditionAccordion ? 
            <ExpandLess onClick={() => this.toggleConditionAccordion(false)} style={{float: 'right', marginTop: '10px'}} /> 
            : 
            <ExpandMore onClick={() => this.toggleConditionAccordion(true)} style={{float: 'right', marginTop: '10px'}} />
          }
        </div>
        <Collapse in={this.state.conditionAccordion} timeout="auto" unmountOnExit>
          <br />
          <Field
            name="conditionSwitch"
            onChange={this.toggleConditionSwitch}
            onLoad={this.initializeToggleCondition(this.props.initialValues)}
            component={ConditionSwitchForm}
          />
          <br />
          {!this.state.conditionSwitch ?
            (<Field
              component={renderConditions}
              name="conditionQuery"
              formatType={"json_without_ids"}
              operators={operators}
              query={conditionQuery}
            />)
            :
            (<Field
              component={TextField}
              style={{ width: "100%"}}
              multiLine={true}
              floatingLabelText="Condition statement"
              name="conditionStatement"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />)
          }
          
          <br />
          <br />
        </Collapse>

        <hr style={{borderTop: "3px solid", borderRadius: "5px", color: 'whitesmoke'}}/>
        
        <h5 style={{position: 'relative', color: "black", top:'10px'}}> Node Actions </h5>

        <FieldArray
          name="actions"
          component={actionsArray}
          initialValues={this.props.initialValues}
          payloadSchema={payloadSchema}
          actionsList={this.state.actionsList}
          snowflakeList={snowflakeList}
        />

        <br />



{/* 
        //check if this node has children , if it does grey out since it cant have a portal
        <div>
          <label>Redirect customer&nbsp;</label>

          <ReactTooltip id='redirect-tt' place='right' type='info' effect='solid'>
            <span>{"Redirect a customer to a different node. If enabled, this node cannot have children nodes. You cannot redirect to a node which also redirects"}</span>
          </ReactTooltip>
          <HelpIcon data-tip data-for="redirect-tt" style={{ color: munvoBlue }} /><br />
        </div>
        <Field
          style={(this.props.rowInfo && this.props.rowInfo.node && this.props.rowInfo.node.children
            && this.props.rowInfo.node.children.length > 0) || !this.canBePortal(this.props)
            ? { pointerEvents: "none", opacity: "0.4" } : {}}
          name="targetNode"
          component="select"
          value={this.state.selectedTarget}
          onChange={this.handleTargetChange}
        >
          // if the node has a target show it as default 
          // if row info is not undefined and target node is not undefined add an option with current target node  
          {(this.props.rowInfo && this.props.rowInfo.node.targetNode && this.props.rowInfo.node.targetNode.name)
            &&
            <option key={this.props.rowInfo.node.targetNode.name}
              value={"{\"name\":\"" + this.props.rowInfo.node.targetNode.name + "\",\"id\":\"" + this.props.rowInfo.node.targetNode.id + "\"}"}>
              {this.props.rowInfo.node.targetNode.name}
            </option>}
          // either way add an empty option 
          {<option value={"{\"name\":\"\",\"id\":-1}"}>None</option>}

          // generate all the options for target node  
          {(this.props.availableTarget !== undefined) && (
            // mapping: for every target node in availableTarget do this
            this.props.availableTarget.map((targetNode) => (
              // check not undefined                 |check no self loop        
              (!this.props.rowInfo || targetNode.name !== this.props.rowInfo.node.name &&
                //check for double appearance of current choice
                (!this.props.rowInfo.node.targetNode || targetNode.name !== this.props.rowInfo.node.targetNode.name)) &&
              //if all passed show this option
              <option key={targetNode.name} value={"{\"name\":\"" + targetNode.name + "\",\"id\":\"" + targetNode.id + "\"}"}>
                {targetNode.name}
              </option>
            )))
          }
        </Field>

        <div>
          {this.state.selectedTarget && this.state.selectedTarget.name !== "" &&
            <div>
              <br />
              <label>Portal option</label>
              <br />
              <div>
                <Field name="portalMode" component="input" type="radio" value="default" onChange={this.handlePortalModeChange} />
                  Default&nbsp;
                <ReactTooltip id='portalDefault-tt' place='right' type='info' effect='solid'>
                  <span>{"The customer will receive both this node's replies and the target node's replies"}</span>
                </ReactTooltip>

                <HelpIcon data-tip data-for="portalDefault-tt" style={{ color: munvoBlue }} />
              </div>
              <div>
                <Field name="portalMode" component="input" type="radio" value="portal" onChange={this.handlePortalModeChange} />
                  Overwrite target reply&nbsp;
                <ReactTooltip id='portalOverwrite-tt' place='right' type='info' effect='solid'>
                  <span>{"The customer will only receive this node's replies, not the target node's replies"}</span>
                </ReactTooltip>

                <HelpIcon data-tip data-for="portalOverwrite-tt" style={{ color: munvoBlue }} />
              </div>

              <div>
                <Field name="portalMode" component="input" type="radio" value="target" onChange={this.handlePortalModeChange} />
                  Send only target reply&nbsp;
                <ReactTooltip id='portalTarget-tt' place='right' type='info' effect='solid'>
                  <span>{"The customer will only receive the target node's replies, this node won't send any replies"}</span>
                </ReactTooltip>

                <HelpIcon data-tip data-for="portalTarget-tt" style={{ color: munvoBlue }} />
              </div>
            </div>
          }
        </div> */}


        <Can I="update" a="webhook" ability={this.props.ability}>
          <label>Webhook &nbsp;</label>
          <br />

          <Field
            name="webhookUrlId"
            component="select"
            value={this.state.selectedWebhook}
            onChange={this.handleWebhookChange}

          >
            {/*select default value*/}
            {(this.props.rowInfo && this.props.rowInfo.node.webhookUrlId
              && this.props.rowInfo.node.webhookUrlId !== 0) &&
              <option key={this.props.rowInfo.node.webhookUrlId}
                value={this.props.rowInfo.node.webhookUrlId} label={this.state.selectedWebhook.label} />
            }

            <option key={"0"} value={"0"} label={"N/A"} />
            {this.props.webhookList.map(option => (

              //check for double appearance of current choice
              (!this.props.rowInfo || (!this.props.rowInfo.node.webhookUrlId || option.id !== parseInt(this.props.rowInfo.node.webhookUrlId))) &&
              //if all passed show this option
              <option key={option.id} value={option.id} label={option.url + " (" + option.contentType.toUpperCase() + ")"} />

            ))}

          </Field>

        </Can>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};

  if(!values.name || values.name.trim() === '') {
    errors.name = "Required";
  }

  if (values.actions && values.actions.length > 0 ) {
    errors.actions = []
    var errorObj = ({"description": "Required"})
    // for (var actionIndex in values.actions) {
    //   if (values.actions[actionIndex] !== undefined) {
    //     var actionKey = Object.keys(values.actions[actionIndex])[0]
        
    //     if (actionKey === Constants.NODE_ACTION_SNOWFLAKE && values.actions[actionIndex][actionKey] && (
    //       (!values.actions[actionIndex][actionKey].hasOwnProperty('description') || values.actions[actionIndex][actionKey]["description"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('connection')) 
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('table')) 
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('enrichmentName') || values.actions[actionIndex][actionKey]["enrichmentName"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('enrichmentField')) 
    //     )){
    //       errors.actions.push({[actionKey]: errorObj})
    //     }
    //     if (actionKey === Constants.NODE_ACTION_MSGCENTER_EMAIL && values.actions[actionIndex][actionKey] && (
    //       (!values.actions[actionIndex][actionKey].hasOwnProperty('description') || values.actions[actionIndex][actionKey]["description"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('destination') || values.actions[actionIndex][actionKey]["destination"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('eventType') || values.actions[actionIndex][actionKey]["eventType"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('externalId') || values.actions[actionIndex][actionKey]["externalId"] == "")
    //       || !values.actions[actionIndex][actionKey].hasOwnProperty('wishedChannel')
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('communication') ||  values.actions[actionIndex][actionKey]["communication"] == "")
    //     )){
    //       errors.actions.push({[actionKey]: errorObj})
    //     }
    //     if (actionKey === Constants.NODE_ACTION_API_CALL && values.actions[actionIndex][actionKey] && (
    //       (!values.actions[actionIndex][actionKey].hasOwnProperty('name') || values.actions[actionIndex][actionKey]["name"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('description') || values.actions[actionIndex][actionKey]["description"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('destination') || values.actions[actionIndex][actionKey]["destination"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('payload') || values.actions[actionIndex][actionKey]["payload"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('response') || values.actions[actionIndex][actionKey]["response"] == "")
    //     )){
    //       errors.actions.push({[actionKey]: errorObj})
    //     }
    //     if (actionKey === Constants.NODE_ACTION_ENRICHMENT && values.actions[actionIndex][actionKey] && (
    //       (!values.actions[actionIndex][actionKey].hasOwnProperty('description') || values.actions[actionIndex][actionKey]["description"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('destination') || values.actions[actionIndex][actionKey]["destination"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('payload') || values.actions[actionIndex][actionKey]["payload"] == "")
    //     )){
    //       errors.actions.push({[actionKey]: errorObj})
    //     }
    //     if (actionKey === Constants.NODE_ACTION_ACC_SOAP_CALL && values.actions[actionIndex][actionKey] && (
    //       (!values.actions[actionIndex][actionKey].hasOwnProperty('description') || values.actions[actionIndex][actionKey]["description"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('destination') || values.actions[actionIndex][actionKey]["destination"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('namespace') || values.actions[actionIndex][actionKey]["namespace"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('bodyRootElement') || values.actions[actionIndex][actionKey]["bodyRootElement"] == "")
    //     )){
    //       errors.actions.push({[actionKey]: errorObj})
    //     }
    //     if (actionKey === Constants.NODE_ACTION_CONTACT_ENGINE && values.actions[actionIndex][actionKey] && (
    //       (!values.actions[actionIndex][actionKey].hasOwnProperty('description') || values.actions[actionIndex][actionKey]["description"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('templateID') || values.actions[actionIndex][actionKey]["templateID"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('type') || values.actions[actionIndex][actionKey]["type"] == "")
    //       || (!values.actions[actionIndex][actionKey].hasOwnProperty('destination') || values.actions[actionIndex][actionKey]["destination"] == "")
    //     )){
    //       errors.actions.push({[actionKey]: errorObj})
    //     }
    //   } else {
    //     errors.actions.push({[values.nodeActionSelected]: errorObj})
    //   }
    // }
  }
  return errors;
};

NodeForm = reduxForm({
  form: "node", // a unique identifier for this form,
  validate,
  onSubmit: (values, dispatch, props) => {

    if (values.conditionSwitch) {
      values.conditionQuery = null;
      values.conditionSwitch = true;
    }

    else if ((values.conditionQuery && values.conditionSwitch == undefined) || !values.conditionSwitch) {
      values.conditionStatement = convertQueryToCondition(values.conditionQuery);
      values.conditionQuery = JSON.stringify(values.conditionQuery);
      values.customCondition = null
      values.conditionSwitch = false;
    }

    if (typeof values.targetNode === 'string' || values.targetNode instanceof String) {
      values.targetNode = JSON.parse(values.targetNode);
    }

    if (values.targetNode === undefined) {
      values.targetNode = JSON.parse("{\"name\":\"\",\"id\":-1}");
    } else {
      if (typeof values.targetNode.id === 'string' || values.targetNode.id instanceof String) {

        values.targetNode.id = parseInt(values.targetNode.id, 10);
      }
    }

    if (values.targetNode.name !== "") {
      //since it has a portal it can no longer have children
      values.canNodeHaveChildren = false;
      if (!values.portalMode) {
        values.portalMode = "default";
      }
      // if (!values.targetNode.id || values.targetNode.id === "undefined") {
      values.targetNode.id = -1;
      //}
    }
    else {
      values.portalMode = "";
      values.canNodeHaveChildren = true;
    }

    //default value is n/a webhook
    if (values.webhookUrlId == null) {
      values.webhookUrlId = "0"
    }
    //if a webhook was selected set webhook enabled to true else to false
    //values.webhookUrlId = parseInt(values.webhookUrlId)
    if (values.webhookUrlId === "0") {
      values.webhookEnabled = 0
    }
    else {
      values.webhookEnabled = 1

    }

    props.submitCallback(values);
  }
})(NodeForm);

export default NodeForm;