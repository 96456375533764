import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import { normalizeFlowcharts } from "../../utils/schema";
import _ from "lodash";
import { actions as flowchartActions } from "../flowcharts";
import { actions as menuActions } from "../menuItems";
import { getJwtToken, formatFlowchartPayloadForBackend, formatFlowchartPayloadForFrontend } from "../../utils/generalUtils";
import * as Types from "./types";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";

const loadFlowchart = flowchartActions.loadFlowchart;
const fetchMenuItems = menuActions.fetchMenuItems;

const getFlowchartsRequest = createAction(Types.GET_REQUEST);
const getFlowchartsSuccess = createAction(Types.GET_SUCCESS);
const getFlowchartsFailure = createAction(Types.GET_FAILURE);
const createFlowchartRequest = createAction(Types.CREATE_REQUEST);
const createFlowchartSuccess = createAction(Types.CREATE_SUCCESS);
const updateFlowchartRequest = createAction(Types.UPDATE_REQUEST);
const updateFlowchartSuccess = createAction(Types.UPDATE_SUCCESS);
const deleteFlowchartRequest = createAction(Types.DELETE_REQUEST);
const deleteFlowchartSuccess = createAction(Types.DELETE_SUCCESS);
const createFlowchartFailure = createAction(Types.CREATE_FAILURE);
const updateFlowchartFailure = createAction(Types.UPDATE_FAILURE);
const deleteFlowchartFailure = createAction(Types.DELETE_FAILURE);
const setActiveFlowchart = createAction(Types.SET_FLOWCHART);
const forceLogout = createAction(Types.LOGOUT);
const createSimulatedInbound = createAction(Types.POST_SIMULATED_INBOUND);
const createSimulatedInboundSuccess = createAction(Types.POST_SIMULATED_INBOUND_SUCCESS);

const fetchFlowcharts = () => async dispatch => {
  dispatch(getFlowchartsRequest());
  try {
    const response = await fetch(API_VERSION + "flowcharts", {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    });
    var json = await response.json();

    //json parse webhook map
    for(var i in json){
      //get the index for the webhookmap option
      for (var j in json[i].flowchartOptions) {
        //check if map already contains keyword being changed (e.value ?) 
        if(json[i].flowchartOptions[j]["key"] === "KeywordWebhookMap"){
          json[i].flowchartOptions[j].value = JSON.parse(json[i].flowchartOptions[j].value); 

          if(typeof json[i].flowchartOptions[j].value === "string"){
            json[i].flowchartOptions[j].value =JSON.parse(json[i].flowchartOptions[j].value)
          }

        break;
        }
      }
    }

    dispatch(getFlowchartsSuccess(normalizeFlowcharts(formatFlowchartPayloadForFrontend(json))));
    dispatch(loadFlowchart(json[0]));
    //console.log("flowchart json: "); //for debugging
    //console.log(json);
  } catch (err) {
    console.log(err);
    dispatch(getFlowchartsFailure(err));
  }
};

const postFlowchart = json => async dispatch => {
  dispatch(createFlowchartRequest());

  const response = await fetch(API_VERSION + "flowcharts", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(formatFlowchartPayloadForBackend(json))
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    const body = await response.json();
    dispatch(createFlowchartFailure(body));
  } else {
    const body = await response.json();
    const normalized = normalizeFlowcharts(formatFlowchartPayloadForFrontend(body.flowcharts));

    dispatch(createFlowchartSuccess(normalized));

    var flowchartsArray = Object.keys(normalized.entities.flowcharts);

    dispatch(
      loadFlowchart(
        normalized.entities.flowcharts[
          normalized.entities.flowcharts[
            flowchartsArray[flowchartsArray.length - 1]
          ].id
        ]
      )
    );

    dispatch(fetchMenuItems());
  }
};

const updateFlowchart = json => async dispatch => {
  dispatch(updateFlowchartRequest());

  var tempJson = json ; 
    //get the keyword webhook map from the options
    for (var j in json.flowchartOptions) {
    if(json.flowchartOptions[j]["key"] === "KeywordWebhookMap"){
      
      json.flowchartOptions[j].value = JSON.stringify(json.flowchartOptions[j].value);

     break;
    }
  }

  const response = await fetch(API_VERSION + "flowcharts", {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(formatFlowchartPayloadForBackend(json))
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    const body = await response.json();
    dispatch(updateFlowchartFailure(body));
  } else {
    const body = await response.json();
    const normalized = normalizeFlowcharts(formatFlowchartPayloadForFrontend(body));
    dispatch(updateFlowchartSuccess(normalized));

    dispatch(loadFlowchart(normalized.entities.flowcharts[json.id]));
    dispatch(fetchMenuItems());
  }
};

const deleteFlowchart = id => async dispatch => {
  dispatch(deleteFlowchartRequest());
  const response = await fetch(API_VERSION + "flowcharts/" + id, {
    method: "DELETE",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    const body = await response.json();
    dispatch(deleteFlowchartFailure(body));
  } else {
    const body = await response.json();
    dispatch(deleteFlowchartSuccess(normalizeFlowcharts(formatFlowchartPayloadForFrontend(body.flowcharts))));
    dispatch(loadFlowchart(body.flowcharts[0] || {}));
    dispatch(fetchMenuItems());
  }
};

const postSimulatedInbound = payload => async dispatch => {
  dispatch(createSimulatedInbound());

  const response = await fetch(API_VERSION + "inbound/", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: payload
  });

  var finalResponse = ""
  if (response.status == 200) {
    dispatch(createSimulatedInboundSuccess());
    await response.json().then((res) => {
      if (!res.accepted && res.ErrorMessage) {
        finalResponse = res.ErrorMessage
      }
      else if (res.accepted) {
        finalResponse = "The event has succesfully been generated."
      }
    })
  }
  else {
    finalResponse = "The event could not be generated. An exception occured."
  }
  return finalResponse
}

const actions = {
  fetchFlowcharts,
  postFlowchart,
  updateFlowchart,
  deleteFlowchart,
  setActiveFlowchart,
  postSimulatedInbound
};

// SELECTORS
const getDatabase = state => state.data;
const getActiveFlowchart = state => state.active;
const getSingleFlowchart = createSelector(
  [getDatabase, getActiveFlowchart],
  (db, id) => {
    if (db === undefined || id === undefined) return undefined;
    else return db.entities.flowcharts[id];
  }
);

const getRawFlowchart = createSelector([getSingleFlowchart], flowchart => {
  if (flowchart === undefined) return undefined;
  else return flowchart;
});

const getFlowchartList = createSelector([getDatabase], db => {
  if (!db) return [];
  const table = _.values(db.entities.flowcharts);
  if (!table) return [];
  return table;
});

const selectors = {
  getRawFlowchart,
  getFlowchartList
};

export { actions, selectors };
