import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { AppBar } from "material-ui";
import HelpIcon from "material-ui/svg-icons/action/help";
import Drawer from "@material-ui/core/Drawer";
import { createNotification, isJSONString } from "../utils/generalUtils";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";


const styles = theme => ({
  colorSwitchBase: {
    "&$colorChecked": {
      color: munvoOrange,
    },
    "&$colorChecked + $colorTrack": {
      backgroundColor: munvoOrange,
    },
  },
  colorTrack: {},
  colorChecked: {},
});

class FlowchartOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      flowchartCondition:"To be configured",
      flowchartDescription:"",
      predefinedSchema: "",
      externalIdCommaSeparatedFieldNames: "",
      tab: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    
    this.setState({
      predefinedSchema: nextProps.flowchartOptions.find(el => el.key === "predefinedSchema").value,
      flowchartCondition: nextProps.flowchartOptions.find(
        el => el.key === "flowchartCondition"
      ).value,
      flowchartDescription: nextProps.flowchartOptions.find(
        el => el.key === "flowchartDescription"
      ).value,
      open: nextProps.open
    });
  }


  changeTab = tab => {
    this.setState({
      tab: this.state.tab === tab ? null : tab
    });
  };

  changeText = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  drawerItemsWidth = "83.33%";
  drawerItemsMargin = "0px";

  render() {

    const flowchartDescription = (
      <Grid xs={12} item={true}>
        <TextField
          style={{ width: this.drawerItemsWidth }}
          value={this.state.flowchartDescription}
          onChange={this.changeText}
          onBlur={() =>
            this.props.onChangeFlowchartOptions(
              "flowchartDescription",
              this.state.flowchartDescription
            )
          }
          required
          hintText="Flowchart Description"
          name="flowchartDescription"
          margin="normal"
          multiline
        />
      </Grid>
    );

    const flowchartCondition = (
      <Grid xs={12} item={true}>
        <TextField
          style={{ width: this.drawerItemsWidth }}
          value={this.state.flowchartCondition}
          onChange={this.changeText}
          multiLine={true}
          onBlur={() =>
            this.props.onChangeFlowchartOptions(
              "flowchartCondition",
              this.state.flowchartCondition
            )
          }
          required
          hintText="'event.name'==='test'"
          name="flowchartCondition"
          margin="normal"
        />
      </Grid>
    );

    const predefinedSchema = (
      <Grid xs={12} item={true}>
        <TextField
          style={{ width: this.drawerItemsWidth }}
          value={this.state.predefinedSchema}
          onChange={this.changeText}
          name="predefinedSchema"
          onBlur={() =>
            this.props.onChangeFlowchartOptions(
              "predefinedSchema",
              this.state.predefinedSchema
            )
          }
          required
          margin="normal"
          helperText={!isJSONString(this.state.predefinedSchema) ? "Please enter a valid JSON format." : ""}
          error={!isJSONString(this.state.predefinedSchema)}
          multiline
        />
      </Grid>
    );

    return (
      <Drawer variant="persistent" anchor="right" open={this.state.open}>
        <div style={{ width: "550px" }}>
          <AppBar
            showMenuIconButton={false}
            style={{ background: munvoBlue }}
          />
          <Grid>
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(1)}>Flowchart's Description</Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Flowchart's description",
                    `The description of the flowchart. (Not required - for reference only)`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 1 && flowchartDescription}
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(2)}>Flowchart's condition statement</Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Flowchart's condition statement",
                    `Boolean expression that typically includes values potentially found in some event's payload. If this condition statement evaluates to true, this flowchart will process the incoming event.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 2 && flowchartCondition}
            <Grid
              xs={12}
              item={true}
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
            </Grid>
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(3)}>Predefined Schema</Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Predefined Schema",
                    `This is the flowchart's predefined schema, which describes the expected payload structure in JSON format of events this flowchart is set up to process. The key names and the type of the values will be reflected in the simplified condition statement builder within each node.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 3 && predefinedSchema}
          </Grid>
          <br />
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      </Drawer>
    );
  }
}

FlowchartOptions.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    configs: state.configuration.configurations
  };
};

export default connect(mapStateToProps)(withStyles(styles)(FlowchartOptions));
