import React, { Component } from "react";
import Sidebar from "../Sidebar";
import HeaderBar from "../Header";
import classnames from "classnames";
import { munvoBlue } from "../utils/globalStyles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { getMonitoring } from "../reducers";
import { connect } from "react-redux";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { createMuiTheme } from "@material-ui/core/styles";
import ReactTooltip from "react-tooltip";
import { makeStyles } from '@material-ui/core/styles';




class Monitoring extends Component {

  constructor(props) {
    super(props);
    this.state = {
      outboundContent: [],
      webhooksContent: [],
      overallStatus: "",
      inboundTransactions: ""
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.notUndefined = this.notUndefined.bind(this);

  }

  componentWillMount() {
    this.handleButtonClick();
  }
    //if the expression is undefined return an empty string
  //else return the expression
  notUndefined(expression) {
    if (expression !== undefined) {
      return expression;
    }
    return "";
  }

  async handleButtonClick() {
    await this.props.dispatch(getMonitoring());
    var outboundContentList = [];
    var webhooksContentList = [];

    for (
      var i = 0;
      i < this.props.monitoring.outboundTransactions.length;
      i++
    ) {
      //not a transaction error and has a session id
      if(this.props.monitoring.outboundTransactions[i].transaction && this.props.monitoring.outboundTransactions[i].transaction.sessionId){
      outboundContentList.push(
        <span>
          <ReactTooltip
            id={"outboundTip" + i}
            place="bottom"
            type="success"
            effect="solid"
            place="right"
          >
            <span>
              {"Timestamp : " +
                this.notUndefined(this.props.monitoring.outboundTransactions[
                  i
                ].createdTimestamp).toString()}
              <br />
              {" Transaction ID : " +
                this.notUndefined(this.props.monitoring.outboundTransactions[
                  i
                ].transaction.id).toString()}
              <br />
              {"Campaingn : " +
                this.notUndefined(this.props.monitoring.outboundTransactions[
                  i
                ].transaction.sessionId.campaign).toString()}
              <br />
              {" Phone Number : " +
                this.notUndefined(this.props.monitoring.outboundTransactions[
                  i
                ].transaction.sessionId.phone).toString()}
              <br />
              {" Session ID : " +
                this.notUndefined(this.props.monitoring.outboundTransactions[
                  i
                ].transaction.sessionId.id).toString()}
            </span>
          </ReactTooltip>

          <Brightness1Icon
            data-tip
            data-for={"outboundTip" + i}
            style={{
              color:
                this.props.monitoring.outboundTransactions[i].success === "true"
                  ? "#2FCC65"
                  : "#e81c2d"
            }}
          />
        </span>
      );
          }
          //is a transaction error
          else if(this.props.monitoring.outboundTransactions[i].transactionError)
          {
            outboundContentList.push(
              <span >
                <ReactTooltip
                  id={"outboundTip" + i}
                  place="bottom"
                  type="success"
                  effect="solid"
                  place="right"
                >
                  <span style={{width:10}}>
                    {"Timestamp : " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].createdTimestamp).toString()}
                    <br />
                    {"Transaction ID : " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].transactionError.transaction.id).toString()}
                    <br />
                    {"Campaingn : " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].transactionError.transaction.campaignID).toString()}
                    <br />
                    {"Phone Number : " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].transactionError.transaction.phone).toString()}
                      <br />
                    {"Error Message: " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].transactionError.errormessage).toString()}
                  </span>
                </ReactTooltip>
      
                <Brightness1Icon
                  data-tip
                  data-for={"outboundTip" + i}
                  style={{
                    color:
                      this.props.monitoring.outboundTransactions[i].success === "true"
                        ? "#2FCC65"
                        : "#e81c2d"
                  }}
                />
              </span>
            );
          }
          //not a transaction error and no session id
          else{
            outboundContentList.push(
              <span>
                <ReactTooltip
                  id={"outboundTip" + i}
                  place="bottom"
                  type="success"
                  effect="solid"
                  place="right"
                >
                  <span>
                    {"Timestamp : " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].createdTimestamp).toString()}
                    <br />
                    {" Transaction ID : " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].transaction.id).toString()}
                    <br />
                    {"Campaingn : " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].transaction.campaignID).toString()}
                    <br />
                    {" Phone Number : " +
                      this.notUndefined(this.props.monitoring.outboundTransactions[
                        i
                      ].transaction.phone).toString()}
                    <br />
                    {" Session ID : undefined"}
                  </span>
                </ReactTooltip>
      
                <Brightness1Icon
                  data-tip
                  data-for={"outboundTip" + i}
                  style={{
                    color:
                      this.props.monitoring.outboundTransactions[i].success === "true"
                        ? "#2FCC65"
                        : "#e81c2d"
                  }}
                />
              </span>
            );
          }
    }

    for (i = 0; i < this.props.monitoring.webhooks.length; i++) {
      webhooksContentList.push(
        <span>
          <ReactTooltip
            id={"webHookTip" + i}
            place="bottom"
            type="success"
            effect="solid"
          >
            <span>
              {"Timestamp : " +
                this.props.monitoring.outboundTransactions[
                  i
                ].createdTimestamp.toString()}
            </span>
          </ReactTooltip>
          <Brightness1Icon
            data-tip
            data-for={"webHookTip" + i}
            style={{
              color:
                this.props.monitoring.webhooks[i].success === "true"
                  ? "#2FCC65"
                  : "#e81c2d"
            }}
          />
        </span>
      );
    }

    this.setState({
      outboundContent: outboundContentList,
      webhooksContent: webhooksContentList,
      overallStatus: this.props.monitoring.overallStatus,
      inboundTransactions: this.props.monitoring.inboundTransactions
    });
  }

  

  render() {
    return (
        <div>
          <HeaderBar pageTitle={"Monitoring Dashboard"} />
          <Sidebar component="div" />
          <div className={classnames("app-content", { expanded: true })}>
            <section
              style={{
                padding: "1rem 2rem",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                color="primary"
                variant="contained"
                size="large"
                style={{
                  textTransform: "none",
                  background: munvoBlue,
                  fontSize: "20px"
                }}
                onClick={this.handleButtonClick}
              >
                Refresh
              </Button>
            </section>
            <section
              style={{
                padding: "1rem 2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div style={{ width: "75%" }}>
                <Box
                  borderRadius="6px"
                  display="flex"
                  p={1}
                  bgcolor={
                    this.props.monitoring.overallStatus === "operational"
                      ? "#2FCC65"
                      : this.props.monitoring.overallStatus ===
                        "partial service failure"
                      ? "#ffd333"
                      : "#e81c2d"
                  }
                  color="white"
                  fontSize="h6.fontSize"
                >
                  <Box p={1} flexGrow={1}>
                    System Status : {this.props.monitoring.overallStatus}
                  </Box>
                </Box>
              </div>
            </section>

            <section
              style={{
                padding: "1rem 2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div style={{ width: "75%" }}>
                <Paper>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Inbound Messages <br />
                          <div>
                            {this.props.monitoring.inboundTransactions ===
                            "success" ? (
                              <CheckCircleIcon style={{ color: "#2FCC65" }} />
                            ) : this.props.monitoring.inboundTransactions ? (
                              <CancelIcon style={{ color: "#e81c2d" }} />
                            ) : (
                              ""
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Outbound Messages
                          <br />
                          <div>
                            {this.state.outboundContent.length
                              ? this.state.outboundContent
                              : ""}
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Webhooks
                          <br />
                          <div>
                            {this.state.webhooksContent.length
                              ? this.state.webhooksContent
                              : ""}
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </section>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    monitoring: state.monitoring
  };
};

export default connect(mapStateToProps)(Monitoring);
