import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { TextField, Checkbox, SelectField } from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import * as Constants from "../utils/Constants";
import { isValidPassword } from "../utils/generalUtils";

class NewUserForm extends Component {
  render() {
    const { handleSubmit, roleList } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              component={TextField}
              floatingLabelText="Username"
              name="username"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type="password"
              floatingLabelText="Password"
              name="password"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type="password"
              floatingLabelText="Confirm Password"
              name="confirmPassword"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field component={SelectField} floatingLabelText="Role" name="role">
              <MenuItem value="" primaryText="" />
              {roleList.map(role => (
                <MenuItem
                  key={role.id}
                  value={JSON.stringify(role)}
                  primaryText={role.rolename}
                />
              ))}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              component={Checkbox}
              label="Active"
              name="active"
              iconStyle={{ fill: munvoOrange }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={Checkbox}
              label="Force Password Reset"
              name="forcePassReset"
              iconStyle={{ fill: munvoOrange }}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  } else {
    let doesUsernameExist = props.userList.find(user => {
      return user.username.toLowerCase() === values.username.toLowerCase();
    });

    if(!isValidPassword(values.password)){
      errors.password =
      "Password should be longer than " +
      Constants.PASSWORDLENGTH.toString() +
      " character long and contain both a letter and a number";
    }
      

    if (doesUsernameExist) {
      errors.username = "Username already exists";
    }
  }

  if (!values.password) {
    errors.password = "Required";
  }

  
  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Password mismatched";
  }

  if (values.role === undefined || values.role === "") {
    errors.role = "Required";
  }

  return errors;
};

NewUserForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  userList: PropTypes.array.isRequired,
  roleList: PropTypes.array.isRequired
};

export default reduxForm({
  form: "createUserForm",
  validate,
  onSubmit: (values, dispatch, props) => {
    props.submitCallback(values);
  }
})(NewUserForm);
