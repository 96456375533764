import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { munvoBlue } from "../utils/globalStyles";
import { isValid } from "date-fns";
import { isValidPassword } from "../utils/generalUtils";
import * as Constants from "../utils/Constants";

class PasswordResetForm extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type="password" 
              floatingLabelText="New Password"
              name="password"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type="password"
              floatingLabelText="Confirm New Password"
              name="confirmPassword"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Password mismatched";
  }

if(!isValidPassword(values.password)){
  errors.password =
  "Password should be longer than " +
  Constants.PASSWORDLENGTH.toString() +
  " character long and contain both a letter and a number";
}


  if (!values.password) {
    errors.password = "Required";
  }
  return errors;
};

PasswordResetForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
};

export default reduxForm({
  form: "passwordResetForm",
  validate,
  onSubmit: (values, dispatch, props) => {
    props.submitCallback(values);
  }
})(PasswordResetForm);
