import * as Types from "./types";

export const tablesReducer = (state = {}, action) => {
  switch (action.type) {
    case Types.FETCH_INBOUND_SUCCESS:
      return {
        ...state,
        inboundList: action.payload
      };
    case Types.FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        sessionList: action.payload
      };
    case Types.FETCH_TRANSACTION_ERRORS_SUCCESS:
      return {
        ...state,
        transactionErrorList: action.payload
      };
    case Types.FETCH_BLACKLISTS_SUCCESS:
      return {
        ...state,
        blacklistList: action.payload
      };
    case Types.FETCH_WEB_HOOK_ERROR_LOG_SUCCESS:
      return {
        ...state,
        webhookErrorLogList: action.payload
      };
      
    case Types.FETCH_WEB_HOOK_LOG_SUCCESS:
      return {
        ...state,
        webhookLogList: action.payload
      };

    case Types.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionList: action.payload
      };

    default:
      return { ...state };
  }
};
