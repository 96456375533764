import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import { getUserProfile, logout, updateUserProfile } from "../reducers";
import RemoteSubmitButton from "../RemoteSubmitButton";
import PasswordResetForm from "./PasswordResetForm";
import { resetPasswordStyles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { DialogTitle, DialogContent, DialogActions } from "@material-ui/core";

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.handleClosePassReset = this.handleClosePassReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getUserProfile(this.props.username));
  }

  componentWillReceiveProps(nextProps) {
    document.body.style.overflow = "auto"; // force overflow auto during password reset
  }

  handleClosePassReset(e) {
    this.props.dispatch(logout(this.props.username));
  }

  handleSubmit(form) {
    const { userProfile } = this.props;
    userProfile.password = form.password;
    userProfile.forcePassReset = 0;
    this.props.dispatch(updateUserProfile(userProfile));
  }

  render() {
    const { userProfile, classes } = this.props;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={
          userProfile !== undefined ? userProfile.forcePassReset === 1 : false
        }
      >
        <DialogTitle>Password Reset</DialogTitle>
        <DialogContent>
          <PasswordResetForm
            submitCallback={this.handleSubmit}
            initialValues={userProfile}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={this.handleClosePassReset}
            className={classes.Button}
          >
            Cancel
          </Button>
          <RemoteSubmitButton
            component={Button}
            form="passwordResetForm"
            label="Submit"
            color="primary"
            variant="outlined"
            className={classes.button}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    username: state.authentication.username,
    userProfile: state.profile.profile
  };
};

export default connect(mapStateToProps)(
  withStyles(resetPasswordStyles)(PasswordReset)
);
