export const API_VERSION = "api/v1/";


export const AT_LEAST_ONE_LETTER = /(.*[a-z].*)/;
export const AT_LEAST_ONE_CAPITAL = /(.*[A-Z].*)/;
export const AT_LEAST_ONE_NUMBER = /(.*[0-9].*)/;

export const ALPHANUMERIC_PATTERN = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/; //only letters and number(ie no symboles) 
export const PASSWORDLENGTH = 8;

export const DEFAULT_REPORTING_LAST_X_DAYS = 30;
export const REPORTING_LAST_X_DAYS_CONFIG_KEY = "reporting|last|x|days";

export const MICROSOFT_SSO_COX_CLIENT_ID = "c9ed4a0a-ad17-4979-8dd9-b4addfb0857b";
export const MICROSOFT_SSO_COX_TENANT_ID = "9feebc97-ff04-42c9-a152-767073872118";
export const MICROSOFT_SSO_COX_REDIRECT_URI = "https://eventgateway.priority.prod.aws.svc.cox.net/#/login";

export const NODE_ACTION_SMSGW = "smsgateway";
export const NODE_ACTION_API_CALL = "apicall";
export const NODE_ACTION_MSGCENTER_EMAIL = "messagecenter";
export const NODE_ACTION_ENRICHMENT = "enrichment";
export const NODE_ACTION_ACC_SOAP_CALL = "accsoapcall";
export const NODE_ACTION_CONTACT_ENGINE = "contactengine"
export const NODE_ACTION_SNOWFLAKE = "snowflake"

export const NODE_ACTION_SMSGW_LABEL = "SMS Gateway";
export const NODE_ACTION_API_CALL_LABEL = "API Call";
export const NODE_ACTION_MSGCENTER_EMAIL_LABEL = "Message Center Call";
export const NODE_ACTION_ENRICHMENT_LABEL = "Profile Enrichment";
export const NODE_ACTION_ACC_SOAP_CALL_LABEL = "ACC SOAP Call";
export const NODE_ACTION_CONTACT_ENGINE_LABEL = "Contact Engine"
export const NODE_ACTION_SNOWFLAKE_LABEL = "Snowflake"

export const NODE_ACTION_SMSGW_PAYLOAD_LABEL = "SMS Gateway Payload";
export const NODE_ACTION_API_CALL_PAYLOAD_LABEL = "Request Payload";
export const NODE_ACTION_API_SAMPLE_RESPONSE_LABEL = "Sample Response";
export const NODE_ACTION_MSGCENTER_EMAIL_PAYLOAD_LABEL = "Message Center Payload";
export const NODE_ACTION_ENRICHMENT_PAYLOAD_LABEL = "Request Payload";

export const STRING_OPERATORS = [
    { name: 'str_==', label: 'equals' },
    { name: 'str_includes', label: 'contains' },
    { name: 'str_startsWith', label: 'begins with' },
    { name: 'str_endsWith', label: 'ends with' },
    { name: 'str_!=', label: 'is not equal to' },,
    { name: 'str_!includes', label: 'does not contain' },
    { name: 'str_!startsWith', label: 'does not begin with' },
    { name: 'str_!endsWith', label: 'does not end with' }
];

export const NUMBER_OPERATORS = [
    { name: 'num_===', label: '===' },
    { name: 'num_!==', label: '!==' },
    { name: 'num_<', label: '<' },
    { name: 'num_>', label: '>' },
    { name: 'num_<=', label: '<=' },
    { name: 'num_>=', label: '>=' }
];

export const BOOLEAN_OPERATORS = [
    { name: 'boo_===', label: 'is' }
]

export const ARRAY_OPERATORS = [
    { name: 'arr_includes', label: 'contains' },
    { name: 'arr_!includes', label: 'does not contain' },
]

export const SNOWFLAKE_OPERATORS = [
    { name: '=', label: '=' },
    { name: '!=', label: '!=' },
    { name: '<', label: '<' },
    { name: '>', label: '>' },
    { name: '<=', label: '<=' },
    { name: '>=', label: '>=' },
    { name: 'contains', label: 'contains' },
    { name: 'beginsWith', label: 'begins with' },
    { name: 'endsWith', label: 'ends with' },
    { name: 'doesNotContain', label: 'does not contain' },
    { name: 'doesNotBeginWith', label: 'does not begin with' },
    { name: 'doesNotEndWith', label: 'does not end with' },
    { name: 'null', label: 'is null' },
    { name: 'notNull', label: 'is not null' },
    { name: 'in', label: 'in' },
    { name: 'notIn', label: 'not in' },
    { name: 'between', label: 'between' },
    { name: 'notBetween', label: 'not between' },
    { name: 'substring', label: 'substring' }
  ];

export const NODE_ACTION_ACC_SOAP_CALL_PAYLOAD_LABEL = "";