import React from "react";
import { connect } from "react-redux";
import { List, ListItem } from "material-ui/List";
import { AppBar, Dialog, FlatButton, Drawer, TextField, IconButton } from "material-ui";
import DeleteIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import SaveIcon from '@material-ui/icons/Save';
import CommunicationCallSplit from "material-ui/svg-icons/communication/call-split";
import NavigationArrowForward from "material-ui/svg-icons/navigation/arrow-forward";
import FlowchartForm from "./FlowchartForm";
import FolderForm from "./FolderForm";
import RemoteSubmitButton from "../RemoteSubmitButton";
import LinearProgress from "material-ui/LinearProgress";
import classnames from "classnames";
import { FlowchartTree } from "../Flowchart";
import { logout, dbSelectors, menuSelectors, flowchartActions, menuActions, dbActions } from "../reducers";
import HeaderBar from "../Header";
import { FindFlowchartTooltip } from "../utils/Tooltip";
import "../App.css";
import logo from "../logo_munvo_picto-h_inverse.svg";
import { munvoBlue } from "../utils/globalStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { theme } from "./styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import SortableTree, { toggleExpandedForAll } from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import SimulationForm from "./SimulationForm";


class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simulationFormOpen: false,
      flowchartFormOpen: false,
      folderFormOpen: false,
      searchString: "",
      currentFlowchart: "",
      menuEdit: this.props.menuEdit,
      treeData: this.props.treeData
    };
    this.renderCallBack = this.renderCallBack.bind(this);
    this.handleCloseWarning = this.handleCloseWarning.bind(this);
    this.handleTreeDataChange = this.handleTreeDataChange.bind(this);
    this.handleMenuEdit = this.handleMenuEdit.bind(this);
    this.handleFlowchartSubmit = this.handleFlowchartSubmit.bind(this);
    this.handleSimulationSubmit = this.handleSimulationSubmit.bind(this);
    this.handleFlowchartClick = this.handleFlowchartClick.bind(this);
    this.handleSaveMenuTree = this.handleSaveMenuTree.bind(this);
    this.handleSimulationDialogOpen = this.handleSimulationDialogOpen.bind(this);
    this.handleSimulationDialogClose = this.handleSimulationDialogClose.bind(this);
    this.handleFlowchartDialogOpen = this.handleFlowchartDialogOpen.bind(this);
    this.handleFlowchartDialogClose = this.handleFlowchartDialogClose.bind(this);
    this.handleFolderSubmit = this.handleFolderSubmit.bind(this);
    this.handleFolderDelete = this.handleFolderDelete.bind(this);
    this.handleFolderDialogOpen = this.handleFolderDialogOpen.bind(this);
    this.handleFolderDialogClose = this.handleFolderDialogClose.bind(this);
    this.filterMenuItems = this.filterMenuItems.bind(this);
    this.canDropFunc = this.canDropFunc.bind(this);
    this.canDragFunc = this.canDragFunc.bind(this);
  }

  async componentWillMount() {
    await this.props.fetchMenuItems();
  };

  componentDidUpdate(prevProps) {
    if(prevProps.treeData !== this.props.treeData) {
      //console.log('in props: ' + JSON.stringify(this.props)) //for debugging
      //console.log('treeData in nextProps: ')
      //console.log(JSON.stringify(this.props.treeData))
      this.setState({ treeData: this.props.treeData});
    }

    if(prevProps.menuEdit !== this.props.menuEdit) {
      //if(this.props.menuEdit) console.log('menuEdit on in nextProps') //for debugging
      //if(!this.props.menuEdit) console.log('menuEdit off in nextProps')
      this.setState({ menuEdit: this.props.menuEdit})
    }
  }

  renderCallBack = () => {
    this.forceUpdate();
  };

  handleCloseWarning = () => {
    this.setState({ openUnsaved: false })
  };

  handleTreeDataChange = newTreeData => {
    //('handle tree data: ' + JSON.stringify(newTreeData));
    this.props.updateMenuTreeData(newTreeData);
  }

  handleMenuEdit = () => {this.props.toggleMenuEdit()};

  handleFlowchartSubmit = form => {
    this.props.newFlowchart(form);
    this.handleFlowchartDialogClose();
  };

  handleSimulationSubmit = async form => {
    this.setState({
      simulationLoading: true
    })
    var response = await this.props.postSimulatedInbound(form.payload)
    this.setState({
      simulationResponse: response,
      simulationLoading: false
    })
  }

  handleFlowchartClick = (flowchart) => {
    if (this.props.saved) {
      //console.log('loaded flw: ' + JSON.stringify(flowchart)); //for debugging
      this.props.loadFlowchart(flowchart)
    } else {
      this.setState({ currentFlowchart: flowchart })

    }
  };

  handleSaveMenuTree = () => {
    this.props.saveMenuTree(this.state.treeData);
  }

  handleFlowchartDialogOpen = () => this.setState({ flowchartFormOpen: true });

  handleFlowchartDialogClose = () => this.setState({ flowchartFormOpen: false });

  handleSimulationDialogOpen = () => this.setState({ simulationFormOpen: true });

  handleSimulationDialogClose = () => this.setState({ simulationFormOpen: false, simulationResponse: "" });

  handleFolderSubmit = form => {
    this.props.newMenuFolder(form.name, this.state.treeData);
    this.handleFolderDialogClose();
  };

  handleFolderDelete = rowInfo => {
    this.props.deleteMenuFolder(rowInfo);
  };

  handleFolderDialogOpen = () => this.setState({ folderFormOpen: true});

  handleFolderDialogClose = () => this.setState({ folderFormOpen: false });

  filterMenuItems = event => {
    this.setState({ searchString: event.target.value });
  };

  canDropFunc = ({nextParent}) => {
    if(nextParent && nextParent.flowchart != null) return false;

    return true;
  };

  canDragFunc = () => this.state.menuEdit

  render() {
    const title = "Event Gateway - Flowcharts";

    const UnsavedFlowchartDialog = () => {
      return (
        <Grid item xs={12}>
          <Dialog
            title="You have an unsaved flowchart"
            actions={[
              <Button
                variant="outlined"
                onClick={this.handleCloseWarning}
              >
                Cancel
              </Button>,
              <MuiThemeProvider theme={theme}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    this.props.loadFlowchart(this.state.currentFlowchart);
                    this.handleCloseWarning();
                  }}
                >
                  Continue Without Saving
                </Button>
              </MuiThemeProvider>

            ]}
            open={this.state.openUnsaved}
            onRequestClose={this.handleCloseWarning}
            autoScrollBodyContent={true}
            contentStyle={{
              position: "absolute",
              left: "50%",
              top: "50%",
              height: "80%",
              transform: "translate(-50%, -50%)"
            }}
          >
            Your current flowchart is unsaved. If you leave this page before
            saving you will lose any unsaved progress.
          </Dialog>
        </Grid>
      );
    }; 

    const flowchartMenuItems = [
      <ListItem
        primaryText="New flowchart"
        leftIcon={<AddIcon />}
        onClick={this.handleFlowchartDialogOpen}
      />,
      <ListItem
        primaryText="New folder"
        leftIcon={<CreateNewFolderIcon />}
        onClick={this.handleFolderDialogOpen}
      /> ];

    if(this.state.menuEdit) {
      flowchartMenuItems.push(
        <ListItem
          primaryText="Edit flowcharts list"
          leftIcon={<EditIcon />}
          style={{backgroundColor: 'rgba(38, 86, 141, 0.39)'}}
          onClick={this.handleMenuEdit}
        />,
        <ListItem
          primaryText="Save flowcharts list"
          leftIcon={<SaveIcon />}
          style={{backgroundColor: 'rgba(38, 86, 141, 0.39)'}}
          onClick={this.handleSaveMenuTree}
        />
      );
    } else {
      flowchartMenuItems.push(
        <ListItem
          primaryText="Edit flowcharts list"
          leftIcon={<EditIcon />}
          onClick={this.handleMenuEdit}
        />
      );
    };

    const filteredTreeData = this.state.searchString.length > 0 ? toggleExpandedForAll({treeData: this.state.treeData, expanded: true}) : this.state.treeData;

    return (
      <div className="App">
        <HeaderBar pageTitle={title} />
        <Drawer docked={true} open={true}>
          <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
            <AppBar
              showMenuIconButton={false}
              style={{ background: munvoBlue }}
              title={<img src={logo} alt="logo" style={{ width: "95%" }} />}
            />
            <TextField
              data-tip
              data-for="search-flowchart-tt"
              style={{ textIndent: 30 }}
              hintText="Find a Flowchart"
              underlineFocusStyle={{ borderColor: munvoBlue }}
              value={this.state.searchString}
              onChange={this.filterMenuItems}
            />
            <FindFlowchartTooltip />
            <Dialog
              open={this.state.simulationFormOpen}
              title="Event Simulation"
              onRequestClose={this.handleSimulationDialogClose}
              actions={[
                <FlatButton
                  label="Close"
                  labelStyle={{ color: munvoBlue }}
                  primary={true}
                  onClick={this.handleSimulationDialogClose}
                />,
                <RemoteSubmitButton
                  component={FlatButton}
                  form="simulationForm"
                  label="Generate"
                  primary={true}
                  style={{ color: munvoBlue }}
                />
              ]}
            >
              <p>Generate an event with a simulator. Use the field below to enter a JSON inbound.</p>
              <SimulationForm submitCallback={this.handleSimulationSubmit} response={this.state.simulationResponse} loading={this.state.simulationLoading} />
            </Dialog>
            <List>
              <ListItem
                id="simulationMenuItem"
                primaryText="Simulation"
                leftIcon={<CompareArrowsIcon />}
                onClick={this.handleSimulationDialogOpen}
              />
              <ListItem
                primaryText="Flowcharts"
                leftIcon={<CommunicationCallSplit />}
                initiallyOpen={true}
                primaryTogglesNestedList={true}
                nestedItems={flowchartMenuItems}
              />
            </List>
            <div style={{ height: '61%', width: '120%', overflow: 'overlay', paddingRight: '-20px'}}>
              <div style={{ height: '100%', width: '100%'}}>
                <SortableTree
                  theme={FileExplorerTheme}
                  treeData={filteredTreeData}
                  onChange={this.handleTreeDataChange}
                  canDrag={this.canDragFunc}
                  canDrop={this.canDropFunc}
                  rowHeight={50}
                  generateNodeProps={rowInfo => {

                  var folderIcon = <FolderIcon style={{width: 48}}/>;

                    if(this.state.menuEdit) {
                      folderIcon =  <IconButton onClick={() => this.handleFolderDelete(rowInfo)}>
                                      <DeleteIcon fontSize="small"/>
                                    </IconButton>
                    } else {
                      if(rowInfo.node.expanded) folderIcon = <FolderOpenIcon style={{width: 48}}/>;
                    }

                    return {
                      onClick: () => {if(!rowInfo.node.isDirectory) this.handleFlowchartClick(rowInfo.node.flowchart)},
                      icons: rowInfo.node.isDirectory ? [folderIcon] : [<NavigationArrowForward style={{width: 48}}/>],
                      style: {height:50, overflowWrap: 'anywhere'},
                      className: this.state.searchString !== '' && (rowInfo.node.title.toLowerCase().includes(this.state.searchString.toLowerCase())) ? 'menuItemSearchResult' : null
                    };
                  }}
                />
              </div>
            </div>

            <Dialog
              open={this.state.flowchartFormOpen}
              title="Create a new flowchart"
              onRequestClose={this.handleFlowchartDialogClose}
              actions={[
                <FlatButton
                  label="Cancel"
                  labelStyle={{ color: munvoBlue }}
                  primary={true}
                  onClick={this.handleFlowchartDialogClose}
                />,
                <RemoteSubmitButton
                  component={FlatButton}
                  form="flowchartform"
                  label="Submit"
                  primary={true}
                  style={{ color: munvoBlue }}
                />
              ]}
            >
              <FlowchartForm submitCallback={this.handleFlowchartSubmit} />
            </Dialog>

            <Dialog
              open={this.state.folderFormOpen}
              title="Create a new folder"
              onRequestClose={this.handleFolderDialogClose}
              actions={[
                <FlatButton
                  label="Cancel"
                  labelStyle={{ color: munvoBlue }}
                  primary={true}
                  onClick={this.handleFolderDialogClose}
                />,
                <RemoteSubmitButton
                  component={FlatButton}
                  form="folderform"
                  label="Submit"
                  primary={true}
                  style={{ color: munvoBlue }}
                />
              ]}
            >
              <FolderForm submitCallback={this.handleFolderSubmit} />
            </Dialog>

          </div>
        </Drawer>
        <div className={classnames("app-content", { expanded: true })}>
          {this.props.isLoading || this.props.urlListIsLoading ? (
            <LinearProgress mode="indeterminate" style={{ width: "110%" }} />
          ) : null}
          <FlowchartTree submitCallback={this.renderCallBack} />
        </div>
        <UnsavedFlowchartDialog />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    treeData: menuSelectors.getParsedMenuTree(state),
    flowcharts: dbSelectors.getFlowchartList(state),
    isLoading: state.db.loading,
    saved: state.flowcharts.saved,
    menuEdit: menuSelectors.getMenuEdit(state)
  };
};

const mapDispatchToProps = { ...menuActions, ...flowchartActions, ...dbActions, logout };
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
