import React from "react";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export const flowchartStyles = theme => ({
  button: {
    margin: theme.spacing(),
  }
})

export const MaterialActionElement = ({ className, handleOnClick, label, title }) => (
  <Button
    variant="contained"
    color="primary"
    className={className}
    title={title}
    size="small"
    onClick={(e) => handleOnClick(e)}>
    {label}
  </Button>
);

export const MaterialValueEditor = ({
  operator,
  value,
  handleOnChange,
  title,
  className,
  type,
  inputType,
  values
}) => {
  if (operator === 'null' || operator === 'notNull') {
    return null;
  }

  switch (type) {
    case 'select':
      return (
        <FormControl variant="standard" className={className}>
          <Select value={value} onChange={(e) => handleOnChange(e.target.value)}>
            {values.map((v) => (
              <MenuItem key={v.name} value={v.name}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case 'checkbox':
      return (
        <Checkbox
          className={className}
          onChange={(e) => handleOnChange(e.target.checked)}
          checked={!!value}
        />
      );

    default:
      return (
        <Input
          type={inputType || 'text'}
          value={value}
          title={title}
          className={className}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );
  }
};

export const MaterialValueSelector = ({
  className,
  handleOnChange,
  options,
  value,
  title
}) => (
  <FormControl className={className} title={title}>
    <Select value={value} onChange={(e) => handleOnChange(e.target.value)}>
      {options.map((option) => {
        const key = option.id ? `key-${option.id}` : `key-${option.name}`;
        return (
          <MenuItem style={{zIndex: 1000+' !important', position: 'relative'}} key={key} value={option.name}>
            {option.label}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
);