import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import * as Constants from "./Constants";
import { formatQuery } from 'react-querybuilder';

export const checkForNodeName = (name, currentNode) => {
  if (currentNode.name !== undefined && name.toLowerCase() === currentNode.name.toLowerCase()) {
    return true
  } else if (currentNode.children === undefined) {
    return false
  } else {
    for (let i = 0; i < currentNode.children.length; i++) {
      let currentChild = currentNode.children[i]
      let result = checkForNodeName(name, currentChild)

      if (result) {
        return true;
      }
    }
    return false
  }
}

//todo check if useless ? 
export const setWebhookIdToNode = (currentNode, webhookUrlId) => {
  currentNode.webhookUrlId = webhookUrlId

  if (currentNode.children !== undefined) {
    currentNode.children = currentNode.children.map(child => {
      return setWebhookIdToNode(child, webhookUrlId)
    })
  }

  return currentNode
}

export const wipeWebhookFlags = (currentNode) => {
  //if webhook isnt enabled wipe the webhookUrlId
  if (currentNode.webhookEnabled === 0) {
    currentNode.webhookUrlId = null
  }


  if (currentNode.children !== undefined) {
    currentNode.children = currentNode.children.map(child => {
      return wipeWebhookFlags(child)
    })
  }

  return currentNode
}

export const createNotification = (title, message) => {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: 'Ok'
      }
    ]
  })
}

export const getJwtToken = () => {
  return localStorage.getItem('jwt-token') ? localStorage.getItem('jwt-token') : sessionStorage.getItem('jwt-token')
}

export const getLoggedInUser = () => {
  return localStorage.getItem('username') ? localStorage.getItem('username') : sessionStorage.getItem('username')
}

export const revertTree = (tree, metadata, flowchartOptions, root = { priority: 1 }) => {
  delete metadata.flowchartOptions
  const fullTree = root
  fullTree.children = tree
  return {
    ...metadata,
    flowchartOptions,
    root: revertNode(fullTree)
  }
}

function getActionLabel(actionName) {
  switch(actionName) {

    case Constants.NODE_ACTION_SMSGW:
      return Constants.NODE_ACTION_SMSGW_LABEL;
    
    case Constants.NODE_ACTION_MSGCENTER_EMAIL:
      return Constants.NODE_ACTION_MSGCENTER_EMAIL_LABEL;
    
    case Constants.NODE_ACTION_API_CALL:
      return Constants.NODE_ACTION_API_CALL_LABEL;
    
    case Constants.NODE_ACTION_ENRICHMENT:
      return Constants.NODE_ACTION_ENRICHMENT_LABEL;
    
    case Constants.NODE_ACTION_ACC_SOAP_CALL:
      return Constants.NODE_ACTION_ACC_SOAP_CALL_LABEL;

    case Constants.NODE_ACTION_CONTACT_ENGINE:
      return Constants.NODE_ACTION_CONTACT_ENGINE_LABEL;

    case Constants.NODE_ACTION_SNOWFLAKE:
      return Constants.NODE_ACTION_SNOWFLAKE_LABEL;

    default:
      return ''
  }
}

export const findPredefinedSchema = tree => {
  if (tree.flowchartOptions) {
    return tree.flowchartOptions.filter(x => x.key == "predefinedSchema").map(x => x.value)[0]
  }
  return "{}";
};

export const parseNode = (node, schema, depth = 0) => {
  let newDepth = depth + 1

  if (depth === 0 && _.isEmpty(node)) {
    return parseNode({
      priority: 1,
      children: [{ priority: 1 }]
    }, "{}")
  }

  //reply is the value stored in db that may contain the DELIMITER
  //replies is an array containing all the linked replies, excluding the first reply
  const { name, customCondition, conditionStatement, conditionQuery, conditionSwitch, actions, webhookEnabled, webhookUrlId, targetNode, portalMode, children } = node; 
  var isWebhookEnabled = webhookEnabled;

  if (!webhookEnabled) {
    isWebhookEnabled = 0
  }

  // const linkedRepliesHTML = fullReply && fullReply.length > 0 ? fullReply.split(DELIMITER).map((reply, replyIndex) =>
  //   <li key={replyIndex}>{"Reply: " + (reply || '')}</li>
  // ) : undefined;


  const subtitleHTML =
      <div>
        <br/>
        <div>{"Condition statement: " + (conditionStatement || 'Catch-all')}</div>

        {actions && actions.length > 0 &&
          <div>
            <br/>
            {actions.map((action, actionIndex) => 
              <li key={actionIndex}>{"Action > " +  getActionLabel(Object.keys(actions[actionIndex])[0]) + ': ' + action[Object.keys(actions[actionIndex])[0]].description}</li>
            )}
          </div>
        }
      </div>;
  
  var childConditionSchema = schema
  if (actions && actions.length !== 0) {
    actions.forEach(actionIndex => {
      if (actionIndex.hasOwnProperty('apicall')) {
        if (actionIndex.apicall.hasOwnProperty('response') && actionIndex.apicall.hasOwnProperty('name')) {
          var response = JSON.parse(actionIndex.apicall.response)
          var apiName = buildApiName(actionIndex.apicall.name)
          childConditionSchema = buildPayloadScheme(schema, response, apiName)
        }
      }
    })
  }

  const newNode = {
    ...node,
    name: name,
    conditionStatement: conditionStatement,
    actions: actions,
    title: <Grid item xs={12}>{name ? "Name: " + (name || '') : "Name: "}</Grid>,
    subtitle:<div><Grid item xs={12}>{subtitleHTML}</Grid></div>,
    // subtitle: fullReply && fullReply.includes(DELIMITER) ? (
    //   <div> <Grid className="node-name" item xs={12}>{name ? "Name: " + (name || '') : ''}</Grid> <Grid item xs={12}><ol className="linkedRepliesNode">{linkedRepliesHTML}</ol></Grid></div>
    // ) : <div><Grid className="node-name" item xs={12}>{name ? "Name: " + (name || '') : ''}</Grid> <Grid item xs={12}>{"Reply: " + (fullReply || '')}</Grid></div>,}
    webhookEnabled: isWebhookEnabled,
    webhookUrlId: webhookUrlId,
    targetNode: targetNode === undefined ? JSON.parse("{\"name\":\"\",\"id\":-1}") : targetNode,   // reference to the target name for redirection
    canNodeHaveChildren: (targetNode === undefined || targetNode.name === "") ? true : false, // if node  has a target node it cant have a child
    portalMode: portalMode ? portalMode : "",
    expanded: true,
    conditionQuery: conditionQuery,
    conditionSwitch: conditionSwitch,
    customCondition: customCondition,
    payloadSchema: name == "" ? "{}" : schema,
    children: children && children.length > 0
      ? children.map(node => parseNode(node, childConditionSchema, newDepth)) : undefined  //add schema as param
  }
  return newNode
}

const buildPayloadScheme = (schema, response, responseName) => {
  try {
    var schemaJSON = JSON.parse(schema)
    schemaJSON[responseName] = response
    return JSON.stringify(schemaJSON)
  } catch(e) {
    console.log(e)
  }
}

const sortTree = tree => {
  let sortedTree = tree.sort((a, b) => a.id - b.id)
  for (let i = 0; i < sortedTree.length; i++) {
    if (sortedTree[i].children) {
      sortTree(sortedTree[i].children)
    }
  }
  return sortedTree
}

export const removeIds = tree => {
  if (!tree) {
    return null
  }
  for (let i = 0; i < tree.length; i++) {
    tree[i].id = null;
    //remove portal id
    if (tree[i].targetNode != null) {
      tree[i].targetNode.id = null;
    }
    if (tree[i].children) {
      removeIds(tree[i].children)
    }
  }
  return tree;
}

export const removeFlowchartOptionsIds = flowchartOptions => {

  for (var i = 0; i < flowchartOptions.length; i++) {
    flowchartOptions[i].id = null;
  }

  return flowchartOptions;
}

export const parseTree = tree => {
  if (!tree) return tree;
  const { root, ...metadata } = tree
  const predefinedSchema = findPredefinedSchema(tree)
  const parsedTree = [parseNode(root, predefinedSchema)]
  const newTree = sortTree(parsedTree[0].children)
  const { children, ...rootNode } = parsedTree[0]
  const availableTarget = getAvailableTarget(parsedTree, true)

  //console.log('tree: ' + JSON.stringify(tree)); //for debugging
  //console.log('New tree: ' + JSON.stringify(newTree));

  return {
    newTree,
    metadata,
    root: rootNode,
    availableTarget
  }
}

//input a parsed tree (ie parseNode(root)
//return a list of all nodes with a non empty node name and no targetnode and doesnt have a child
export const getAvailableTarget = (node, root) => {

  var availableTarget = []

  if (root) {
    node = node[0];
  }

  //base case

  if (node.children === undefined || node.children.length === 0) {

    const currentTarget = node.targetNode
    const currentName = node.name

    //check if node isnt a portal node 
    if (currentTarget === undefined || currentTarget.name === "") {
      //check if node has a node name 
      if (currentName !== undefined && currentName !== "") {
        availableTarget.push(node)
        return availableTarget;
      }
    }
    return availableTarget;

  }

  //if the current node has children call recursively on all children
  //push what they return along with current node 
  //recursion case 

  for (var i = 0; i < node.children.length; i++) {
    const currentChildren = node.children[i];

    var childList = getAvailableTarget(currentChildren, false);

    for (var j = 0; j < childList.length; j++) {

      availableTarget.push(childList[j])

    }



  }

  //check for current 
  const currentTarget = node.targetNode
  const currentName = node.name

  //check if node isnt a portal node 
  if (currentTarget === undefined || currentTarget.name === "") {
    //check if node has a node name 
    if (currentName !== undefined && currentName !== "") {
      availableTarget.push(node)
    }
  }

  return availableTarget

}

//given a node and a function will apply the function to all the nodes, if node is a list of node isRoot needs to be false 
//can act as filter if the function given return what should be filtered
export const mapOnTree = (node, isRoot, func) => {

  if (node === undefined) {
    return;
  }
  var filteredList = []

  if (isRoot) {
    node = node[0];
  }

  //base case

  if ((node.children === undefined || node.children.length === 0) && node[0] === undefined) {

    var list = func(node)
    if (list && (list[0] !== undefined || list.name !== undefined)) {
      filteredList.push(list);
    }
    return filteredList;

  }

  var nodelist = []

  if (node[0] !== undefined) {
    nodelist = node;
  }
  else {
    nodelist = node.children;
  }

  //if the current node has children call recursively on all children
  //push what they return along with current node 
  //recursion case 

  for (var i = 0; i < nodelist.length; i++) {
    const currentChildren = nodelist[i];

    var childList = mapOnTree(currentChildren, false, func);

    for (var j = 0; j < childList.length; j++) {
      var list = func(childList[j])
      if (list && (list[0] !== undefined || list.name !== undefined)) {
        filteredList.push(list);
      }
    }

  }

  return filteredList

}

export const revertNode = ({ title, subtitle, expanded, children, ...node }) => {
  const json = { ...node }
  if (children) {
    json.children = children.map(child => revertNode(child))


  }

  return json
}

export const getReactElementText = (parent) => {
  if (parent == null) {
    return ''
  }
  if (typeof parent === 'string') {
    return parent;
  }
  if (
    (typeof parent !== 'object' && parent)
    || !parent.props
    || (
      (
        typeof parent.props.children !== 'string'
        && !parent.props.children
      )
      || typeof parent.props.children !== 'object'
    )
  ) {
    return '';
  }
  if (typeof parent.props.children === 'string') {
    return parent.props.children;
  }
  return parent.props.children
    .map(child => getReactElementText(child))
    .join('');
}

export const isValidPassword = (pwd) => {
  //check pwd has at least one letter, one bnumber and is big enough
  if (Constants.AT_LEAST_ONE_LETTER.test(pwd) &&
    Constants.AT_LEAST_ONE_NUMBER.test(pwd) &&
    (pwd.length >= Constants.PASSWORDLENGTH)) {
    return true;
  }
  return false;
}


export const stringSearch = (key, searchQuery, node, path, treeIndex) => {
  if (typeof node[key] === 'function') {
    // Search within text after calling its function to generate the text
    return (
      String(node[key]({ node, path, treeIndex })).toUpperCase().indexOf(searchQuery) > -1
    );
  } else if (typeof node[key] === 'object') {
    if (key === 'actions') {
      for (const actionIndex in node[key]){
        for (const actionType in node[key][actionIndex]){
          for (const actionObject in node[key][actionIndex][actionType]) {
            if (String(node[key][actionIndex][actionType][actionObject]).toUpperCase().indexOf(searchQuery) > -1) {
              return true;
            }
          }
        }
      }
      return false;
    } else {
      return getReactElementText(node[key]).toUpperCase().indexOf(searchQuery) > -1;
    }
  } else {
    return node[key] && String(node[key]).toUpperCase().indexOf(searchQuery) > -1;
  }
}

export const customSearch = ({ node, path, treeIndex, searchQuery }) => {
  const capsSearchQuery = searchQuery ? searchQuery.toUpperCase() : null
  return (
    stringSearch('name', capsSearchQuery, node, path, treeIndex)
    || stringSearch('actions', capsSearchQuery, node, path, treeIndex)
  ) && searchQuery.length !== 0
}

export const logMove = ({ node, treeIndex, path }) => console.log('node:', node, '\ntreeIndex:', treeIndex, '\npath:', path)

//from front-end to back-end
export const generateMenuTreePayload = menuTreeData => {
  var menuPayload = []

  for(var i=0; i < menuTreeData.length; i++) {
    var branchPayload = convertTreeDataToMenuItemFormat(menuTreeData[i]);
    branchPayload.orderNumber = i+1;
    menuPayload.push(branchPayload);
  }

  return menuPayload;
}

const convertTreeDataToMenuItemFormat = treeItem => {
  var branchItem = {};
  branchItem.expanded = treeItem.expanded;
  branchItem.id = treeItem.menuItemId;
  branchItem.createdTimestamp = treeItem.createdTimestamp;

  if(treeItem.flowchart != null) {
    branchItem.flowchart = treeItem.flowchart;
  }

  else if(treeItem.isDirectory) {
    branchItem.folder = {};
    branchItem.folder.id = treeItem.folderId;
    branchItem.folder.name = treeItem.title;
    branchItem.folder.createdTimestamp = treeItem.folderCreatedTimestamp;

    branchItem.children = [];

    var childrenAmount = treeItem.children ? treeItem.children.length : 0

    for(var i=0; i<childrenAmount; i++) {
      var branchItemChild = convertTreeDataToMenuItemFormat(treeItem.children[i]);
      branchItemChild.orderNumber = i+1;

      branchItem.children.push(branchItemChild);
    }
  }

  return branchItem;
}


//from back-end to front-end
export const generateMenuTreeData = menuPayload => {
  var treeData = []
  for(var i = 0; i < menuPayload.menuItems.length; i++) {
    var branchData = convertMenuItemToTreeDataFormat(menuPayload.menuItems[i]);
    treeData.push(branchData);
  }

  return treeData;
}

const convertMenuItemToTreeDataFormat = menuItem => {
  var branchData = {};
  branchData.expanded = menuItem.expanded;
  branchData.menuItemId = menuItem.id;
  branchData.createdTimestamp = menuItem.createdTimestamp;

  if(menuItem.flowchart != null) {
    branchData.title = menuItem.flowchart.name;
    branchData.flowchart = menuItem.flowchart;
    branchData.isDirectory = false;
  }

  else if(menuItem.folder != null) {
    branchData.title = menuItem.folder.name;
    branchData.isDirectory = true;
    branchData.folderId = menuItem.folder.id;
    branchData.folderCreatedTimestamp = menuItem.folder.createdTimestamp;
    branchData.children = [];

    var childrenAmount = menuItem.children ? menuItem.children.length : 0

    for(var i=0; i<childrenAmount; i++) {
      branchData.children.push(convertMenuItemToTreeDataFormat(menuItem.children[i]));
    }
  }

  return branchData;
}

//from front-end to back-end (only 1 flowchart since it is being saved)
//1. modify action json objects by turning keys (smsgateway/messagecenter/apicall) into actiontype values
//2. aggregates various fields for some outbound actions into a single payload for the backend
export const formatFlowchartPayloadForBackend = (tree) => {
  var newRoot = tree.root;

  for(let i=0; i<newRoot.children.length; i++) {
    var node = newRoot.children[i];
    var newNode = formatFlowchartPayloadForBackendRecursively(node);
    newRoot.children[i] = newNode;
  }

  tree.root = newRoot;

  return tree;
}

/*
Example:
  {
    ...
    "actions": [
      {
        "messagecenter": {
          "description": "x",
          ...
        }
      }
    ]
  }

  becomes

  {
    ...
    "actions": [
      {
        "actionType": "messagecenter",
        "description": "x",
        ...
      }
    ]
  }

*/

const formatFlowchartPayloadForBackendRecursively = (node) => {
  if(node.actions != null && node.actions !== undefined && node.actions.length > 0) {
    for(let i = 0; i<node.actions.length; i++) {
      var actionKey = Object.keys(node.actions[i])[0] //Object.keys(...) returns all the keys, but we only expect one key per action
      
      var actionJSON = node.actions[i][actionKey] //fetch the json associated to the key

      actionJSON.actionType = actionKey; //add new field actionType with value = actionKey

      //Aggregates various fields into one payload field for consumption by the backend
      if(actionJSON.actionType === Constants.NODE_ACTION_ACC_SOAP_CALL) {

        var aggregatePayload = {};
        aggregatePayload.namespace = actionJSON.namespace;
        aggregatePayload.bodyRootElement = actionJSON.bodyRootElement;
        aggregatePayload.bodyElements = actionJSON.bodyElements;

        actionJSON.payload = JSON.stringify(aggregatePayload);
        
        //removing those fields since they are now in the payload field
        delete actionJSON.namespace;
        delete actionJSON.bodyRootElement;
        delete actionJSON.bodyElements;
      } else if(actionJSON.actionType === Constants.NODE_ACTION_MSGCENTER_EMAIL) {

        var aggregatePayload = {};
        aggregatePayload.eventType = actionJSON.eventType;
        aggregatePayload.externalId = actionJSON.externalId;
        aggregatePayload.ctxElements = actionJSON.ctxElements;
        aggregatePayload.rtEventElements = actionJSON.rtEventElements;
        aggregatePayload.wishedChannel = actionJSON.wishedChannel
        aggregatePayload.communication = actionJSON.communication
        actionJSON.payload = JSON.stringify(aggregatePayload);

        //removing those fields since they are now in the payload field
        delete actionJSON.eventType;
        delete actionJSON.externalId;
        delete actionJSON.email;
        delete actionJSON.wishedChannel;
        delete actionJSON.communication
        delete actionJSON.ctxElements;
        delete actionJSON.rtEventElements;
        
      } else if(actionJSON.actionType === Constants.NODE_ACTION_CONTACT_ENGINE){
        var aggregatePayload = {};
        // aggregatePayload.description = actionJSON.description;
        aggregatePayload.templateID = actionJSON.templateID;
        aggregatePayload.type = actionJSON.type;
        // aggregatePayload.destination = actionJSON.destination;
        actionJSON.payload = JSON.stringify(aggregatePayload);

        //removing those fields since they are now in the payload field
        // delete actionJSON.description
        delete actionJSON.templateID;
        delete actionJSON.type;
        // delete actionJSON.destination;

      } else if (actionJSON.actionType === Constants.NODE_ACTION_SNOWFLAKE) {
        var aggregatePayload = {};
        aggregatePayload.description = actionJSON.description
        aggregatePayload.connection = actionJSON.connection
        aggregatePayload.table = actionJSON.table
        aggregatePayload.advancedSwitch = actionJSON.advancedSwitch
        if (actionJSON.advancedSwitch == true) {
          aggregatePayload.advancedQuery = actionJSON.advancedQuery
          aggregatePayload.sqlQuery = actionJSON.advancedQuery
        } else {
          aggregatePayload.simpleQuery = actionJSON.simpleQuery
          aggregatePayload.sqlQuery = formatQuery(actionJSON.simpleQuery, "sql")
        }
        aggregatePayload.enrichmentName = actionJSON.enrichmentName
        aggregatePayload.enrichmentField = actionJSON.enrichmentField

        actionJSON.payload = JSON.stringify(aggregatePayload);

        delete actionJSON.description
        delete actionJSON.connection
        delete actionJSON.table 
        delete actionJSON.sqlQuery
        delete actionJSON.advancedSwitch
        delete actionJSON.enrichmentName
        delete actionJSON.enrichmentField
        if (aggregatePayload.advancedSwitch == true) {
          delete actionJSON.simpleQuery
        } else {
          delete actionJSON.advancedQuery
        }
        
      } else if (actionJSON.actionType === Constants.NODE_ACTION_API_CALL) {
        var aggregatePayload = {};
        aggregatePayload.requestBody = actionJSON.requestBody
        aggregatePayload.encryptBody = actionJSON.encryptBody ? true : false
        aggregatePayload.headers = actionJSON.headers
        aggregatePayload.httpMethod = actionJSON.httpMethod
        actionJSON.payload = JSON.stringify(aggregatePayload);

        delete actionJSON.requestBody
        delete actionJSON.headers
        delete actionJSON.encryptBody
        delete actionJSON.httpMethod
      }
      
      node.actions[i] = actionJSON; //swap json for new json with actionType

    }
  }

  if(node.children && node.children.length > 0) {
    for(let i=0; i<node.children.length; i++) {
      formatFlowchartPayloadForBackendRecursively(node.children[i]);
    }
  }

  return node;
}


//from back-end to front-end (all flowcharts, since the back-end returns a list)
//1. modify action json objects by turning actiontype values into keys (smsgateway/messagecenter/apicall)
//2. for some outbound actions, takes items out of their payload (removal of the 'payload' parent field)
//used by flowchart endpoints (post/get/put/delete)
export const formatFlowchartPayloadForFrontend = (flowcharts) => {
  for(let j=0; j<flowcharts.length; j++) {
    var newRoot = flowcharts[j].root;

    for(let i=0; i<newRoot.children.length; i++) {
      var node = newRoot.children[i];
      var newNode = formatFlowchartPayloadForFrontendRecursively(node);
      newRoot.children[i] = newNode;
    }
  
    flowcharts[j].root = newRoot;
  }


  return flowcharts;
}

//used by menuItem endpoint (get)
export const formatFlowchartPayloadForFrontendInMenuItems = (menuItems) => {
  for(let j=0; j<menuItems.length; j++) {

    //some menu items are folders, check their content for flowcharts
    if(!menuItems[j].flowchart && menuItems[j].folder) {
      if(menuItems[j].children) menuItems[j].children = formatFlowchartPayloadForFrontendInMenuItems(menuItems[j].children);
      continue;
    }

    var newRoot = menuItems[j].flowchart.root;

    for(let i=0; i<newRoot.children.length; i++) {
      var node = newRoot.children[i];
      var newNode = formatFlowchartPayloadForFrontendRecursively(node);
      newRoot.children[i] = newNode;
    }
  
    menuItems[j].flowchart.root = newRoot;
  }

  return menuItems;
}

/*
Example:

  {
    ...
    "actions": [
      {
        "actionType": "messagecenter",
        "description": "x",
        ...
      }
    ]
  }

  becomes

  {
    ...
    "actions": [
      {
        "messagecenter": {
          "description": "x",
          ...
        }
      }
    ]
  }

*/

const formatFlowchartPayloadForFrontendRecursively = (node) => {
  if(node.actions != null && node.actions !== undefined && node.actions.length > 0) {
    for(let i = 0; i<node.actions.length; i++) {

      var actionType = node.actions[i].actionType; //fetch the value that will become our key

      //Removes the payload parent field and takes its items up one level
      if(actionType === Constants.NODE_ACTION_ACC_SOAP_CALL) {
  
        var payloadJSON = JSON.parse(node.actions[i].payload);

        node.actions[i].namespace = payloadJSON.namespace;
        node.actions[i].bodyRootElement = payloadJSON.bodyRootElement;
        node.actions[i].bodyElements = payloadJSON.bodyElements;

        delete node.actions[i].payload;
      } else if(actionType === Constants.NODE_ACTION_MSGCENTER_EMAIL) {
  
        var payloadJSON = JSON.parse(node.actions[i].payload);

        node.actions[i].eventType = payloadJSON.eventType;
        node.actions[i].email = payloadJSON.email;
        node.actions[i].externalId = payloadJSON.externalId;
        node.actions[i].wishedChannel = payloadJSON.wishedChannel;
        node.actions[i].communication = payloadJSON.communication
        node.actions[i].ctxElements = payloadJSON.ctxElements;
        node.actions[i].rtEventElements = payloadJSON.rtEventElements;

        delete node.actions[i].payload;
      } else if(actionType === Constants.NODE_ACTION_CONTACT_ENGINE){
        var payloadJSON = JSON.parse(node.actions[i].payload);

        // node.actions[i].description = payloadJSON.description;
        node.actions[i].templateID = payloadJSON.templateID;
        node.actions[i].type = payloadJSON.type;
        // node.actions[i].destination = payloadJSON.destination;

        delete node.actions[i].payload;
      }

      else if(actionType === Constants.NODE_ACTION_SNOWFLAKE) {
  
        var payloadJSON = JSON.parse(node.actions[i].payload);

        node.actions[i].description = payloadJSON.description;
        node.actions[i].table = payloadJSON.table;
        node.actions[i].connection = payloadJSON.connection;
        node.actions[i].advancedSwitch = payloadJSON.advancedSwitch;
        if (payloadJSON.advancedSwitch == true) {
          node.actions[i].advancedQuery = payloadJSON.advancedQuery;
        } else {
          node.actions[i].simpleQuery = payloadJSON.simpleQuery;
        }
        node.actions[i].sqlQuery = payloadJSON.sqlQuery;
        node.actions[i].enrichmentName = payloadJSON.enrichmentName;
        node.actions[i].enrichmentField = payloadJSON.enrichmentField;

        delete node.actions[i].payload;
      } 
      
      else if(actionType === Constants.NODE_ACTION_API_CALL) {
        var payloadJSON = JSON.parse(node.actions[i].payload);
        node.actions[i].requestBody = payloadJSON.requestBody
        node.actions[i].headers = payloadJSON.headers
        node.actions[i].httpMethod = payloadJSON.httpMethod
        node.actions[i].encryptBody = payloadJSON.encryptBody ? true : false
        delete node.actions[i].payload;
      }

      var actionJSON = {};

      delete node.actions[i].actionType; //remove the actionType field in the original action json

      actionJSON[actionType] = node.actions[i]; //the old json (-actionType field) now becomes the value to our key

      node.actions[i] = actionJSON;
    }
  }

  if(node.children && node.children.length > 0) {
    for(let i=0; i<node.children.length; i++) {
      formatFlowchartPayloadForFrontendRecursively(node.children[i]);
    }
  }

  return node;
}

//initialize local actionsList for NodeForm, so the node dialog can set initial values coming from the db
export const getActionsList = (node) => {
  var actionsList = [];

  if(node && node.actions) {
    for(let i=0; i<node.actions.length; i++) {
      var actionKey = Object.keys(node.actions[i])[0]; //the key is the action name we need
      actionsList.push(actionKey);
    }
  }

  return actionsList;
}

// Used to validate a JSON input
export const isJSONString = s => {
  try {
    JSON.parse(s);
  } catch(e) {
    return false;
  }
  return true;
};

export const buildApiName = apiName => {
  return apiName.replace(" ", "_") + "_response"
}

var conditionArray = []
var ruleSet = []
var condition = ""
var mainCombinator = ""

export const convertQueryToCondition = (query) => {
  if (typeof query === "string") {
    query = JSON.parse(query)
  }
  var isNestedQuery = false;
  var rules = query["rules"]
  for (var ruleIndex in rules) {
    if (rules[ruleIndex]["rules"]) {
      isNestedQuery = true;
      break;
    }
  }
  
  return isNestedQuery ? convertNestedQuery(query) : convertSimpleQuery(query)
}


const buildNestedQuery = (rule, combinator) => {
  if (!Array.isArray(rule)) {
    buildNestedQuery(rule["rules"], rule["combinator"])
  }
  else {
    rule.forEach((field, index) => {
      if (field.hasOwnProperty("rules")) {
        buildNestedQuery(field["rules"], field["combinator"])
      }
      else {
        ruleSet.push(extractCondition(field))
        ruleSet.push(combinator === "and" ? "&&" : "||")
        if (index == rule.length - 1) {
          ruleSet.pop()
          conditionArray.push(ruleSet.join(''))
          ruleSet = []
          return conditionArray
        }
      }
    })
  }
}

const convertNestedQuery = (query) => {
  conditionArray = []
  condition = ""
  var rules = query["rules"]
  var combinator = query["combinator"]
  mainCombinator = combinator

  Object.keys(rules).forEach(ruleIndex => {
    if (rules[ruleIndex]["rules"]) {
      buildNestedQuery(rules[ruleIndex], rules[ruleIndex]["combinator"])
      
      if (conditionArray.length > 1) {
        condition += "("

        conditionArray.forEach((curentCondition, index) => {
          condition += "(" + curentCondition + ")"
          if (index != conditionArray.length - 1) {
            condition += (rules[ruleIndex]["combinator"] === "and" ? "&&" : "||")
          }
          else {
            ruleIndex != rules.length-1 ? condition += (mainCombinator === "and" ? "&&" : "||") : condition += ")"
          }
        })
        
        conditionArray = []
      } 
      else {
        var tempCondition = "(" + conditionArray[0] + ")"
        ruleIndex != rules.length-1 ? condition += tempCondition + (mainCombinator === "and" ? "&&" : "||") : condition += tempCondition
        conditionArray = []
      }
    }
    else {
      var tempCondition = "(" + extractCondition(rules[ruleIndex]) + ")"
      ruleIndex != rules.length-1 ? condition += tempCondition + (mainCombinator === "and" ? "&&" : "||") : condition += tempCondition
    }
  })
  return condition
}

const convertSimpleQuery = (query) => {
  var rules = query["rules"]
  var combinator = query["combinator"]
  var tempConditionArray = []

  if (rules.length == 0) {
    return ""
  }

  Object.keys(rules).forEach(ruleIndex => {
    tempConditionArray.push(extractCondition(rules[ruleIndex]))
    tempConditionArray.push(combinator === "and" ? "&&" : "||")
  })
  tempConditionArray.pop()
  return tempConditionArray.join('')
}

const extractCondition = (rule) => {
  var condition = rule["field"]
  var operator = rule["operator"].substring(rule["operator"].indexOf("_") + 1)

  switch (rule["operator"].substring(0, 3)) {
    case "arr":
      if (operator.charAt(0) == '!') { condition = "!" + condition; operator = operator.substring(1) }
      let isNumberOrStringIncluded = isNaN(parseInt(rule["value"])) ? ("(\"" + rule["value"] + "\")") : ("(" + rule["value"] + ")")
      condition += "." + operator + isNumberOrStringIncluded
      return condition;

    case "str":
      if (operator == "==" || operator == "!=") {
        return condition + operator + "\"" + rule["value"] + "\""
      }
      if (operator.charAt(0) == '!') { condition = "!" + condition; operator = operator.substring(1) }
      condition += ("." + operator + "(\"" + rule["value"] + "\")")
      return condition;

    case "boo":
    case "num":
      condition += rule["operator"].substring(rule["operator"].indexOf("_") + 1) + rule["value"]
      return condition;

    default:
      break;
  }
  return condition
}

const getOperatorsAndInputType = input => {
  switch (typeof input) {
    case "number":
      return [Constants.NUMBER_OPERATORS, "number"]
    case "string":
      return [Constants.STRING_OPERATORS, "string"]
    case "boolean":
      return [Constants.BOOLEAN_OPERATORS, "boolean"]
    case "object":
      if (Array.isArray(input))
        return [Constants.ARRAY_OPERATORS, "string"]
    default:
      break;
  }
}

const createOperator = (value, operators, propertyPaths, index) => {
  switch (typeof value) {
    case "string":
      operators.push({name: "\"" + propertyPaths[index] + "\"", label: propertyPaths[index], operators: Constants.STRING_OPERATORS, inputType: "string"})
      break;
    case "boolean":
      operators.push({name: propertyPaths[index], label: propertyPaths[index], operators: Constants.BOOLEAN_OPERATORS, valueEditorType: 'checkbox', defaultValue: false})
      break;
    case "number":
      operators.push({name: propertyPaths[index], label: propertyPaths[index], operators: Constants.NUMBER_OPERATORS, inputType: "number"})
      break;
    case "object":
      if (Array.isArray(value)) {
        operators.push({name: propertyPaths[index], label: propertyPaths[index], operators: Constants.ARRAY_OPERATORS, inputType: "string"})
        break;
      } 
    default:
      break;
  }
}

const getPayloadKeys = propertyPaths => {
  var keys = []
  for (var index in propertyPaths) {
    var key = propertyPaths[index]
    keys.push(key.substring(key.indexOf(".") + 1))
  }
  return keys
}

const appendObjectKey = (payloadKeys, key) => {
  var newPayloadKeys = []
  payloadKeys.forEach(currentKey => {
    newPayloadKeys.push(key + "." + currentKey)
  })
  return newPayloadKeys
}

const propertiesToArray = (obj) => {
  const isObject = val =>
      typeof val === 'object' && !Array.isArray(val);

  const addDelimiter = (a, b) =>
      a ? `${a}.${b}` : b;

  const paths = (obj = {}, head = '') => {
    return Object.entries(obj)
      .reduce((product, [key, value]) => 
        {
            let fullPath = addDelimiter(head, key)
            return isObject(value) ?
                product.concat(paths(value, fullPath))
            : product.concat(fullPath)
        }, []);
  }
  return paths(obj);
}

export const buildQueryBuilderOperators = payloadSchema => {
  var operators = []

  Object.entries(payloadSchema).forEach(payload => {
    var propertyPaths = appendObjectKey(propertiesToArray(payload[1]), payload[0])
    var payloadKeys = getPayloadKeys(propertyPaths)
    for (var index in payloadKeys) {
      var key = payloadKeys[index]
      
      if (!key.includes(".")) {
        var value = payload[1][key]
        createOperator(value, operators, propertyPaths, index)
      } 
      else {
        var runner = payload[1]
        var nestedKeys = payloadKeys[index].split(".")
        for (var propertyIndex in nestedKeys) {
          var currentKey = nestedKeys[propertyIndex]
          runner = runner[currentKey]
        }
        var inputType = getOperatorsAndInputType(runner)
        operators.push({name: inputType[1] == "string" ? "\"" + propertyPaths[index] + "\"" : propertyPaths[index] , label: propertyPaths[index], operators: inputType[0], inputType: inputType[1], valueEditorType: inputType[1] == "boolean" ? 'checkbox' : "text"})
      }
    }
  })
  return operators
}

export const getSnowflakeOperators = (payloadSchema, columns) => {
  var operators = []
  var columnsValues = []

  if (columns) {
    columns.map(column =>{
      columnsValues.push({name: column, label: column})
    })
  }

  Object.entries(payloadSchema).forEach(payload => {
    var propertyPaths = appendObjectKey(propertiesToArray(payload[1]), payload[0])

    for (var columnIndex in columnsValues) {
      operators.push({
        name: columnsValues[columnIndex].name,
        label: columnsValues[columnIndex].label,
        valueEditorType: 'select',
        values: propertyPaths.map(prop => {
          return {name:prop, label: prop}
        }),
        operators: Constants.SNOWFLAKE_OPERATORS
      })
    }
  })
  return operators
}