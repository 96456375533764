import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactJson from 'react-json-view'
import DescriptionIcon from '@material-ui/icons/Description';
import { IconButton } from "material-ui";

export const FlowchartDialog = props => {
    const [openFlowchart, setOpenFlowchart] = React.useState(false);

    const handleOpenFlowchart = () => {
        setOpenFlowchart(true);
    }

    const handleCloseFlowchart = () => {
        setOpenFlowchart(false);
    }

    const buildSessionJSON = (data) => {
        var sessionJSON = {}
        sessionJSON["nodename"] = data["nodename"]
        sessionJSON["tcreated"] = data["tcreated"]
        sessionJSON["eventPayload"] = JSON.parse(data["eventPayload"])
        return sessionJSON
    }

    return (
        <IconButton>
            <DescriptionIcon onClick={handleOpenFlowchart}/>
            <Dialog
                open={openFlowchart}
                onClose={handleCloseFlowchart}
                style={{zIndex: 10000}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {Array.isArray(props.data) ?
                        props.data.map(session => {
                            var sessionJSON = buildSessionJSON(session)
                            return <ReactJson src={sessionJSON} name={false} displayDataTypes={false} />
                        })
                        :
                        <ReactJson src={JSON.parse(props.data)} name={false} displayDataTypes={false} />
                    }
                    {props.data.length == 0 &&
                        <p>No node was triggered</p>
                    }
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseFlowchart} color="primary" autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>
        </IconButton>
    );
}