import * as Types from "./types";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";

const monitoringRequest = createAction(Types.MONITORING_REQUEST);
const monitoringSuccess = createAction(
  Types.MONITORING_SUCCESS,
  payload => payload
);
const monitoringFailure = createAction(Types.MONITORING_FAILURE);
const forceLogout = createAction(Types.LOGOUT);

export const getMonitoring = () => async dispatch => {
  dispatch(monitoringRequest());
  try {
    const response = await fetch(API_VERSION + "monitoring", {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    });

    if (response.status === 401 || response.status === 403) {
      const responseLogout = await fetch(API_VERSION + "session", {
        method: "DELETE",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" }
      });

      const body = await responseLogout.json();

      if (body.ok) {
        dispatch(forceLogout());
        Cookies.remove("JESSIONID");
        localStorage.clear();
        sessionStorage.clear();
      }
    } else if (response.status !== 200) {
      dispatch(monitoringFailure());
    } else {
      const body = await response.json();
      dispatch(monitoringSuccess(body));
    }
  } catch (error) {
    console.error(error);
  }
};
