import * as Types from './types'

export const dbReducer = (state = {}, action) => {
  switch(action.type){
    case Types.GET_SUCCESS:
    case Types.CREATE_SUCCESS:
    case Types.UPDATE_SUCCESS:
    case Types.DELETE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading:false
      }
    case Types.SET_FLOWCHART:
      return {
        ...state,
        active:action.payload
      }
    case Types.LOAD_TREE:
      //console.log(JSON.stringify(action.payload.metadata.id)) //for debugging
      return {
        ...state,
        active:action.payload.metadata.id
      }
    case Types.GET_REQUEST:
    case Types.CREATE_REQUEST:
    case Types.UPDATE_REQUEST:
    case Types.DELETE_REQUEST:
      return {
        ...state,
        loading:true
      }
    case Types.CREATE_FAILURE:
    case Types.UPDATE_FAILURE:
      return{
        ...state,
        loading:false,
        success: action.success,
        message: action.message
      }
    case Types.GET_FAILURE:
    case Types.DELETE_FAILURE:
      return {
        ...state,
        loading:false
      }
    default:
      return state;
  }
}
