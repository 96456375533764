export const createTableData = (data) => {
   let rows = []

   if (data) {
      data.map(d => {
         rows.push({ 
            id: d.id,
            name: d.connectionName, 
            accountIdentifier: d.accountIdentifier,
            username: d.username,
            warehouse: d.warehouseName,
            database: d.dbName,
            schema: d.schemaName
         })
      })
   }

   return rows
 }

 export const convertFromToSnowflakeJSON = (form) => {
   let connectionJSON = {
      connectionName: form.name,
      accountIdentifier: form.accountIdentifier,
      username: form.username,
      password: form.password,
      warehouseName: form.warehouse,
      dbName: form.database,
      schemaName: form.schema
    }
    return connectionJSON
 }

 export const uniqueConnectionName = (connections, name) => {
    var listOfConnectionNames = connections.map(connection => {
       return connection.connectionName
    })

    return listOfConnectionNames.includes(name)
 }