
export const parseLinkedReplies = form => {
  let reply = "";
  let linkedReplies = "";
  const { firstReply, replies } = form;

  // if (replies && replies.length > 0) {
  //   linkedReplies = replies.map(linkedReply => {
  //     linkedReplies = linkedReplies + Constants.DELIMITER + linkedReply.reply;
  //     return linkedReplies;
  //   });
  //   reply = firstReply + linkedReplies[linkedReplies.length - 1];
  // } else {
  //   reply = firstReply;
  // }

  delete form.firstReply;
  delete form.replies;

  form.reply = reply;

  return form;
};
