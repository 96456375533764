import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { TextField }from 'redux-form-material-ui'
import { munvoBlue } from '../utils/globalStyles'

const SnowflakeForm = (props) => {
   const { formResponse } = props;
   var responseColor = "#FC100D"

   return(
      <form>
         <Field 
            component={TextField} 
            type='text' 
            floatingLabelText='Name' 
            name='name' 
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         <br/>
         <br/>
         <Field 
            component={TextField} 
            type='text' 
            floatingLabelText='Account identifier' 
            name='accountIdentifier' 
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         <br/>
         <br/>
         <Field 
            component={TextField} 
            type='text' 
            floatingLabelText='Username' 
            name='username' 
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         <br/>
         <br/>
         <Field 
            component={TextField} 
            type='password' 
            floatingLabelText='Password' 
            name='password'
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         <br/>
         <br/>
         <Field 
            component={TextField} 
            type='text' 
            floatingLabelText='Warehouse' 
            name='warehouse'
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         <br/>
         <br/>
         <Field 
            component={TextField} 
            type='text' 
            floatingLabelText='Database' 
            name='database'
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         <br/>
         <br/>
         <Field 
            component={TextField} 
            type='text' 
            floatingLabelText='Schema' 
            name='schema'
            underlineStyle={{ borderColor: munvoBlue }}
            underlineFocusStyle={{ borderColor: munvoBlue }}
            floatingLabelFocusStyle={{ color: munvoBlue }}
         />
         {formResponse &&
            <p style={{ color: responseColor }}>{formResponse}</p>
          }
      </form>
   )
}

const validate = (values) => {
   const errors = {}
   if(!values.accountIdentifier){
      errors.accountIdentifier = 'Required'
   }
   if(!values.name){
      errors.name = 'Required'
   }
   if(!values.username){
      errors.username = 'Required'
   }
   if(!values.password){
      errors.password = 'Required'
   }
   if(!values.warehouse){
      errors.warehouse = 'Required'
   }
   if(!values.database){
      errors.database = 'Required'
   }
   if(!values.schema){
      errors.schema = 'Required'
   }
   return errors
}

export default reduxForm({
   form: 'snowflakeForm',
   validate,
   onSubmit: (values, dispatch, props) => { 
      props.submitCallback(values)
   }
})(SnowflakeForm)