import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { loginFormStyles } from "./styles";
import RemoteSubmitButton from "../RemoteSubmitButton";
import { connect } from "react-redux";
import { login } from "../reducers";
import LoginForm from "./LoginForm";
import CardActions from "@material-ui/core/CardActions";
import { FlatButton } from "material-ui";
import Checkbox from "@material-ui/core/Checkbox";
import { LoginTooltip } from "../utils/Tooltip";
import logo from "../event_gateway_logo.png";
import Button from "@material-ui/core/Button";
import MicrosoftLogin from "react-microsoft-login";
import * as Constants from "../utils/Constants";
import { loginMicrosoftUser } from "../reducers/login";

class MicrosoftSSOLoginCard extends Component {
  constructor(props) {
    super(props);

    this.state = { rememberMe: false };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.authHandler = this.authHandler.bind(this);
    //this.handleRememberMe = this.handleRememberMe.bind(this);
  }

  handleSubmit(form) {
    this.props.dispatch(login(form, this.state.rememberMe));
  }

  authHandler(err, data) {
    //console.log("AUTHDATA JSON: " + JSON.stringify(data)) //for debugging
    //console.log("AUTHHANDLER ERROR: " + JSON.stringify(err)) //for debugging
    if(!this.props.invalidate) this.props.dispatch(loginMicrosoftUser(data));
  }

  // handleRememberMe(e) {
  //   this.setState({ rememberMe: e.target.checked });
  // }

  render() {
    const { classes, invalidate } = this.props;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.textCenter}>
              <img src={logo} alt="logo" />
              
              <br/><br/>

              <MicrosoftLogin 
                clientId={Constants.MICROSOFT_SSO_COX_CLIENT_ID} 
                authCallback={this.authHandler}
                buttonTheme={"dark"}
                forceRedirectStrategy={false}
                tenantUrl={"https://login.microsoftonline.com/" + Constants.MICROSOFT_SSO_COX_TENANT_ID}
                redirectUri={Constants.MICROSOFT_SSO_COX_REDIRECT_URI}
              />

              <br/><br/>
            </div>
          </CardContent>
          {/* <CardActions className={classes.buttonLocation}>
            <div>
              Remember me?
              <Checkbox
                label="Remember me"
                onChange={this.handleRememberMe}
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked
                }}
              />
            </div>
          </CardActions> */}
          {invalidate ? (
            <div className={classes.footerText}>Login Failed</div>
          ) : (
            <div></div>
          )}
        </Card>
      </div>
    );
  }
}

MicrosoftSSOLoginCard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    invalidate: state.authentication.invalidate
  };
};

export default connect(mapStateToProps)(withStyles(loginFormStyles)(MicrosoftSSOLoginCard));
