import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Sidebar from "../Sidebar";
import HeaderBar from "../Header";
import classnames from "classnames";
import { getConfiguration } from "../reducers";
import {
  DEFAULT_REPORTING_LAST_X_DAYS,
  REPORTING_LAST_X_DAYS_CONFIG_KEY
} from "../utils/Constants";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import Button from "@material-ui/core/Button";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLegend,
  VictoryPie
} from "victory";
import { connect } from "react-redux";
import { generateReport } from "../reducers";
import { dbSelectors } from "../reducers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { EngagementScoreTooltip } from "../utils/Tooltip";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    generateReport: (campaignId, startDate, endDate) =>
      dispatch(generateReport(campaignId, startDate, endDate)),
    dispatch
  };
};

function formatDate(number) {
  if (number < 10) {
    number = "0" + number;
  }

  return number;
}

class Reports extends Component {
  constructor(props) {
    super(props);
    const dateNow = new Date();
    const datePast = new Date(
      Math.abs(dateNow - 86400000 * DEFAULT_REPORTING_LAST_X_DAYS)
    ); //large num = milliseconds in a day

    this.state = {
      isDefault:true,
      startDate:
        datePast.getFullYear() +
        "-" +
        formatDate(datePast.getMonth() + 1) +
        "-" +
        formatDate(datePast.getDate() + 1),
      endDate:
        dateNow.getFullYear() +
        "-" +
        formatDate(dateNow.getMonth() + 1) +
        "-" +
        formatDate(dateNow.getDate() + 1),
      campaignNumber: null
    };
  }

  componentWillMount() {
    if(this.props.flowcharts[0]) {
      this.props.generateReport(
          this.props.flowcharts[0].campaignID,
          this.state.startDate,
          this.state.endDate
    )};
    this.props.dispatch(getConfiguration());
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.report) {
      this.setState({
        totalMessages: nextProps.report.transactionCount,
        messagesDelivered: nextProps.report.outboundTransactionCount,
        messagesReceived: nextProps.report.inboundTransactionCount,
        activeSessions: nextProps.report.activeSessionCount,
        inactiveSessions: nextProps.report.inactiveSessionCount
      });
    }
    if (!this.state.campaignNumber && nextProps.flowcharts.length > 0) {
      this.setState({
        campaignNumber: nextProps.flowcharts[0].campaignID
      });
    }

    if (nextProps.configs && this.state.isDefault) {
      for (var i = nextProps.configs.length - 1; i >= 0; i--) {
        if (nextProps.configs[i].key === REPORTING_LAST_X_DAYS_CONFIG_KEY) {
          const dateNow = new Date();
          const datePast = new Date(
            Math.abs(dateNow - 86400000 * nextProps.configs[i].value)
          ); //large num = milliseconds in a day

          this.setState({
            startDate:
              datePast.getFullYear() +
              "-" +
              formatDate(datePast.getMonth() + 1) +
              "-" +
              formatDate(datePast.getDate() + 1)
          });
          this.setState({
            isDefault: false
          });

          break;
        }
      }
    }
  }

  render() {
    //const classes = useStyles();
    const transactionData = [
      { type: "Outbound", count: this.props.report.outboundTransactionCount },
      { type: "Inbound", count: this.props.report.inboundTransactionCount },
      { type: "Batch", count: this.props.report.batchTransactionCount }
    ];
    let statusData = [];
    for (let key in this.props.report.statusCounts) {
      statusData.push({
        type: key,
        count: this.props.report.statusCounts[key]
      });
    }
    return (
      <div>
        <HeaderBar pageTitle={"KPI Reporting Dashboard"} />
        <Sidebar component="div" />
        <div className={classnames("app-content", { expanded: true })}>
          <section
            style={{
              padding: "1rem 6rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <TextField
              style={{ width: "200px" }}
              id="campaign-list"
              select
              value={this.state.campaignNumber}
              name="campaignNumber"
              onChange={this.handleChange}
              SelectProps={{ native: true }}
              label="Campaign Number"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {this.props.flowcharts.map(option => (
                <option key={option.campaignID} value={option.campaignID}>
                  {option.campaignID}
                </option>
              ))}
            </TextField>
            &nbsp;&nbsp;
            <TextField
              id="date"
              label="Start Date"
              type="date"
              name="startDate"
              value={this.state.startDate}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true
              }}
            />
            &nbsp;&nbsp;
            <TextField
              id="date"
              label="End Date"
              type="date"
              name="endDate"
              value={this.state.endDate}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true
              }}
            />
            &nbsp;&nbsp;
            <Button
              color="primary"
              variant="contained"
              style={{ background: munvoOrange }}
              onClick={() =>
                this.props.generateReport(
                  this.state.campaignNumber,
                  this.state.startDate,
                  this.state.endDate
                )
              }
            >
              Generate Report
            </Button>
          </section>
          <section
            style={{
              padding: "1rem 6rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data Type</TableCell>
                    <TableCell align="right">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={"Transaction Count"}>
                    <TableCell component="th" scope="row">
                      Transactions
                    </TableCell>
                    <TableCell align="right">
                      {this.props.report.transactionCount}
                    </TableCell>
                  </TableRow>
                  <TableRow key={"Session Count"}>
                    <TableCell component="th" scope="row">
                      Sessions
                    </TableCell>
                    <TableCell align="right">
                      {this.props.report.sessionCount}
                    </TableCell>
                  </TableRow>
                  <TableRow key={"Engagement Score"}>
                    <TableCell
                      component="th"
                      scope="row"
                      data-tip
                      data-for="engagement-score-tt"
                    >
                      Engagement Score
                      <EngagementScoreTooltip />
                    </TableCell>

                    <TableCell align="right">
                      {this.props.report.engagement}
                    </TableCell>
                  </TableRow>
                  <TableRow key={"Transaction Error Count"}>
                    <TableCell component="th" scope="row">
                      Transaction Errors
                    </TableCell>
                    <TableCell align="right">
                      {this.props.report.transactionErrorCount}
                    </TableCell>
                  </TableRow>
                  <TableRow key={"Opt-Out Count"}>
                    <TableCell component="th" scope="row">
                      Opt-outs
                    </TableCell>
                    <TableCell align="right">
                      {this.props.report.optoutCount}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <VictoryChart
              domainPadding={80}
              style={{ parent: { maxWidth: "40%" } }}
            >
              {/* <VictoryLegend x={75} y={0} standalone={false}
                                title="Message Transaction Types"
                                centerTitle
                                orientation="horizontal"
                                gutter={20}
                                style={{ border: { stroke: "black" }, title: {fontSize: 14 } }}
                                data={[
                                { name: "Outbound", symbol: { fill: munvoBlue} },
                                { name: "Inbound", symbol: { fill: munvoOrange } },
                                { name: "Batch", symbol: { fill: "green" } }
                                ]}
                            /> */}
              <VictoryAxis style={{ axis: { stroke: "black" } }} />
              <VictoryBar
                data={transactionData || []}
                barRatio={0.7}
                // barWidth={60}
                x={"type"}
                y={"count"}
                labels={d => d.count}
                style={{
                  data: {
                    fill: data =>
                      data.type === "Inbound"
                        ? munvoOrange
                        : data.type === "Outbound"
                        ? munvoBlue
                        : "green"
                  }
                }}
              />
            </VictoryChart>
          </section>
          <section
            style={{
              padding: "1rem 6rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <svg width={400} height={250} style={{ border: "1px solid #ccc" }}>
              <VictoryLegend
                standalone={false}
                x={20}
                y={40}
                gutter={20}
                title="Transaction Status"
                centerTitle
                style={{ border: { stroke: "black" } }}
                data={statusData.map(status => {
                  let el = { name: status.type };
                  return el;
                })}
              />
              <VictoryPie
                standalone={false}
                width={400}
                height={250}
                padding={{
                  left: 150,
                  bottom: 20,
                  top: 20
                }}
                data={statusData.map((status, index) => {
                  let dataPoint = { x: index + 1, y: status.count };
                  return dataPoint;
                })}
                labels={d => d.y}
              />
            </svg>
            <svg width={400} height={250} style={{ border: "1px solid #ccc" }}>
              <VictoryLegend
                standalone={false}
                colorScale={[munvoBlue, munvoOrange]}
                x={20}
                y={40}
                gutter={20}
                title="Session Status"
                centerTitle
                style={{ border: { stroke: "black" } }}
                data={[{ name: "Active" }, { name: "Inactive" }]}
              />
              <VictoryPie
                standalone={false}
                width={400}
                height={250}
                padding={{
                  left: 150,
                  bottom: 20,
                  top: 20
                }}
                colorScale={[munvoBlue, munvoOrange]}
                data={[
                  { x: 1, y: this.props.report.activeSessionCount || 0 },
                  { x: 2, y: this.props.report.inactiveSessionCount || 0 }
                ]}
                labels={d => d.y}
              />
            </svg>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    flowcharts: dbSelectors.getFlowchartList(state),
    report: state.reports.reportData,
    configs: state.configuration.configurations
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
