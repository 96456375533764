import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";

const snowflakeRequest = createAction(Types.SNOWFLAKE_REQUEST);
const snowflakeFailure = createAction(Types.SNOWFLAKE_FAILURE);
const snowflakeSuccess = createAction(Types.SNOWFLAKE_SUCCESS, snowflake_connections => snowflake_connections);
const tableSuccess = createAction(Types.TABLES_SUCCESS, snowflake_tables => snowflake_tables);
const tableFailure = createAction(Types.TABLES_FAILURE);
const columnSuccess = createAction(Types.COLUMNS_SUCCESS, snowflake_columns => snowflake_columns);
const columnFailure = createAction(Types.COLUMNS_FAILURE);
const forceLogout = createAction(Types.LOGOUT);

export const getSnowflakeConnections = () => async dispatch => {
  dispatch(snowflakeRequest());
  const response = await fetch(API_VERSION + "snowflake/list", {
    method: "GET",
    credentails: "same-orgin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(snowflakeFailure());
  } else {
    const body = await response.json();
    dispatch(snowflakeSuccess(body.snowflakeList));
  }
};

export const postSnowflakeConnection = newConnection => async dispatch => {
  dispatch(snowflakeRequest());
  const response = await fetch(API_VERSION + "snowflake/newconnection", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(newConnection)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(snowflakeFailure());
  } else {
    const body = await response.json();
    dispatch(snowflakeSuccess(body.snowflakeList));
  }
};

export const putSnowflakeConnection = connection => async dispatch => {
  dispatch(snowflakeRequest());
  const response = await fetch(API_VERSION + "snowflake/editconnection", {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(connection)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(snowflakeFailure());
  } else {
    const body = await response.json();
    dispatch(snowflakeSuccess(body.snowflakeList));
  }
}

export const deleteSnowflakeConnection = queryParams => async dispatch => {
  dispatch(snowflakeRequest());
  const response = await fetch(
    API_VERSION + "snowflake/delete?".concat(queryParams),
    {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  );

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(snowflakeFailure());
  } else {
    const body = await response.json();
    dispatch(snowflakeSuccess(body.snowflakeList));
  }
};

export const testSnowflakeConnection = connection => async dispatch => {
  dispatch(snowflakeRequest());
  const response = await fetch(API_VERSION + "snowflake/testconnection", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(connection)
  });

  var finalResponse = ""
  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    finalResponse = "Connection to snowflake failed!"
    dispatch(snowflakeFailure());
  } else {
    const body = await response.json();
    finalResponse = body.success ? "Connection to snowflake succeeded!" : "Connection to snowflake failed!"
    dispatch(snowflakeSuccess(body.snowflakeList));
  }
  return finalResponse
}

export const getTablesByConnectionID = connectionID => async dispatch => {
  dispatch(snowflakeRequest());
  const response = await fetch(API_VERSION + "snowflake/" + connectionID, {
    method: "GET",
    credentails: "same-orgin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(tableFailure());
  } else {
    const body = await response.json();
    dispatch(tableSuccess(body.snowflakeTableList));
  }
}

export const getColumnsByTableName  = (connectionID, tableName) => async dispatch => {
  dispatch(snowflakeRequest());
  const response = await fetch(API_VERSION + "snowflake/" + connectionID + "/" + tableName, {
    method: "GET",
    credentails: "same-orgin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(columnFailure());
  } else {
    const body = await response.json();
    dispatch(columnSuccess(body.snowflakeColumnList));
  }
}

const snowflakeActions = {
  getSnowflakeConnections,
  postSnowflakeConnection,
  putSnowflakeConnection,
  deleteSnowflakeConnection,
  testSnowflakeConnection,
  getTablesByConnectionID,
  getColumnsByTableName
}

export { snowflakeActions };